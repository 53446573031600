import _ from "underscore";

export default {
    create(hooks) {
        const hasTouchEvents = typeof window.TouchEvent !== "undefined";
        if (hooks.step === undefined) {
            throw new Error("ScrollWheelHandler expects a step(isXAxis, stepIncreased) function.");
        }

        const getDefaultScrollPositions = function () {
            return {
                deltaX: 0,
                deltaY: 0,
                lastTouchX: -1,
                lastTouchY: -1,
            };
        };

        // Wheel normalization code form Facebook's fixed data table
        // https://github.com/facebook/fixed-data-table/blob/master/dist/fixed-data-table.js#L4402-4542
        // Reasonable defaults
        const PIXEL_STEP = 10;
        const LINE_HEIGHT = 40;
        const PAGE_HEIGHT = 800;
        const WHEEL_DELTA_DIVISOR = 120;

        function normalizeWheel(event) {
            let sX = 0,
                sY = 0, // spinX, spinY
                pX = 0,
                pY = 0; // pixelX, pixelY

            // Legacy
            if ("detail" in event) {
                sY = event.detail;
            }
            if ("wheelDelta" in event) {
                sY = -event.wheelDelta / WHEEL_DELTA_DIVISOR;
            }
            if ("wheelDeltaY" in event) {
                sY = -event.wheelDeltaY / WHEEL_DELTA_DIVISOR;
            }
            if ("wheelDeltaX" in event) {
                sX = -event.wheelDeltaX / WHEEL_DELTA_DIVISOR;
            }

            // side scrolling on FF with DOMMouseScroll
            if ("axis" in event && event.axis === event.HORIZONTAL_AXIS) {
                sX = sY;
                sY = 0;
            }

            pX = sX * PIXEL_STEP;
            pY = sY * PIXEL_STEP;

            if ("deltaY" in event) {
                pY = event.deltaY;
            }
            if ("deltaX" in event) {
                pX = event.deltaX;
            }

            if ((pX || pY) && event.deltaMode) {
                if (event.deltaMode === 1) {
                    // delta in LINE units
                    pX *= LINE_HEIGHT;
                    pY *= LINE_HEIGHT;
                } else {
                    // delta in PAGE units
                    pX *= PAGE_HEIGHT;
                    pY *= PAGE_HEIGHT;
                }
            }

            // Fall-back if spin cannot be determined
            if (pX && !sX) {
                sX = pX < 1 ? -1 : 1;
            }
            if (pY && !sY) {
                sY = pY < 1 ? -1 : 1;
            }

            return {
                spinX: sX,
                spinY: sY,
                pixelX: pX,
                pixelY: pY,
            };
        }

        const DEFAULT_HOOK_LIMIT = 75;
        let _mixinScrollPositions;
        return function (evt) {
            if (hooks.shouldScroll && hooks.shouldScroll(evt) === false) {
                return;
            }

            if (hooks.isActive && hooks.isActive() === false) {
                evt.preventDefault();
                return;
            }

            if (!_mixinScrollPositions) {
                _mixinScrollPositions = getDefaultScrollPositions();
            }

            const positions = _mixinScrollPositions;
            if (hasTouchEvents && evt.nativeEvent instanceof window.TouchEvent) {
                if (hooks.minNumFingers) {
                    if (hooks.minNumFingers > evt.nativeEvent.touches.length) {
                        return;
                    }
                    // eslint-disable-next-line no-magic-numbers
                } else if (evt.nativeEvent.touches.length !== 2) {
                    return;
                }
                const coords = _.getClientPos(evt);
                positions.deltaX += positions.lastTouchX - coords.x;
                positions.deltaY += positions.lastTouchY - coords.y;
                positions.lastTouchX = coords.x;
                positions.lastTouchY = coords.y;
            } else {
                const normalized = normalizeWheel(evt);
                positions.deltaX += normalized.pixelX;
                positions.deltaY += normalized.pixelY;
            }
            const limit = hooks.limit || DEFAULT_HOOK_LIMIT;

            if (positions.deltaX > limit) {
                hooks.step(true, true, evt);
                positions.deltaX = 0;
            } else if (positions.deltaX < -limit) {
                hooks.step(true, false, evt);
                positions.deltaX = 0;
            }

            if (positions.deltaY > limit) {
                hooks.step(false, true, evt);
                positions.deltaY = 0;
            } else if (positions.deltaY < -limit) {
                hooks.step(false, false, evt);
                positions.deltaY = 0;
            }

            if (hooks.shouldEventPropagate && !hooks.shouldEventPropagate()) {
                evt.stopPropagation();
            }
        };
    },
};
