import PropTypes from "prop-types";
import React from "react";
import ViewList from "./ViewList";
import Settings from "./Settings";
import LayerList from "./LayerList";
import Account from "./Account";
import Help from "./Help";
import ReservationPane from "./ReservationPane";
import ObjectInfo from "./ObjectInfo";
import OrderInfo from "./OrderInfo";
import AdminPane from "./AdminPane";
import PinboardPane from "./PinboardPane";
import Tooltip from "./Tooltip";
import ErrorBoundary from "./ErrorBoundary";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { Menu as MenuModel } from "../models/Menu";
import { Selection as SelectionModel } from "../models/Selection";
import { TimeEdit } from "../lib/TimeEdit";
import Mousetrap from "@timeedit/mousetrap";
import Language from "../lib/Language";
import _ from "underscore";
import LayoutConstants from "../lib/LayoutConstants";

const headwayConfig = {
    selector: ".help", // CSS selector where to inject the badge
    account: "Jlwn57",
};

const TOOLTIP_WIDTH_MULTIPLIER = 5;

class Menu extends React.Component {
    static propTypes = {
        data: PropTypes.instanceOf(MenuModel).isRequired,
        selection: PropTypes.instanceOf(SelectionModel),
    };

    static contextTypes = {
        update: PropTypes.func,
        user: PropTypes.object,
        scratchpad: PropTypes.object,
    };

    state = {};

    componentDidMount() {
        Mousetrap.bindWithHelp(
            "shift+mod+l",
            (e) => this.props.onSelectionToggle(e),
            undefined,
            Language.get("nc_menu_open/close_lists.")
        );

        const activeMenuId = this.props.data.activeMenu;
        if (activeMenuId === "settings" && this.props.data.isOpen) {
            this.props.onSettingsVisibilityChange(true);
        }
        if (window.Headway) {
            window.Headway.init(headwayConfig);
        }
    }

    componentWillUnmount() {
        Mousetrap.unbindWithHelp("esc");
        if (this._previousEscBinding) {
            Mousetrap.bindWithHelp("esc", this._previousEscBinding);
        }
    }

    getMenuAction = (item) => {
        if (item.id === "selection") {
            return this.props.onSelectionToggle;
        }

        return this.toggleMenu.bind(this, item);
    };

    open = (id) => {
        this.props.onSettingsVisibilityChange(id === "settings");
        this.context.update(this.props.data, this.props.data.open(id));
        this._previousEscBinding = Mousetrap.unbindWithHelp("esc", true)[0];
        Mousetrap.bindWithHelp(
            "esc",
            () => {
                if (this.props.data.isOpen) {
                    this.close();
                }
            },
            undefined,
            Language.get("nc_menu_close_menu.")
        );
    };

    close = () => {
        this.props.onSettingsVisibilityChange(false);
        this.context.update(this.props.data, this.props.data.close());
        Mousetrap.unbindWithHelp("esc");
        if (this._previousEscBinding) {
            Mousetrap.bindWithHelp("esc", this._previousEscBinding);
        }
    };

    toggleMenu = (item) => {
        if (this.props.data.isOpen && item.id === this.props.data.activeMenu) {
            this.close();
            return;
        }

        this.open(item.id);
    };

    showTooltip = (id) => {
        const showTooltips = this.state.showTooltips || {};
        showTooltips[id] = true;
        this.setState({ showTooltips });
    };

    hideTooltip = (id) => {
        const showTooltips = this.state.showTooltips || {};
        showTooltips[id] = false;
        this.setState({ showTooltips });
    };

    isTooltipVisible = (id) => {
        if (!this.state.showTooltips || !this.state.showTooltips[id]) {
            return false;
        }

        return this.state.showTooltips[id];
    };

    getCurrentComponent = () => {
        const item = this.props.data.getCurrentItem();
        if (!item) {
            return null;
        }

        switch (item.id) {
            case "account":
                return <Account key={item.id} />;
            case "settings":
                return (
                    <ErrorBoundary>
                        <Settings
                            key={item.id}
                            calendar={this.props.activeCalendar}
                            publicSelection={this.props.publicSelection}
                            getCalendarIndex={this.props.getCalendarIndex}
                        />
                    </ErrorBoundary>
                );
            case "views":
                return (
                    <ErrorBoundary>
                        <ViewList
                            key={item.id}
                            data={item.model}
                            onViewChange={this.props.onViewChange}
                            view={this.props.view}
                        />
                    </ErrorBoundary>
                );
            case "layers":
                return (
                    <LayerList
                        key={item.id}
                        data={item.model}
                        layers={this.props.layers}
                        layer={this.props.activeLayer}
                        setActiveLayer={this.props.setActiveLayer}
                        onLayerCreated={this.props.onLayerCreated}
                        onRemoveLayer={this.props.onRemoveLayer}
                        onPersistLayer={this.props.onPersistLayer}
                        reloadLayers={this.props.reloadLayers}
                        getLayerName={this.props.getLayerName}
                    />
                );
            case "info":
                if (
                    this.props.reservationIds.length === 0 &&
                    this.props.orderIds.length === 0 &&
                    this.props.objectIds.length === 0 &&
                    this.props.dynamicReservationIds.length === 0
                ) {
                    return (
                        <div style={{ padding: "10px" }}>
                            <h2>{Language.get("cal_res_side_tab_res_info")}</h2>
                            <p>{Language.get("nc_menu_reservation_not_selected")}</p>
                        </div>
                    );
                }
                // eslint-disable-next-line no-case-declarations
                const dynamicReservationIds = this.props.dynamicReservationIds.filter(
                    (id) => this.props.reservationIds.indexOf(id) === -1
                );
                // eslint-disable-next-line no-case-declarations
                const panels = this.props.multiPanelOrder.map((panelName) => {
                    switch (panelName) {
                        case "object":
                            return (
                                <ObjectInfo
                                    key={panelName}
                                    embedded={true}
                                    noIgnore={true}
                                    colorDefs={this.context.colorDefs}
                                    typeId={TimeEdit.rootType}
                                    objectId={this.props.objectIds ? this.props.objectIds[0] : 0}
                                    onClose={this.props.onObjectInfoClose}
                                    onObjectInfo={this.props.onObjectInfo}
                                    user={this.context.user}
                                />
                            );
                        case "reservation":
                            return (
                                <ReservationPane
                                    key={panelName}
                                    user={this.context.user}
                                    activeLayer={this.props.activeLayer}
                                    reservationIds={this.props.reservationIds}
                                    isInfoEntryEdited={this.props.isInfoEntryEdited}
                                    onReservationEditChange={this.props.onReservationEditChange}
                                    isClusterInfo={this.props.isClusterInfo}
                                    onEntryInfoOpen={this.props.onEntryInfoOpen}
                                    isEntryInfo={this.props.isEntryInfo}
                                    onClose={this.props.onReservationInfoClose}
                                    onObjectInfo={this.props.onObjectInfo}
                                />
                            );
                        case "order":
                            return (
                                <OrderInfo
                                    key={panelName}
                                    orderIds={this.props.orderIds}
                                    onClose={this.props.onOrderInfoClose}
                                />
                            );
                        default:
                            return <></>;
                    }
                });
                return (
                    <div style={{ position: "relative", height: "100%", overflow: "hidden" }}>
                        <div className="multiPane" style={{ overflow: "scroll", height: "100%" }}>
                            {panels}
                        </div>
                        <ReservationPane
                            dynamic={true}
                            user={this.context.user}
                            activeLayer={this.props.activeLayer}
                            reservationIds={dynamicReservationIds}
                            isClusterInfo={this.props.isClusterInfo}
                            isEntryInfo={this.props.isEntryInfo}
                            onClose={this.props.onDynamicReservationInfoClose}
                            onObjectInfo={this.props.onObjectInfo}
                        />
                    </div>
                );
            case "help":
                return (
                    <Help
                        key={item.id}
                        showAbout={this.props.showAbout}
                        showShortcuts={this.props.showShortcuts}
                        hasAvailableUpdate={this.props.hasAvailableUpdate}
                        resetHelp={this.props.resetHelp}
                    />
                );
            case "pinboard":
                return (
                    <PinboardPane
                        key={item.id}
                        showExtraInfo={this.context.user.showExtraInfo}
                        scratchpad={this.context.scratchpad}
                    />
                );
            case "admin":
                return <AdminPane key={item.id} user={this.context.user} />;
            default:
                return <></>;
        }
    };

    onDragStart = (evt) => {
        _.setDragData(evt, "application/x-timeedit-menu", "");
    };

    onDragEnd = (event) => {
        this.props.onDragEnd(event);
    };

    getItems = () => {
        const user = this.context.user;

        const includePinboard = user.isAdmin || this.props.flags?.pinboard;

        const items = includePinboard
            ? this.props.data.items
            : this.props.data.items.filter((item) => item.id !== "pinboard");

        if (!user.useInfoPopover || this.props.appWidth < LayoutConstants.MIN_WIDTH_FOR_POPOVER) {
            return items;
        }

        return items.filter((item) => item.id !== "info");
    };

    render() {
        const menuItems = this.getItems().map(this.renderMenuItem);

        const isRight = this.props.position === MenuModel.POSITION.RIGHT;

        const classes = {
            popover: true,
            hidden: !this.props.data.isOpen,
            right: isRight,
            left: !isRight,
        };

        const menuClasses = {
            right: isRight,
            left: !isRight,
        };

        return (
            <div id="menuWrapper">
                <ul
                    id="menu"
                    style={{ height: this.props.appHeight }}
                    className={_.classSet(menuClasses)}
                    draggable={true}
                    onDragStart={this.onDragStart}
                    onDragEnd={this.onDragEnd}
                >
                    {menuItems}
                </ul>

                <div
                    id="menuPopover"
                    style={{ height: this.props.appHeight }}
                    className={_.classSet(classes)}
                >
                    {this.props.data.isOpen ? this.getCurrentComponent() : null}
                </div>
            </div>
        );
    }

    renderMenuItem = (item) => {
        const isActiveItem = item.id === this.props.data.activeMenu;
        const classes = {
            active: this.props.data.isOpen && isActiveItem,
            indicator: this.props.hasAvailableUpdate,
        };
        classes[item.id] = true;
        if (
            item.id === "account" &&
            this.props.isChristmasTime &&
            this.context.user.christmasEnabled
        ) {
            classes.xmasMode = true;
        }

        const tooltip = Language.get(item.label);

        const isRight = this.props.position === MenuModel.POSITION.RIGHT;

        return (
            <li
                id={item.id}
                ref={item.id}
                key={item.id}
                className={_.classSet(classes)}
                onClick={this.getMenuAction(item)}
                onMouseOver={this.showTooltip.bind(this, item.id)}
                onMouseOut={this.hideTooltip.bind(this, item.id)}
            >
                <Tooltip
                    className="tooltip"
                    height={26}
                    width={tooltip.length * TOOLTIP_WIDTH_MULTIPLIER}
                    horizontalPosition={isRight ? "right" : "left"}
                    verticalPosition="top"
                    show={this.isTooltipVisible(item.id)}
                >
                    <a />
                    <span>{tooltip}</span>
                </Tooltip>
            </li>
        );
    };
}

export default withLDConsumer()(Menu);
