const errorCorrectLoadedSettings = (loadedSettings) => {
    let result = loadedSettings.map((loadedSetting) => {
        let selectedCategories = { ...loadedSetting.settings.selectedCategories };
        Object.keys(selectedCategories).forEach((key) => {
            let selectedValues = selectedCategories[key];
            selectedCategories[key] = selectedValues.map((value) =>
                Array.isArray(value) ? value.flat() : value
            );
        });
        return { ...loadedSetting, settings: { ...loadedSetting.settings, selectedCategories } };
    });
    return result;
};

export default errorCorrectLoadedSettings;
