import PropTypes from "prop-types";
import React from "react";
import addClass from "./add-class";

// eslint-disable-next-line react/prefer-stateless-function
class Option extends React.Component {
    static propTypes = {
        /**
         * The value that will be sent to the `onSelect` handler of the
         * parent Combobox.
         */
        value: PropTypes.any.isRequired,

        /**
         * What value to put into the input element when this option is
         * selected, defaults to its children coerced to a string.
         */
        label: PropTypes.string,

        /**
         * Whether the element should be selectable
         */
        isfocusable: PropTypes.string,
        isselected: PropTypes.string,
    };

    static defaultProps = {
        role: "option",
        tabIndex: "-1",
        className: "ic-tokeninput-option",
        isselected: false,
        isfocusable: "true",
    };

    render() {
        const props = { ...this.props };
        if (props.isselected === "true") {
            props.className = addClass(props.className, "ic-tokeninput-selected");
            props.ariaSelected = true;
        }
        return <div {...props} />;
    }
}

export default Option;
