import React from "react";
import PropTypes from "prop-types";
import Language from "../lib/Language";
import SummaryEditor from "./SummaryEditor";
import API from "../lib/TimeEditAPI";
import _ from "underscore";
import { TimeConstants as TC } from "../lib/TimeConstants";

const DECIMAL = "nc_summary_decimal";
const HOURS = "nc_summary_hours";
const MINUTES = "nc_summary_minutes";
const ALL_WEEKS = 0;
const NO_SUM = 0;
const ADD_NEW = -1;
const HUNDRED_PERCENT = 100;
import { SimpleDateFormat, MillenniumWeek, MillenniumTime } from "@timeedit/millennium-time";

const toTypeClass = (id) => ({ class: "typeid", id });

const groupSummaries = (summaries) => {
    const groups = {};
    summaries.forEach((summary) => {
        const section = summary.objects[0].id;
        if (!groups[section]) {
            groups[section] = [];
        }
        groups[section] = groups[section].concat(summary);
    });
    return _.values(groups);
};

const formatSum = (sum, unit) => {
    if (unit === HOURS) {
        return Language.get(
            "nc_hours_short",
            new MillenniumTime(sum).format(Language.getDateFormat("date_f_h_mm"))
        );
    }
    if (unit === DECIMAL) {
        return Language.get(
            "nc_hours_short",
            String(
                Math.round((sum / TC.SECONDS_PER_HOUR) * HUNDRED_PERCENT) / HUNDRED_PERCENT
            ).replace(".", Language.get("zz_dec_decimal_separator"))
        );
    } // SUM_UNIT_MINUTES
    return Language.get(
        "nc_minutes_short",
        Math.floor((sum * HUNDRED_PERCENT) / TC.MINUTES_PER_HOUR) / HUNDRED_PERCENT
    );
};

class SummaryWindow extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            selectedSumUnit: props.selectedSumUnit || DECIMAL,
            objects: [],
            isShowMore: false,
            isEditMode: false,
            typeSettings: {},
            primaryFields: [],
        };
        const summaryObjects = this.getSummaryObjects(props);
        if (summaryObjects.length > 0) {
            this.updateObjects(summaryObjects, props);
        }
        if (props.selectedFilter && props.selectedFilter.types) {
            this.updateFields(props.selectedFilter.types);
        }
    }
    static contextTypes = {
        user: PropTypes.object.isRequired,
        customWeekNames: PropTypes.array,
    };

    getSummaryObjects(props) {
        if (!props.summaries) {
            return [];
        }
        return _.flatten(props.summaries.map((summary) => summary.objects.map((obj) => obj.id)));
    }

    componentDidUpdate(prevProps) {
        const oldSummaryObjects = this.getSummaryObjects(prevProps);
        const newSummaryObjects = this.getSummaryObjects(this.props);
        if (!_.isEqual(oldSummaryObjects, newSummaryObjects)) {
            this.updateObjects(newSummaryObjects, this.props);
        }
        if (
            !_.isEqual(prevProps.selectedFilter, this.props.selectedFilter) &&
            this.props.selectedFilter
        ) {
            this.updateFields(this.props.selectedFilter.types);
        }
    }

    updateObjects(summaryObjects, props) {
        API.getObjects(summaryObjects, (objects) => {
            this.setState({ objects });
        });
        const typeSettings = {};
        const types = _.uniq(
            _.flatten(
                props.summaries.map((summary) => summary.objects.map((object) => object.type.id))
            )
        );
        const calls = types.map((type) => (done) => {
            API.getPreferences("objectListColumns", [toTypeClass(type)], (result) => {
                if (result) {
                    const ts = JSON.parse(result[0]);
                    typeSettings[type] = ts;
                }
                done();
            });
        });
        _.runAsync(calls, () => {
            this.setState({ typeSettings });
        });
    }

    updateFields(types) {
        API.findFieldsForTypes(types, false, true, (result) => {
            if (result) {
                const primaryFields = result.map((fld) => fld.id);
                this.setState({ primaryFields });
            }
        });
    }

    isChecked(sumType) {
        return sumType === this.state.selectedSumUnit;
    }

    onSumChanged(event) {
        let selectedSum = parseInt(event.target.value, 10);
        if (selectedSum === ADD_NEW) {
            selectedSum = NO_SUM;
            this.setState({
                selectedSum,
                isEditMode: true,
            });
        } else {
            this.setState({
                selectedSum,
                isEditMode: false,
            });
        }
        this.props.onSummaryChanged({ selectedSum, selectedWeek: this.props.selectedSummaryWeek });
    }

    onWeekChanged(event) {
        const selectedWeek = parseInt(event.target.value, 10);
        this.props.onSummaryChanged({ selectedSum: this.props.selectedSummary, selectedWeek });
    }

    onChecked(value) {
        this.setState({
            selectedSumUnit: value,
        });
        this.props.onSumUnitChanged(value);
    }

    dismiss() {
        this.props.onClose();
    }

    onSummaryDone(name, id, selectedTypes, includeMembers, isFrameTime, templateGroup) {
        const result = {
            name,
            id,
            types: selectedTypes,
            includeMembers,
            isFrameTime,
            templateGroup,
        };
        this.props.onSummaryDone(result);
        this.toggleEditMode();
    }

    onSummaryDelete(filterId) {
        this.props.onSummaryDelete(filterId);
        this.toggleEditMode();
    }

    onSummaryCancelled() {
        this.toggleEditMode();
    }

    getObjectName(object) {
        const found = _.find(this.state.objects, (obj) => obj.id === object.id);
        if (found) {
            const pref = this.state.typeSettings[object.type.id];
            let field;
            if (pref) {
                const primaryFields = pref.defaultColumns
                    .filter((col) => col.primary)
                    .map((col) => col.id);
                field = _.find(found.fields, (fld) => primaryFields.indexOf(fld.id) !== -1);
            }
            if (!field) {
                field = _.find(
                    found.fields,
                    (fld) => this.state.primaryFields.indexOf(fld.id) !== -1
                );
            }
            if (field) {
                return field.values.join(", ");
            }
        }
        return String(object.id);
    }

    _renderObjectSum(summary, sumUnit, objects, objectName, ignoreSubHeader) {
        if (objects.length === 1) {
            return (
                <tr key={`${objectName}:${objects[0].id}`} className="summaryLine">
                    <td>{this.getObjectName(objects[0])}</td>
                    <td>{formatSum(summary.length, this.state.selectedSumUnit)}</td>
                </tr>
            );
        }
        const object = objects.shift();
        const nextObjectName = this.getObjectName(object);
        const header = !ignoreSubHeader ? (
            <tr key={objects.map((obj) => obj.id).join(",")} className="summaryLine">
                <td>{nextObjectName}</td>
                <td />
            </tr>
        ) : null;
        return [header, this._renderObjectSum(summary, sumUnit, objects, nextObjectName)];
    }

    _renderSummaries() {
        if (!this.props.summaries || this.props.summaries.length < 1) {
            return null;
        }
        const groups = groupSummaries(this.props.summaries);
        if (groups[0][0].objects.length === 1) {
            return (
                <table className="summarySection">
                    <tbody>
                        {groups.map((summaries) =>
                            summaries.map((summary) =>
                                this._renderObjectSum(
                                    summary,
                                    this.state.selectedSumUnit,
                                    [].concat(summary.objects)
                                )
                            )
                        )}
                    </tbody>
                </table>
            );
        }
        return (
            <div className="summaryScroller">
                {groups.map((summaries, index) => {
                    const headlineObject = summaries[0].objects[0];
                    let objectName = null;
                    return (
                        <table key={index} className="summarySection">
                            <tbody>
                                <tr className="summaryHeadline">
                                    <td colSpan="2">{this.getObjectName(headlineObject)}</td>
                                </tr>
                                {summaries.map((summary) => {
                                    const objects = [].concat(summary.objects);
                                    objects.shift(); // Remove the headline object
                                    const nextObjectName = this.getObjectName(objects[0]);
                                    const ignoreSubHeader = nextObjectName === objectName;
                                    objectName = nextObjectName;
                                    return this._renderObjectSum(
                                        summary,
                                        this.state.selectedSumUnit,
                                        objects,
                                        null,
                                        ignoreSubHeader
                                    );
                                })}
                            </tbody>
                        </table>
                    );
                })}
            </div>
        );
    }

    showMore() {
        this.setState({ isShowMore: true });
    }

    showLess() {
        this.setState({ isShowMore: false });
    }

    toggleEditMode() {
        this.setState({ isEditMode: !this.state.isEditMode });
    }

    render() {
        if (!this.props.isVisible) {
            return null;
        }
        let weekSelection = null;
        const clusterWeeks = this.props.clusterWeeks;
        if (clusterWeeks.length > 0 && clusterWeeks[0] instanceof MillenniumWeek) {
            weekSelection = (
                <div>
                    <label>{Language.get("nc_summary_week")}</label>
                    <br />
                    <select
                        value={this.props.selectedSummaryWeek}
                        onChange={this.onWeekChanged.bind(this)}
                    >
                        <option value={ALL_WEEKS}>{Language.get("week_list_all")}</option>
                        {clusterWeeks.map((cv) => (
                            <option key={cv.getWeek(true)} value={cv.getWeek(true)}>
                                {Language.getCustomWeekShortest(
                                    cv.getStartOfWeek(),
                                    this.context.customWeekNames
                                ) ||
                                    SimpleDateFormat.format(
                                        cv.getStartOfWeek(),
                                        Language.getDateFormat("date_f_yyyy_ww")
                                    )}
                            </option>
                        ))}
                    </select>
                </div>
            );
        }
        return (
            <div className="summaryWindow windowFrame">
                <div className="windowHeader">
                    {Language.get("nc_reservation_summary")}
                    <div className="dismiss" onClick={this.dismiss.bind(this)} />
                </div>
                <div className="windowBody">
                    <div>
                        <label>{Language.get("nc_summary_do_sum")}</label>
                        <div className="listTopLine">
                            <select
                                className="sumSelect"
                                value={this.props.selectedSummary}
                                onChange={this.onSumChanged.bind(this)}
                            >
                                <option value={NO_SUM}>
                                    {Language.get("nc_summary_do_not_sum")}
                                </option>
                                {this.props.filters.map((filter) => (
                                    <option value={filter.id} key={filter.name}>
                                        {filter.name}
                                    </option>
                                ))}
                                {this.context.user.isAdmin ? (
                                    <option disabled>──────────</option>
                                ) : null}
                                {this.context.user.isAdmin ? (
                                    <option value={ADD_NEW} key={ADD_NEW}>
                                        {Language.get("nc_new_summary")}
                                    </option>
                                ) : null}
                            </select>
                            {this.context.user.isAdmin ? (
                                <button
                                    className={
                                        this.props.selectedSummary === NO_SUM
                                            ? "addButton"
                                            : "editButton"
                                    }
                                    onClick={this.toggleEditMode.bind(this)}
                                >
                                    {this.props.selectedSummary === NO_SUM ? "+" : ""}
                                </button>
                            ) : null}
                        </div>
                    </div>
                    {this.state.isShowMore ? (
                        <span>
                            <div className="rightAligned">
                                <button onClick={this.showLess.bind(this)}>
                                    {Language.get("nc_dialog_show_less")}
                                </button>
                            </div>
                            {weekSelection}
                            <div>
                                <label>{Language.get("nc_show_summary_as")}</label>
                                <br />
                                {[DECIMAL, HOURS, MINUTES].map((value) => (
                                    <span key={value}>
                                        <input
                                            type="radio"
                                            value={value}
                                            checked={this.isChecked(value)}
                                            onChange={this.onChecked.bind(this, value)}
                                        />{" "}
                                        {Language.get(value)}
                                    </span>
                                ))}
                            </div>
                        </span>
                    ) : (
                        <div className="rightAligned">
                            <button onClick={this.showMore.bind(this)}>
                                {Language.get("nc_dialog_show_more")}
                            </button>
                        </div>
                    )}

                    {this.context.user.isAdmin && this.state.isEditMode ? (
                        <div>
                            <SummaryEditor
                                selectedFilter={this.props.selectedFilter}
                                onDone={this.onSummaryDone.bind(this)}
                                onCancel={this.onSummaryCancelled.bind(this)}
                                onDelete={this.onSummaryDelete.bind(this)}
                                templateKindNumber={this.props.templateKindNumber}
                            />
                        </div>
                    ) : null}
                    {this.state.isEditMode ? null : this._renderSummaries()}
                </div>
            </div>
        );
    }
}

SummaryWindow.DECIMAL = DECIMAL;
SummaryWindow.HOURS = HOURS;
SummaryWindow.MINUTES = MINUTES;
SummaryWindow.ALL_WEEKS = ALL_WEEKS;
SummaryWindow.NO_SUM = NO_SUM;
SummaryWindow.formatSum = formatSum;

export default SummaryWindow;
