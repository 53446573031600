import Language from "../lib/Language";

const getMembershipColumn = () => ({
    id: 22000,
    name: Language.get("nc_member_period"),
    sortable: false,
    primary: false,
});

export default getMembershipColumn;
