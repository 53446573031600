import React from "react";
import TimeInput from "./TimeInput";

class TimeRangeInput extends React.Component {
    state = {
        start: this.props.defaultValue.start,
        end: this.props.defaultValue.end,
    };

    onChange = (prop, value) => {
        const update = {
            start: this.state.start,
            end: this.state.end,
        };
        update[prop] = value;

        this.setState(update);
        this.props.onUpdate(update);
    };

    render() {
        return (
            <div className="timeRange">
                <TimeInput
                    defaultValue={this.state.start}
                    onUpdate={this.onChange.bind(this, "start")}
                />
                -
                <TimeInput
                    defaultValue={this.state.end}
                    onUpdate={this.onChange.bind(this, "end")}
                />
            </div>
        );
    }
}

export default TimeRangeInput;
