import { PeriodHeader } from "./PeriodHeader";
import _ from "underscore";

export class TimePeriodHeader extends PeriodHeader {
    constructor(visibleValues, firstVisibleValue, subheader?) {
        super(visibleValues || 1, firstVisibleValue, subheader, "TimePeriodHeader");
    }

    getKind() {
        return PeriodHeader.KIND.TIME;
    }

    /**
     * A simple period is a period where all cells only contains a single value and
     * no values overlap eachother. However, since entries are only split into subentries
     * on a per-date-basis, one such entry can be part of multiple periods. Therefore
     * we must handle all time periods as non-simple.
     */
    // eslint-disable-next-line no-unused-vars
    isSimplePeriod(index) {
        // eslint-disable-line no-unused-vars
        return false;
    }

    periodsToJSON() {
        return this.values.map((times) =>
            times.map((range) => ({
                start: range.start.getTimeNumber(),
                end: range.end.getTimeNumber(),
            }))
        );
    }

    toJSON() {
        const json = super.toJSON();
        return _.extend(json, {
            timeProvider: true,
            kind: "periodtime",
            periods: this.periodsToJSON(),
        });
    }
}
