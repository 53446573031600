import _ from "underscore";

export class Settings {
    items: any;
    constructor(items) {
        this.items = items || [];
    }

    find(id) {
        return _.find(this.items, (el) => el.id === id);
    }
}
