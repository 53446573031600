import PropTypes from "prop-types";
import React from "react";
import Lang from "../lib/Language";

class ConfirmButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isClickable: this.props.disabled !== undefined ? !this.props.disabled : true,
            confirmMode: false,
        };
        this.displayName = "ConfirmButton";
    }

    componentDidUpdate(prevProps) {
        if (this.props.disabled === prevProps.disabled) {
            return;
        }

        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
            isClickable:
                this.props.disabled !== undefined ? !this.props.disabled : this.state.isClickable,
            confirmMode: false,
        });
    }

    onClick(event) {
        // eslint-disable-next-line no-alert
        if (confirm(this.props.confirmMessage || Lang.get("nc_button_click_to_confirm"))) {
            this.props.onClick(event);
        }
    }

    render() {
        return (
            <button
                className={this.props.className}
                onClick={this.onClick.bind(this)}
                disabled={!this.state.isClickable}
            >
                {this.props.children}
            </button>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.props.onClick !== nextProps.onClick ||
            this.props.confirmMessage !== nextProps.confirmMessage ||
            this.props.disabled !== nextProps.disabled ||
            this.props.children !== nextProps.children ||
            this.props.className !== nextProps.className ||
            this.state.isClickable !== nextState.isClickable
        );
    }
}

ConfirmButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    waitTime: PropTypes.number,
    confirmText: PropTypes.string,
    disabled: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
};

export default ConfirmButton;
