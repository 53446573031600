const toSearchCriteria = (filterFields) => {
    const typeFilterMap = {};
    filterFields.forEach((filter) => {
        let type = typeFilterMap[filter.typeId];
        if (!type) {
            type = { type: filter.type, categories: [] };
        }
        type.categories = filter.fields.map((field) => ({
            id: field.fieldExtId,
            values: field.values,
        }));
        typeFilterMap[filter.typeId] = type;
    });
    return Object.values(typeFilterMap);
};

/* Returns a list of objects:
{
    "type": {
        "class": "typename",
        "extid": "room",
        "id": 4,
        "name": "Lokal"
    },
    "categories": [
        {
            "id": "room.equipment",
            "values": [
                "Mikrofon, 5 st"
            ]
        }
    ]
}
*/
const toSearchCriteriaFromObjects = (filterObjects) => {
    const typeFilterMap = {};
    filterObjects.forEach((filter) => {
        let type = typeFilterMap[filter.extId];
        if (!type) {
            type = { type: { class: "typename", extid: filter.extId }, categories: [] };
        }
        type.categories = filter.value.categories.map((field) => ({
            id: field.id,
            values: field.values,
        }));
        typeFilterMap[filter.extId] = type;
    });
    // eslint-disable-next-line no-console
    console.log(Object.values(typeFilterMap));
    return Object.values(typeFilterMap);
};

export { toSearchCriteria, toSearchCriteriaFromObjects };
