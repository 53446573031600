import React from "react";
import _ from "underscore";

export default {
    wrap(Component) {
        return class extends React.Component {
            static displayName = Component.displayName;

            state = {
                isResizing: false,
                startPosition: null,
                lastMousePosition: null,
            };

            onResizeStart = (evt) => {
                document.addEventListener(_.getEndEvent(evt), this.onResizeEnd);
                document.addEventListener(_.getMoveEvent(evt), this.onResizeMove);

                evt.stopPropagation();

                this.setState({
                    startPosition: _.clone(evt),
                    isResizing: true,
                    lastMousePosition: _.clone(evt),
                });
            };

            onResizeMove = (evt) => {
                if (!this.state.isResizing) {
                    return;
                }

                this.setState({
                    lastMousePosition: _.clone(evt),
                });
            };

            setResizeEndCallback = (callback) => {
                this._resizeEndCallback = callback;
            };

            onResizeEnd = (evt) => {
                if (!this.state.isResizing) {
                    return;
                }
                document.removeEventListener(_.getEndEvent(evt), this.onResizeEnd);
                document.removeEventListener(_.getMoveEvent(evt), this.onResizeMove);

                this.setState({ isResizing: false });

                if (this._resizeEndCallback) {
                    this._resizeEndCallback(evt);
                }
            };

            render() {
                return (
                    <Component
                        {...this.props}
                        {...this.state}
                        onResizeStart={this.onResizeStart}
                        setResizeEndCallback={this.setResizeEndCallback}
                    />
                );
            }
        };
    },
};
