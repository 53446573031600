import { getUserLanguage } from "./LangUtils";

import React from "react";
import ReactDOM from "react-dom";
import API from "./TimeEditAPI";
import { MillenniumDate, MillenniumDateTime, MillenniumTime } from "@timeedit/millennium-time";
import DataStore from "./DataStore";
import Log from "./Log";
import FastClick from "fastclick";
import Locale from "../lib/Language";
import _ from "underscore";
import path from "path";
import { AuthWrapper } from "@timeedit/te-auth-components/lib";
import { EAppId } from "@timeedit/types/lib/enums/appIds.enum";
import { getAccessToken } from "@timeedit/te-auth-components/lib/services/token.service";

import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

import * as Sentry from "@sentry/react";
import { ViewList } from "../models/ViewList";
import { Menu } from "../models/Menu";
import { App } from "../models/App";
import AppComponent from "../components/App";
import ErrorBoundary from "../components/ErrorBoundary";

const getLdClientSideId = (env) => {
    console.log(env);
    switch (env) {
        case "development":
        case "beta":
            return "65e829cc7386bf105e2e28d5";
        case "staging":
            return "65e829836880eb10b9f4edca";
        // Production
        default:
            return "65a938f0183d141061d386f9";
    }
};

export const TimeEdit = (function () {
    const State = new DataStore({});

    let usingSSO = false;

    const AUTH_URLS = {
        development: "http://localhost:3000",
        beta: "https://auth-beta.timeedit.io",
        newBeta: "https://auth.timeedit.dev",
        staging: "https://auth.timeedit.io",
        production: "https://auth.timeedit.net",
    };

    const getAuthURL = (env) => {
        if (!env) {
            return undefined;
        }
        if (env.serverEnv === "beta" && env.useDevDomainForBetaEnv) {
            return AUTH_URLS.newBeta;
        }
        return AUTH_URLS[env.serverEnv];
    };

    const renderApp = async function (LDProvider, useSSO = false, env = undefined) {
        const onLogin = (token) => {
            // eslint-disable-next-line no-console
            console.log("Got token", token);
            const aToken = getAccessToken();
            // eslint-disable-next-line no-console
            console.log(aToken);
            API.loginWithSSOToken(aToken, (result) => {
                if (result[0] && result[0].class === "error") {
                    // eslint-disable-next-line no-console
                    console.log(result);
                    // eslint-disable-next-line no-console
                    console.log("Not authenticated");
                    TimeEdit.State.update(TimeEdit.State.get(), { token: null, logoutAuth: true });
                } else {
                    // eslint-disable-next-line no-console
                    console.log("SSO login result:", result);
                    if (result.parameters[0] === false) {
                        // eslint-disable-next-line no-console
                        console.log("Not authenticated");
                        TimeEdit.State.update(TimeEdit.State.get(), {
                            token: null,
                            logoutAuth: true,
                        });
                    } else {
                        TimeEdit.State.update(TimeEdit.State.get(), { token, logoutAuth: false });
                    }
                }
            });
        };

        const onLogout = () => {
            // eslint-disable-next-line no-console
            console.log("On logout");
            TimeEdit.State.update(TimeEdit.State.get(), { token: null });
        };

        usingSSO = useSSO;

        if (useSSO) {
            ReactDOM.render(
                <LDProvider>
                    <ErrorBoundary>
                        <AuthWrapper
                            debugLevel="NONE"
                            onLoginSuccess={onLogin}
                            onLogout={onLogout}
                            urls={{
                                BASE_URL: window.location.href,
                                AUTH_URL: getAuthURL(env), // Do a lookup based on env
                            }}
                            appId={EAppId.TE_CORE}
                        >
                            <AppComponent {...State.get().asProps()} usingSSO={true} env={env} />
                        </AuthWrapper>
                    </ErrorBoundary>
                </LDProvider>,
                document.getElementById("container")
            );
        } else {
            ReactDOM.render(
                <LDProvider>
                    <ErrorBoundary>
                        <AppComponent {...State.get().asProps()} usingSSO={false} env={env} />
                    </ErrorBoundary>
                </LDProvider>,
                document.getElementById("container")
            );
        }
        FastClick.attach(document.body);
    };

    const logoutUser = function (ssoLogoutFn) {
        if (usingSSO) {
            // eslint-disable-next-line no-console
            console.log("Logging out using SSO");
            // eslint-disable-next-line no-unused-vars
            API.logoutUser((result) => {
                if (ssoLogoutFn) {
                    ssoLogoutFn();
                }
            });
        } else {
            window.location.href = path.resolve(
                "/",
                window.TIMEEDIT_APP_PATH,
                `logout?lang=${getUserLanguage()}`
            );
        }
    };

    const run = async function (useSSO = false, env = undefined) {
        Sentry.init({
            dsn: "https://ff17d752ee011c2d3e654725fb456567@o4506875039318016.ingest.us.sentry.io/4506875378008064",
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });

        const menuItems = [
            { id: "views", label: "permission_views", model: new ViewList() },
            { id: "settings", label: "nc_menu_calendar_settings" },
            { id: "layers", label: "nc_drafts_title" },
            { id: "account", label: "nc_account_settings" },
            { id: "info", label: "cal_res_side_tab_res_info" },
            { id: "pinboard", label: "nc_pinboard" },
            { id: "help", label: "menu_help" },
        ];

        const menu = new Menu(menuItems);
        menu.freeze();
        State.update(new App(menu, null));

        const LDProvider = await asyncWithLDProvider({
            clientSideID: getLdClientSideId(env.serverEnv),
            context: {
                kind: "uninitialized",
                key: "uninitialized",
            },
            options: {},
        });

        State.subscribe(renderApp.bind(this, LDProvider, useSSO, env));
        renderApp(LDProvider, useSSO, env);
    };

    const serverIsAtLeast = function (major, minor) {
        return (
            TimeEdit.serverInfo.server.major >= major && TimeEdit.serverInfo.server.minor >= minor
        );
    };

    const getCompletePropertyId = function () {
        return _.find(TimeEdit.entryPropertyDefinitions, (def) => def.complete === true).id;
    };

    const presentShortcut = (shortcut) => shortcut.replace("mod", Locale.get("nc_key_mod_short"));

    return {
        State,
        logoutUser,
        run,
        serverIsAtLeast,
        getCompletePropertyId,
        DEBUG: false,
        presentShortcut,
    };
})();

if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    Log.listen((event) => console.log(JSON.stringify(event)), Log.ALL);
    TimeEdit.API = API;
    TimeEdit.Log = Log;
    TimeEdit.Locale = Locale;
    TimeEdit.MillenniumDate = MillenniumDate;
    TimeEdit.MillenniumDateTime = MillenniumDateTime;
    TimeEdit.toDate = function (dateNumber) {
        return new MillenniumDate(dateNumber).format("yyyy-MM-dd");
    };
    TimeEdit.toDateTime = function (mts) {
        return new MillenniumDateTime(mts).format("yyyy-MM-dd HH:mm:ss");
    };
    TimeEdit.toTime = function (mts) {
        return new MillenniumTime(mts).format("HH:mm:ss");
    };
    window.TimeEdit = TimeEdit;
}
