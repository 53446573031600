import PropTypes from "prop-types";
import React from "react";
import API from "../lib/TimeEditAPI";
import Language from "../lib/Language";
import Log from "../lib/Log";
import pkg from "../../package.json";
import _ from "underscore";

class Help extends React.Component {
    constructor(props) {
        super(props);
        this.displayName = "Help";
        this.TARGET = "_blank";
        this.state = {
            supportURL: "",
            supportInfoLines: [],
            supportContacts: [],
        };
    }

    componentDidMount() {
        this._isMounted = true;
        API.getSupportInfo((supportURL, supportInfoLines, supportContacts) =>
            this.setStateIfMounted({
                supportURL: supportURL || "",
                supportInfoLines,
                supportContacts: supportContacts || [],
            })
        );
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setStateIfMounted(newState) {
        if (this._isMounted) {
            this.setState(newState);
        }
    }

    resetHelp(event) {
        API.setPreferences("viewedHelp", ["{}"], () => {
            Log.info(Language.get("nc_client_side_user_menu_help_reset"));
            this.props.resetHelp();
        });
        event.preventDefault();
    }

    createIssue() {
        const lang = this.context.user.language || this.getLanguageString();
        const isNordic = _.any(["sv", "nb", "da"], (lg) => lg === lang);
        const url = isNordic
            ? "https://support.timeedit.net/hc/sv"
            : "https://support.timeedit.net/hc/en-us";
        // eslint-disable-next-line no-undef
        //mixpanel.track("Open help center", {});
        window.open(url, this.TARGET);
    }

    getLanguageString() {
        return navigator.languages ? navigator.languages[0] : navigator.language;
    }

    openVersionInfo() {
        const lang = this.context.user.language || this.getLanguageString();
        const url = `http://help.timeedit.se/shortcuts/about_client?version=${pkg.version}&lang=${lang}`;
        window.open(url, this.TARGET);
    }

    showAbout() {
        this.props.showAbout(this.state.supportInfoLines);
    }

    render() {
        const reportIssue = (
            <div>
                <div className="btnGroup vertical">
                    <button className="button save" onClick={this.createIssue.bind(this)}>
                        {Language.get("nc_help_center")}
                    </button>
                </div>
            </div>
        );

        return (
            <div className="helpPane">
                <h2>{Language.get("menu_help")}</h2>

                {this._renderUpdateInfo()}

                {this._renderSupportContacts()}

                {reportIssue}

                <div className="btnGroup vertical">
                    <button className="save" onClick={this.props.showShortcuts}>
                        {Language.get("nc_keyboard_shortcuts")}
                    </button>
                </div>
                <div className="btnGroup vertical">
                    <button className="save" onClick={this.showAbout.bind(this)}>
                        {Language.get("about_window_title")}
                    </button>
                </div>

                <div className="btnGroup vertical">
                    <button className="save" onClick={this.resetHelp.bind(this)}>
                        {Language.get("nc_menu_reset_help")}
                    </button>
                </div>
            </div>
        );
    }

    _renderSupportContacts() {
        if (this.state.supportContacts.length === 0) {
            return null;
        }

        const renderLine = (label, value, link) => {
            if (!value) {
                return null;
            }
            if (link) {
                return (
                    <li>
                        {label ? <b>{label}: </b> : null}
                        <a href={link} target={this.TARGET}>
                            {value}
                        </a>
                    </li>
                );
            }
            return (
                <li>
                    <b>{label}:</b> {value}
                </li>
            );
        };

        const style = {
            padding: "5px",
            paddingBottom: "10px",
            borderBottom: "1px solid #eee",
            marginBottom: "10px",
        };

        return (
            <div>
                {this.state.supportContacts.map((contact, index) => (
                    <div key={index} style={style}>
                        <p style={{ marginTop: "0" }}>{contact.description}</p>
                        <ul>
                            {renderLine(Language.get("support_window_name"), contact.name)}
                            {renderLine(
                                Language.get("support_window_email"),
                                contact.email,
                                `mailto:${contact.email}`
                            )}
                            {renderLine(
                                Language.get("support_window_phone"),
                                contact.phone,
                                `tel://${contact.phone}`
                            )}
                            {renderLine(null, contact.url_title || contact.url, contact.url)}
                            {renderLine(
                                null,
                                contact.manual_url_title || contact.manual_url,
                                contact.manual_url
                            )}
                        </ul>
                    </div>
                ))}
            </div>
        );
    }

    _renderUpdateInfo() {
        if (!this.props.hasAvailableUpdate) {
            return null;
        }

        const style = {
            backgroundColor: "rgb(0, 103, 109)",
            padding: "5px",
            border: "1px solid #111",
        };

        return (
            <div style={style}>
                <strong>{Language.get("nc_timeedit_update_available")}</strong>
                <br />
                {Language.get("nc_timeedit_reload_to_get_latest_version")}
            </div>
        );
    }
}

Help.contextTypes = {
    user: PropTypes.object,
};

export default Help;
