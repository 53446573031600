import PropTypes from "prop-types";
import React from "react";
import API from "../lib/TimeEditAPI";
import ArrayInput from "./ArrayInput";
import _ from "underscore";

const DEFAULT_SIZE = 5;

type TColorTypeInputProps = {
    onUpdate: (...args: any[]) => any;
    defaultValue: any[];
    defaultSize: number;
    limit: number;
};
class ColorTypeInput extends React.Component<TColorTypeInputProps> {
    static propTypes = {
        defaultValue: PropTypes.arrayOf(PropTypes.number).isRequired,
        onUpdate: PropTypes.func,
    };

    state = {
        types: [],
    };

    componentDidMount() {
        API.getColorTypes((types) => {
            this.setState({ types: [{ id: 0, name: "-" }].concat(types) });
        });
    }

    render() {
        const types = this.state.types.map((type) => ({
            value: type.id,
            label: type.name,
            selected:
                _.contains(this.props.defaultValue, type.id) ||
                (type.id === 0 && this.props.defaultValue.length === 0),
        }));
        return (
            <ArrayInput
                defaultValue={types}
                disabled={this.state.types.length === 0}
                onUpdate={this.props.onUpdate}
                limit={this.props.limit || 0}
                defaultSize={this.props.defaultSize || DEFAULT_SIZE}
            />
        );
    }
}

export default ColorTypeInput;
