import _ from "underscore";

const createReservationGroupNameFromTrack = (track) => {
    // eslint-disable-next-line no-console
    console.log(track);
    const firstActivity = track[0];
    return `${firstActivity.primaryObject}-${firstActivity.track}`;
};

// Checks the categories object, returns all field ids for which matchAll should be active
// If fieldDefs are provided, also filters out any fields which do not allow multiple values
const getMatchAllIds = (categories, fieldDefs?) => {
    const matchAllIds: number[] = [];

    Object.keys(categories).forEach((key) => {
        const values = categories[key];
        let matchAll = false;
        if (values.length > 1) {
            matchAll = _.every(values, (vl) => vl.length === 1);
        }
        const fieldId = parseInt(key, 10);
        if (fieldDefs) {
            const def = _.find(fieldDefs, (df) => df.id === fieldId);
            if (def && !def.multiple) {
                matchAll = false;
            }
        }
        if (matchAll) {
            matchAllIds.push(fieldId);
        }
    });
    return matchAllIds;
};

export default { createReservationGroupNameFromTrack, getMatchAllIds };
