import PropTypes from "prop-types";
import React from "react";
import FieldInput from "./FieldInput";
import Language from "../lib/Language";

class FieldTableRow extends React.Component {
    static propTypes = {
        field: PropTypes.object.isRequired,
        definition: PropTypes.object.isRequired,
        editable: PropTypes.bool,
        addField: PropTypes.func,
        removeField: PropTypes.func,
        setFieldValue: PropTypes.func,
        deleteButton: PropTypes.func,
        includeCheckbox: PropTypes.bool,
        hasExternalValue: PropTypes.bool,
        clearExternalValue: PropTypes.func,
        isCompact: PropTypes.bool,
        maxFieldValues: PropTypes.number,
    };

    static defaultProps = {
        editable: true,
        isCompact: false,
    };

    state = {
        includeCheckbox: true,
        showDescription: false,
    };

    addField = () => {
        this.props.addField(this.state.includeCheckbox);
    };

    removeField = (index) => {
        this.props.removeField(index, this.state.includeCheckbox);
    };

    setFieldValue = (valueIndex, newValue, event) => {
        this.props.setFieldValue(event, newValue, valueIndex, this.state.includeCheckbox);
    };

    toggleInclude = (event) => {
        if (this.props.setFieldValue) {
            this.props.setFieldValue(null, null, 0, event.target.checked);
        }
        this.setState({ includeCheckbox: event.target.checked });
    };

    hasValue = () => this.props.field.values && this.props.field.values.length > 0;

    toggleDescription = () => {
        this.setState({ showDescription: !this.state.showDescription });
    };

    isComment = () => this.props.definition.kind === FieldInput.fieldKind.COMMENT;

    clearExternalValue = () => {
        this.props.clearExternalValue(this.props.definition, this.state.includeCheckbox);
    };

    hasMaxValues = () => {
        if (
            this.props.field.values &&
            this.props.field.values.length === this.props.maxFieldValues
        ) {
            return true;
        }
        return false;
    };

    render() {
        let inputElements;

        if (this.hasValue()) {
            inputElements = this.props.field.values.map((elementValue, index) => {
                let element = this._renderElement(elementValue || "", index);

                if (index > 0 && this.props.editable) {
                    element = <div key={`${index}.${this.props.field.id}rm`}>{element}</div>;
                }
                return element;
            });
        } else {
            inputElements = [this._renderElement("", 0)];
        }

        let addButton = null;
        if (
            this.props.definition.multiple === true &&
            this.props.editable &&
            !this.hasMaxValues()
        ) {
            addButton = (
                <div className="addButton" onClick={this.addField}>
                    &#xf067;
                </div>
            );
        }
        if (this.props.deleteButton) {
            addButton = (
                <span className="removeButton" onClick={this.props.deleteButton}>
                    &#xf068;
                </span>
            );
        }

        let includeCheckbox = null;
        if (this.props.includeCheckbox === true) {
            includeCheckbox = (
                <td>
                    <input
                        type="checkbox"
                        checked={this.state.includeCheckbox}
                        value={this.state.includeCheckbox}
                        onChange={this.toggleInclude}
                    />
                </td>
            );
        }

        let descriptionButton = null;
        if (this.props.definition.description || this.props.hasExternalValue) {
            descriptionButton = (
                <span
                    className="helpButton"
                    title={this.props.definition.description}
                    onClick={this.toggleDescription}
                />
            );
        }

        let descriptionCell = null;
        if (this.state.showDescription) {
            const descriptionText = [this.props.definition.description];
            if (this.props.hasExternalValue) {
                descriptionText.push(Language.get("nc_value_suggested_in_preferences"));
                if (this.props.field.originalValues && this.props.field.originalValues.length > 0) {
                    descriptionText.push(
                        `Current value: ${this.props.field.originalValues.join(",")}.`
                    );
                }
            }
            descriptionCell = (
                <tr>
                    <th />
                    <td>
                        <div>{descriptionText.join(" ")} </div>
                    </td>
                    <td />
                </tr>
            );
        }

        const isMandatory = this.props.definition.mandatory === true;

        const classNames = [];
        classNames.push(isMandatory ? "requiredField" : "normalField");

        if (this.isComment() && !this.props.isCompact) {
            return (
                <tbody>
                    <tr key={this.props.field.id} className={classNames.join(" ")}>
                        <th
                            colSpan="2"
                            className="fieldLabel"
                            style={{ position: "relative" }}
                            title={this.props.definition.description}
                        >
                            {this.props.definition.name}
                            {isMandatory ? "*" : ""}
                            {inputElements}
                            {this.props.hasExternalValue ? (
                                <button
                                    className="clearExternalValue"
                                    onClick={this.clearExternalValue}
                                />
                            ) : null}
                        </th>
                        {includeCheckbox}
                        <td
                            className={
                                this.props.hasExternalValue
                                    ? "fieldLabel externalValue"
                                    : "fieldLabel"
                            }
                        >
                            {addButton}
                            {descriptionButton}
                        </td>
                    </tr>
                    {descriptionCell}
                </tbody>
            );
        }

        return (
            <tbody>
                <tr key={this.props.field.id} className={classNames.join(" ")}>
                    <th className="fieldLabel" title={this.props.definition.description}>
                        {this.props.definition.name}
                        {isMandatory ? "*" : ""}
                    </th>
                    {includeCheckbox}
                    <td width="100%" style={{ position: "relative" }}>
                        {inputElements}
                        {this.props.hasExternalValue ? (
                            <button
                                className="clearExternalValue"
                                onClick={this.clearExternalValue}
                            />
                        ) : null}
                    </td>
                    <td
                        title={
                            this.props.hasExternalValue
                                ? Language.get("nc_value_suggested_in_preferences")
                                : null
                        }
                        className={
                            this.props.hasExternalValue ? "fieldLabel externalValue" : "fieldLabel"
                        }
                    >
                        {addButton}
                        {descriptionButton}
                    </td>
                </tr>
                {descriptionCell}
            </tbody>
        );
    }

    _renderElement = (value, index) => {
        const key = `${index}.${this.props.field.id}`;

        return (
            <FieldInput
                key={key}
                isCompact={this.props.isCompact}
                isFirstField={this.props.isFirstField}
                value={value}
                field={this.props.field}
                definition={this.props.definition}
                editable={this.props.editable}
                onChange={this.setFieldValue.bind(this, index)}
                onDelete={this.removeField.bind(this, index)}
                indeterminate={this.props.indeterminate}
            />
        );
    };
}

export default FieldTableRow;
