import React from "react";
import API from "../lib/TimeEditAPI";
import _ from "underscore";
import Language from "../lib/Language";

class ModalDialog extends React.Component {
    state = {
        neverShow: false,
    };

    toggleNeverShow = () => {
        this.setState({ neverShow: !this.state.neverShow });
    };

    onClose = (remember = true, value = true) => {
        if (this.state.neverShow && remember) {
            this.props.onRemember();
            API.setPreferences(`dismissedModalDialogs.${this.props.displayKey}`, [value], _.noop);
        }
        this.props.onClose();
    };

    getOkButton = () => (
        <button
            className={this.props.isModernStyle === true ? "modernButton" : "save xWide"}
            onClick={this.onClose}
        >
            {Language.get("nc_dialog_done")}
        </button>
    );

    useButtonCallback =
        (cb = _.noop, remember = true, value = true) =>
        () => {
            cb();
            this.onClose(remember, value);
        };

    copyToClipboard = () => {
        window.getSelection().selectAllChildren(this.refs.contentArea);
        document.execCommand("copy");
    };

    render() {
        let neverShowAgainButton = null;
        if (this.props.displayKey) {
            neverShowAgainButton = (
                <div className="neverShowAgain">
                    <input type="checkbox" onChange={this.toggleNeverShow} />
                    {Language.get("nc_modal_do_not_show_again")}
                </div>
            );
        }

        let btn = this.getOkButton();
        if (this.props.buttons && this.props.buttons !== "NO_BUTTONS") {
            btn = this.props.buttons.map(this._renderButtons);
        }

        let title = null;
        if (this.props.title) {
            title = <h1 draggable="true">{this.props.title}</h1>;
        }

        const copyButton = null;
        /* this.props.hasCopyButton === true ? (
                <button className="dialogCopyButton" onClick={this.copyToClipboard}>
                    {Language.get("cal_res_below_copy")}
                </button>
            ) : null*/

        let style = this.props.useContentSize
            ? "contentModalDialog modal draggableDialog"
            : "modalDialog modal draggableDialog";

        if (this.props.isModernStyle === true) {
            style = `${style} modernStyle`;
        }
        return (
            <div className={style} style={this.props.style} onDragStart={this.props.onDragStart}>
                {title}
                <div className="content">
                    <span ref="contentArea">{this.props.children}</span>
                    {copyButton}
                </div>
                {this.props.buttons === "NO_BUTTONS" ? null : (
                    <div className="buttons btnGroup horizontal">
                        {neverShowAgainButton} {btn}
                    </div>
                )}
            </div>
        );
    }

    _renderButtons = (button) => {
        const className = this.props.isModernStyle === true ? "modernButton" : "save xWide";
        if (button.cb || button.remember !== undefined) {
            return (
                <button
                    className={className}
                    key={`btn_${button.title}`}
                    onClick={this.useButtonCallback(button.cb, button.remember, button.value)}
                >
                    {" "}
                    {button.title}{" "}
                </button>
            );
        }
        return (
            <button className={className} key={`btn_${button.title}`} onClick={this.onClose}>
                {" "}
                {button.title}{" "}
            </button>
        );
    };
}

export default ModalDialog;
