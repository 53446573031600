const adaptReservationData = (reservationData, callback) => {
    //console.log(reservationData);
    callback(reservationData);
};

const coreAPI = (props, currentUser, setToolbar) => ({
    setToolbarContent: (toolbar) => {
        setToolbar(toolbar);
    },

    setCreateExamRequestFunction: (callback) => {
        this._reservationCallback = callback;
    },

    createExamRequest: (reservationData) => {
        if (this._reservationCallback) {
            adaptReservationData(reservationData, (adaptedData) => {
                this._reservationCallback(adaptedData);
            });
        }
    },
});

export default coreAPI;
