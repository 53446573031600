import React from "react";
import {
    MillenniumDate,
    MillenniumWeek,
    MillenniumWeekday,
    SimpleDateFormat,
} from "@timeedit/millennium-time";
import Language from "../lib/Language";
import Mousetrap from "@timeedit/mousetrap";
import _ from "underscore";
import PropTypes from "prop-types";
import { TimeConstants as TC } from "../lib/TimeConstants";

const LAST_MONTH = 11;

// eslint-disable-next-line prefer-const
let styles;

class DateSelect extends React.Component {
    static defaultProps = {
        allowDate: true,
        allowWeek: false,
        allowWeekday: false,
        allowMonth: false,
        values: [],
        startDate: null,
        endDate: null,
        highlightDates: [],
        showTodayButton: true,
    };

    static contextTypes = {
        customWeekNames: PropTypes.array,
    };

    constructor(props) {
        super(props);
        const now = this.props.now || MillenniumDate.today();

        this.state = {
            year: now.getYear(),
            month: now.getMonth(),
            lastToggledDate: null,
            highlightDates: [],
        };
    }

    componentDidMount() {
        if (this.props.onClose) {
            this._prevEsc = Mousetrap.unbind("esc", true);
            Mousetrap.bind("esc", this.props.onClose);
        }

        if (this.props.now) {
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({
                year: this.props.now.getYear(),
                month: this.props.now.getMonth(),
                highlightDates: this.props.highlightDates
                    ? this.props.highlightDates.map((date) => date.getDayNumber())
                    : [],
            });
        }
    }

    componentWillUnmount() {
        if (this._prevEsc) {
            Mousetrap.bind("esc", this._prevEsc[0]);
        }
    }

    getWeekdays = () => MillenniumWeekday.getLocalizedWeekdayList(Language.firstDayOfWeek);

    isTypeAllowed = (tp) => {
        const type = tp.substring(0, 1).toUpperCase() + tp.substring(1).toLowerCase();
        if (this.props.hasOwnProperty(`allow${type}`)) {
            return this.props[`allow${type}`];
        }

        return true;
    };

    toggleSelection = (dts, type, event) => {
        if (!this.isTypeAllowed(type)) {
            return;
        }

        let lastToggledDate = null;
        let dates = Array.isArray(dts) ? dts : [dts];

        if (this.state.lastToggledDate && dates.length === 1 && event.shiftKey === true) {
            // eslint-disable-next-line no-param-reassign
            dates = _.range(0, dates[0].diff(this.state.lastToggledDate, "days") + 1).map(function (
                n
            ) {
                return this.state.lastToggledDate.addDays(n);
            },
            this);
        }

        const deselectDates = [];
        let selection = _.filter(this.props.values, (selectedDate) =>
            _.every(dates, (date) => {
                if (date.isSameDayAs(selectedDate)) {
                    deselectDates.push(date);
                    return false;
                }
                return true;
            })
        );

        if (deselectDates.length < dates.length) {
            selection = selection.concat(dates);
        }

        if (
            dates.length === 1 &&
            selection.length === this.props.values.length + 1 &&
            event.shiftKey === false
        ) {
            lastToggledDate = dates[0];
        }

        this.setState({
            lastToggledDate,
        });
        this.props.onChange(selection);
    };

    toggleCurrentMonth = (event) => {
        const lastDayOfMonth = MillenniumDate.lastDateOfMonth(
            this.state.year,
            this.state.month + 1
        );
        this.toggleSelection(
            _.range(0, lastDayOfMonth.getDate()).map(function (index) {
                return MillenniumDate.create(this.state.year, this.state.month + 1, index + 1);
            }, this),
            "month",
            event
        );
    };

    stepMonth = (inc) => {
        const increase = inc ? true : false;
        let year = this.state.year;
        let month = this.state.month + (increase ? 1 : -1);
        if (month < 0) {
            year = year - 1;
            month = LAST_MONTH;
        } else if (month > LAST_MONTH) {
            year = year + 1;
            month = 0;
        }

        this.setState({
            year,
            month,
        });
    };

    goToToday = () => {
        const today = new MillenniumDate.today();
        this.props.onChange(today);
    };

    isTodayInRange = () => {
        const today = new MillenniumDate.today().getDayNumber();
        if (
            today >= this.props.startDate.getDayNumber() &&
            today <= this.props.endDate.getDayNumber()
        ) {
            return false;
        }
        return true;
    };

    onYearChange = (event) => {
        const year = parseInt(event.target.value, 10);
        let month = this.state.month;

        const firstDateOfMonth = MillenniumDate.lastDateOfMonth(year, this.state.month + 1);
        const lastDateOfMonth = MillenniumDate.create(year, this.state.month + 1, 1);

        if (firstDateOfMonth.isAfter(this.props.endDate)) {
            month = this.props.endDate.getMonth();
        }
        if (lastDateOfMonth.isBefore(this.props.startDate)) {
            month = this.props.startDate.getMonth();
        }

        this.setState({ month, year });
    };

    render() {
        const firstDayOfMonth = MillenniumDate.create(this.state.year, this.state.month + 1, 1);
        const week = new MillenniumWeek(
            firstDayOfMonth,
            Language.firstDayOfWeek,
            Language.daysInFirstWeek
        );
        const firstDateInWeek = week.getStartOfWeek().getMillenniumDate();
        // eslint-disable-next-line no-magic-numbers
        const weekStarts = _.range(0, 6).map((index) => firstDateInWeek.addWeeks(index));

        // Creating the date to format for headline mid-month since the first days of January often belong to a week starting the previous year
        const showBack = week.getStartDate().isAfter(this.props.startDate);
        // eslint-disable-next-line no-magic-numbers
        const showForward = weekStarts[5].addDays(6).isBefore(this.props.endDate);
        let todayButton = null;
        if (this.props.showTodayButton) {
            todayButton = (
                <tr>
                    <th colSpan="8">
                        <button
                            className={"default"}
                            disabled={this.isTodayInRange()}
                            onClick={this.goToToday}
                        >
                            {Language.get("nc_calendar_go_to_today")}
                        </button>
                    </th>
                </tr>
            );
        }
        return (
            <table className={"dateSelect"} style={styles.noTextSelect}>
                <tbody>
                    <tr>
                        <th
                            colSpan="8"
                            onClick={this.toggleCurrentMonth}
                            style={{ textAlign: "center" }}
                        >
                            {showBack ? (
                                <span
                                    style={{
                                        float: "left",
                                        paddingLeft: "3px",
                                        fontFamily: "FontAwesome",
                                        cursor: "pointer",
                                    }}
                                    onClick={this.stepMonth.bind(this, false)}
                                >
                                    &#xf0d9;
                                </span>
                            ) : null}
                            {showForward ? (
                                <span
                                    style={{
                                        float: "right",
                                        paddingRight: "3px",
                                        fontFamily: "FontAwesome",
                                        cursor: "pointer",
                                    }}
                                    onClick={this.stepMonth.bind(this, true)}
                                >
                                    &#xf0da;
                                </span>
                            ) : null}
                            {MillenniumDate.create(this.state.year, this.state.month + 1, 1).format(
                                "MMMM"
                            )}
                            {this._renderYearSelect()}
                        </th>
                    </tr>

                    <tr>
                        <td>&nbsp;</td>
                        {this.getWeekdays().map(function (weekday, index) {
                            const weekdayDates = weekStarts.map((weekStart) =>
                                weekStart.addDays(index)
                            );
                            return (
                                <th
                                    key={weekday.absoluteWeekdayNumber}
                                    style={{ textAlign: "center" }}
                                    onClick={this.toggleSelection.bind(
                                        this,
                                        weekdayDates,
                                        "weekday"
                                    )}
                                >
                                    {SimpleDateFormat.format(weekday, "EE")}
                                </th>
                            );
                        }, this)}
                    </tr>

                    {weekStarts.map(this._renderWeekRow)}
                    {todayButton}
                </tbody>
            </table>
        );
    }

    _renderWeekRow = (start) => {
        const dates = _.range(0, TC.DAYS_PER_WEEK).map((index) => start.addDays(index));
        let name = null;
        const customName = Language.getCustomWeek(start, this.context.customWeekNames);
        if (customName) {
            name = customName.getShortestName();
        }
        return (
            <tr key={start.getWeek(true, Language.firstDayOfWeek, Language.daysInFirstWeek)}>
                <th onClick={this.toggleSelection.bind(this, dates, "week")}>
                    {Language.usesWeekNumbers
                        ? name ||
                          start.getWeek(false, Language.firstDayOfWeek, Language.daysInFirstWeek)
                        : ""}
                </th>
                {dates.map(this._renderDateCell)}
            </tr>
        );
    };

    _renderDateCell = (date) => {
        const isDateSelected = _.some(this.props.values, (selectedDate) =>
            date.isSameDayAs(selectedDate)
        );
        let style = _.extend({}, styles.cell);
        if (date.getMonth() !== this.state.month) {
            style = _.extend(style, styles.inactive);
        }
        let isDisabled = false;
        if (this.props.startDate && date.isBefore(this.props.startDate)) {
            isDisabled = true;
        }
        if (this.props.endDate && date.isAfter(this.props.endDate)) {
            isDisabled = true;
        }
        if (this.props.hideWeekends && date.isWeekend()) {
            isDisabled = true;
        }
        if (isDisabled) {
            style = _.extend(style, styles.disabled);
        }
        if (isDateSelected) {
            style = _.extend(style, styles.selected);
        }
        if (this.state.highlightDates.indexOf(date.getDayNumber()) !== -1) {
            style = _.extend(style, styles.highlighted);
        }

        return (
            <td
                style={style}
                key={date.getDayNumber()}
                onClick={isDisabled ? _.noop : this.toggleSelection.bind(this, date, "date")}
            >
                {date.getDate()}
            </td>
        );
    };

    _renderYearSelect = () => {
        const years = _.range(this.props.startDate.getYear(), this.props.endDate.getYear() + 1);
        const DAY = 10;
        if (years.length === 1) {
            return (
                <span style={{ marginLeft: "2px", lineHeight: "18px" }}>
                    {MillenniumDate.create(this.state.year, 1, DAY).format("yyyy")}
                </span>
            );
        }

        return (
            <select
                value={this.state.year}
                onChange={this.onYearChange}
                style={{ marginLeft: "2px", lineHeight: "18px" }}
            >
                {years.map((year) => (
                    <option key={year} value={year}>
                        {year}
                    </option>
                ))}
            </select>
        );
    };
}

styles = {
    table: {
        tableLayout: "fixed",
    },
    cell: {
        textAlign: "center",
        width: "12.5%",
        cursor: "pointer",
    },
    highlighted: {
        fontWeight: "bold",
        backgroundColor: "#ccc",
        color: "black",
    },
    inactive: {
        color: "#ccc",
    },
    disabled: {
        color: "#eee",
        textDecoration: "italic",
        cursor: "default",
    },
    selected: {
        background: "rgb(179, 224, 252)",
    },
    noTextSelect: {
        WebkitTouchCallout: "none",
        WebkitUserSelect: "none",
        KhtmlUserSelect: "none",
        MozUserSelect: "none",
        msUserSelect: "none",
        userSelect: "none",
    },
};

export default DateSelect;
