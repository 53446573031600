import Language from "../lib/Language";

const MEMBERS = {
    EXCLUDE: 0,
    INCLUDE: 1,
    OBSTACLES: 2,
    MEMBERS_ONLY: 3,
};

const RESERVATION_KIND = {
    UNDEFINED: 0,
    PHYSICAL: 1,
    ABSTRACT: 2,
    DOUBLE: 3,
    OPTIONAL: 4,
    OPTIONAL_DOUBLE: 5,
};

const ReservationConstants = {
    ALL: "allReservations",
    MINE: "myReservations",
    MY_CREATED: "myCreated",

    STATUS: {
        UNKNOWN: 0,
        RESERVATION: 10,
        AVAILABILITY: 11,
        INFO: 12,
        COMPLETE: 20,
        INCOMPLETE: 21,
        CONFIRMED: 30,
        PRELIMINARY: 31,
        PLANNED: 32,
        REQUESTED: 33,
        REJECTED: 34,
        TIME: 40,
        WAITING_LIST: 41,
    },

    MEMBERS,

    RESERVATION_KIND,

    PERMISSION: {
        READ: 0,
        MODIFY: 1,
    },

    VARIANT: {
        RESERVATION: 0,
        CANCELLED: 1,
    },

    TYPE: {
        RESERVATION: 0,
        AVAILABILITY: 1,
        INFO: 2,
    },

    OBJECT_MASS_CHANGE: {
        ADD: 0,
        REMOVE: 1,
        REPLACE: 2,
        MOVE: 3,
        ASSIGN: 4,
        COPY_AVAILABILITY: 5,
    },

    ERROR_AVAILABILITY_OVERLAP_POSSIBLE: -9078,

    toMemberLabel: (memberValue) => {
        // Intentionally not localized, used only for logging
        if (memberValue === MEMBERS.EXCLUDE) {
            return "Exclude";
        }
        if (memberValue === MEMBERS.INCLUDE) {
            return "Include";
        }
        if (memberValue === MEMBERS.OBSTACLES) {
            return "Obstacles";
        }
        if (memberValue === MEMBERS.MEMBERS_ONLY) {
            return "Members only";
        }
        return "";
    },

    toKindLabel: (kindValue) => {
        if (kindValue === RESERVATION_KIND.PHYSICAL) {
            return Language.get("cal_reservation_status_physical");
        }
        if (kindValue === RESERVATION_KIND.ABSTRACT) {
            return Language.get("cal_reservation_status_abstract");
        }
        if (kindValue === RESERVATION_KIND.DOUBLE) {
            return `${Language.get("cal_reservation_status_physical")}*`;
        }
        if (kindValue === RESERVATION_KIND.OPTIONAL) {
            return `${Language.get("cal_reservation_status_optional")}`;
        }
        if (kindValue === RESERVATION_KIND.OPTIONAL_DOUBLE) {
            return `${Language.get("cal_reservation_status_optional")}*`;
        }
        return "";
    },

    toShortKindLabel: (kindValue) => {
        if (kindValue === RESERVATION_KIND.PHYSICAL) {
            return Language.get("cal_reservation_status_physical_short");
        }
        if (kindValue === RESERVATION_KIND.ABSTRACT) {
            return Language.get("cal_reservation_status_abstract_short");
        }
        if (kindValue === RESERVATION_KIND.DOUBLE) {
            return `${Language.get("cal_reservation_status_physical_short")}*`;
        }
        if (kindValue === RESERVATION_KIND.OPTIONAL) {
            return `${Language.get("cal_reservation_status_optional_short")}`;
        }
        if (kindValue === RESERVATION_KIND.OPTIONAL_DOUBLE) {
            return `${Language.get("cal_reservation_status_optional_short")}*`;
        }
        return "";
    },
};

export default ReservationConstants;
