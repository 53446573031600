import React from "react";
import CSRF from "../../lib/CSRF";
import _ from "underscore";

class ForgotPassword extends React.Component {
    render() {
        const STRINGS = this.props.strings;

        return (
            <div>
                <h2>{STRINGS.login_new_password_required}</h2>

                <form method="post">
                    <ul>
                        <li>
                            <input
                                className="password firstPassword"
                                name="password"
                                placeholder={STRINGS.login_password}
                                type="password"
                            />
                        </li>
                        <li>
                            <input
                                className="password confirmPassword"
                                name="confirmPassword"
                                placeholder={STRINGS.login_confirm_password}
                                type="password"
                            />
                        </li>
                        <li>
                            <input
                                type="hidden"
                                name="_csrf"
                                id="csrfToken"
                                value={CSRF.getToken()}
                            />
                            <input
                                type="submit"
                                id="loginButton"
                                disabled={!this.props.isAvailable}
                                value={this.props.strings.login_login}
                            />
                        </li>
                    </ul>
                </form>
            </div>
        );
    }

    _renderRules = () => (
        <ul
            className={_.classSet({ hidden: this.state.rules.length === 0 })}
            style={{ width: "100%" }}
        >
            {this.state.rules.map((rule, index) => (
                <li key={index}>{rule}</li>
            ))}
        </ul>
    );
}

export default ForgotPassword;
