import { TimeEdit } from "../lib/TimeEdit";
import PropTypes from "prop-types";
import React from "react";

class CalendarIcon extends React.Component {
    static propTypes = {
        calendar: PropTypes.object.isRequired,
        isActive: PropTypes.bool.isRequired,
        size: PropTypes.number.isRequired,
    };

    render() {
        return (
            <svg
                className="calendarIcon"
                width={this.props.size}
                height={this.props.size}
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="0"
                    y="0"
                    width="100%"
                    height="100%"
                    rx="5"
                    ry="5"
                    className="calendarIconBackground"
                />
                {this.renderCalendar()}
            </svg>
        );
    }

    renderCalendar = () => {
        const view = TimeEdit.State.get().view;
        const size = this.getSize(view.section);
        const classes = ["calendarIcon"];
        if (this.props.isActive) {
            classes.push("active");
        }

        return (
            <rect
                x={size.x}
                y={size.y}
                width={size.width}
                height={size.height}
                rx="3"
                ry="3"
                className={classes.join(" ")}
            />
        );
    };

    getSize = (section, x = 0, y = 0, width = 1.0, height = 1.0) => {
        if (section.hasChild()) {
            if (section.first._modelId !== this.props.calendar._modelId) {
                return null;
            }

            const FRAME = 4;
            const EDGE = 2;
            const sizeExcludingFrame = this.props.size - FRAME;
            return {
                x: x * sizeExcludingFrame + EDGE,
                y: y * sizeExcludingFrame + EDGE,
                width: width * sizeExcludingFrame,
                height: height * sizeExcludingFrame,
            };
        }

        if (section.hasVerticalLayout) {
            return (
                this.getSize(section.first, x, y, width, height * section.weight) ||
                this.getSize(
                    section.second,
                    x,
                    y + height * section.weight,
                    width,
                    height * (1 - section.weight)
                )
            );
        }

        return (
            this.getSize(section.first, x, y, width * section.weight, height) ||
            this.getSize(
                section.second,
                x + width * section.weight,
                y,
                width * (1 - section.weight),
                height
            )
        );
    };
}

export default CalendarIcon;
