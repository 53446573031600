import React from "react";
import Language from "../lib/Language";
import { SimpleDateFormat, MillenniumDate } from "@timeedit/millennium-time";

const getSeparator = (format) => {
    let found = "-";
    [".", "-", "/"].forEach((separator) => {
        if (format.indexOf(separator) !== -1) {
            found = separator;
        }
    });
    return found;
};

const orderDateComponents = (dateArray, format, separator) => {
    const result = [];
    const componentOrder = format.split(separator);
    componentOrder.forEach((component, index) => {
        if (component.indexOf("y") !== -1) {
            result[0] = dateArray[index];
        }
        if (component.toLowerCase().indexOf("m") !== -1) {
            result[1] = dateArray[index];
        }
        if (component.indexOf("d") !== -1) {
            result[2] = dateArray[index];
        }
    });
    return result;
};

class DateInput extends React.Component {
    state = {
        value: SimpleDateFormat.format(
            this.props.defaultValue,
            Language.getDateFormat("date_i_yyyy_mm_dd")
        ),
    };

    componentDidUpdate(prevProps) {
        if (this.props.defaultValue !== prevProps.defaultValue) {
            const value = SimpleDateFormat.format(
                this.props.defaultValue,
                Language.getDateFormat("date_i_yyyy_mm_dd")
            );
            if (value !== this.state.value) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({ value });
            }
        }
    }

    onChange = (evt) => {
        this.setState({ value: evt.target.value });
    };

    returnValue = () => {
        const format = Language.getDateFormat("date_i_yyyy_mm_dd");
        const separator = getSeparator(format);
        const dateArray = this.state.value.split(separator).map((str) => parseInt(str, 10));
        const sortedArray = orderDateComponents(dateArray, format, separator);
        try {
            const date = MillenniumDate.create.apply(null, sortedArray);
            this.props.onUpdate(date);
        } catch (error) {
            this.setState({
                value: SimpleDateFormat.format(
                    this.props.defaultValue,
                    Language.getDateFormat("date_i_yyyy_mm_dd")
                ),
            });
            this.props.onUpdate(this.props.defaultValue);
        }
    };

    render() {
        return (
            <input
                disabled={this.props.disabled || false}
                type="text"
                value={this.state.value}
                onChange={this.onChange}
                onBlur={this.returnValue}
            />
        );
    }
}

export default DateInput;
