import React from "react";
import * as LangUtils from "../../lib/LangUtils";
import CSRF from "../../lib/CSRF";
import _ from "underscore";
import SelectSignature from "./SelectSignature";

class Login extends React.Component {
    wasSuccessful = () => window.location.href.indexOf("success=true") > -1;

    render() {
        const STRINGS = this.props.strings;
        const content = this.wasSuccessful()
            ? STRINGS.nc_login_reset_success_message
            : this._renderForm();

        return (
            <div>
                {content}

                <a className="forgotPassword" href={`/${this.props.appPath}/login`}>
                    &raquo; {STRINGS.login_login}
                </a>
            </div>
        );
    }

    _renderForm = () => {
        const STRINGS = this.props.strings;

        return (
            <div>
                <form method="post">
                    <input type="hidden" name="_csrf" id="csrfToken" value={CSRF.getToken()} />
                    <input
                        type="hidden"
                        name="lang"
                        id="lang"
                        value={LangUtils.getUserLanguage()}
                    />
                    <ul>
                        <li>{this._renderDatabaseList()}</li>
                        <li>
                            <input
                                name="username"
                                id="usernameField"
                                placeholder={STRINGS.login_user}
                                autoCorrect="off"
                                autoCapitalize="none"
                                autoFocus="true"
                                type="text"
                            />
                        </li>
                        <li>
                            <input
                                name="email"
                                id="emailField"
                                placeholder={STRINGS.login_email}
                                autoCorrect="off"
                                autoCapitalize="none"
                                type="email"
                            />
                        </li>
                        <li>{this._renderAuthServerList()}</li>
                        <li>
                            <input
                                type="submit"
                                id="loginButton"
                                value={STRINGS.nc_login_reset_password}
                            />
                        </li>
                    </ul>
                </form>
            </div>
        );
    };

    _renderDatabaseList = () => {
        if (this.props.databases.length === 0) {
            return null;
        }

        const classes = {
            hidden: this.props.databases.length === 1,
        };
        return (
            <SelectSignature
                values={this.props.databases}
                name="database"
                onChange={this.props.onDatabaseSelected}
                value={this.props.selectedDatabase}
                className={_.classSet(classes)}
            />
        );
    };

    _renderAuthServerList = () => {
        if (this.props.authServers.length === 0) {
            return null;
        }

        const classes = {
            hidden: this.props.authServers.length === 1,
        };
        return (
            <SelectSignature
                values={this.props.authServers}
                name="authserver"
                className={_.classSet(classes)}
            />
        );
    };
}

export default Login;
