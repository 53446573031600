import PropTypes from "prop-types";
import React from "react";
import { MillenniumDate, MillenniumDateTime } from "@timeedit/millennium-time";
import CancellationListSettings from "./CancellationListSettings";
import Language from "../lib/Language";
import _ from "underscore";
import TemplateKind from "../models/TemplateKind";
import ReservationTypeList from "./ReservationTypeList";
import RC from "../lib/ReservationConstants";
import Log from "../lib/Log";
import { TimeEdit } from "../lib/TimeEdit";
import { Macros } from "../models/Macros";
import TimeEditAPI from "../lib/TimeEditAPI";

const MAX_CANCELLATIONS_TO_RESTORE = 5000;

const COL_IDS = {
    ID: 0,
    DATE: 1,
    TIME: 2,
    TYPES: 3,
    FIELDS: 4,
    CANCELLED: 5,
    WEEK: 8,
    WEEKDAY: 9,
    LENGTH: 10,
    STATUS: 11,
};

const getAvailableColumns = (context) => {
    const columns = [
        { name: Language.get("cal_reservation_list_column_id"), sortable: true, id: COL_IDS.ID },
        {
            name: Language.get("cal_reservation_list_column_date"),
            sortable: true,
            id: COL_IDS.DATE,
        },
        {
            name: Language.get("cal_reservation_list_column_time"),
            sortable: false,
            id: COL_IDS.TIME,
        },
        {
            name: Language.get("cal_reservation_list_column_type_all"),
            sortable: false,
            id: COL_IDS.TYPES,
        },
        {
            name: Language.get("cal_reservation_list_column_field_all"),
            sortable: false,
            id: COL_IDS.FIELDS,
        },
        {
            name: Language.get("cal_reservation_list_column_cancelled"),
            sortable: true,
            id: COL_IDS.CANCELLED,
        },
        {
            name: Language.get("cal_reservation_list_column_week"),
            sortable: false,
            id: COL_IDS.WEEK,
        },
        {
            name: Language.get("cal_reservation_list_column_week_day"),
            sortable: false,
            id: COL_IDS.WEEKDAY,
        },
        {
            name: Language.get("cal_reservation_list_column_length"),
            sortable: false,
            id: COL_IDS.LENGTH,
        },
        {
            name: Language.get("cal_reservation_list_column_status"),
            sortable: false,
            id: COL_IDS.STATUS,
        },
    ];
    if (context.useNewReservationGroups) {
        columns.push({
            name: Language.get("nc_reservation_group_list_title"),
            sortable: false,
            id: 12,
        });
    }
    if (true) {
        // TODO Check if timezone column should be active
        columns.push({
            name: Language.get("cal_res_side_user_time_zone"),
            sortable: false,
            id: 15,
        });
    }
    return columns;
};

class CancellationList extends React.Component {
    static contextTypes = {
        user: PropTypes.object,
        update: PropTypes.func,
        fireEvent: PropTypes.func,
        presentModal: PropTypes.func,
        useNewReservationGroups: PropTypes.bool,
    };

    constructor(props, context) {
        super(props, context);
        const timeLimit = this.getTimeLimits();

        this.state = {
            searchOptions: {
                templateKind: TemplateKind.RESERVATION,
                searchString: "",
                beginTime: timeLimit.begin,
                endTime: timeLimit.end,
                name: "",
                allReservations: false,
                reservationStatus: [
                    RC.STATUS.CONFIRMED,
                    RC.STATUS.PRELIMINARY,
                    RC.STATUS.PLANNED,
                    RC.STATUS.REQUESTED,
                    RC.STATUS.REJECTED,
                ],
                includeMembers: RC.MEMBERS.EXCLUDE,
                useTemplateGroup: false,
                reservationVariant: RC.VARIANT.CANCELLED,
                complete: true,
                incomplete: true,
                permission: RC.PERMISSION.READ,
            },
            canDeleteCancellations: false,
        };
    }

    componentDidMount() {
        TimeEditAPI.okToDeleteReservationsCancelled([], (okToDelete) => {
            this.setState({ canDeleteCancellations: okToDelete });
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data.limits !== this.props.data.limits) {
            const timeLimits = this.getTimeLimits();
            if (
                timeLimits.begin !== this.state.searchOptions.beginTime ||
                timeLimits.endTime !== this.state.searchOptions.endTime
            ) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({
                    // eslint-disable-line react/no-did-update-set-state
                    searchOptions: _.extend({}, this.state.searchOptions, {
                        beginTime: timeLimits.begin,
                        endTime: timeLimits.end,
                    }),
                });
            }
        }
    }

    getTimeLimits = () => {
        try {
            return {
                begin: MillenniumDateTime.fromDate(this.props.data.limits.getStartDate()).getMts(),
                end: MillenniumDateTime.fromDate(this.props.data.limits.getEndDate()).getMts(),
            };
        } catch (error) {
            return {
                begin: MillenniumDateTime.fromDate(MillenniumDate.today()).getMts(),
                end: 0,
            };
        }
    };

    onSearchSettingsChange = (settings, cb) => {
        let newSettings = _.extend(
            {},
            _.omit(settings, (value) => value === undefined)
        );
        // If user is not an admin, enforce the default value for hidden search property
        if (!this.context.user.isAdmin) {
            newSettings = _.extend(newSettings, {
                includeMembers: RC.MEMBERS.EXCLUDE,
            });
        }

        // Ensure cancellations are shown no matter their status
        newSettings = _.extend(newSettings, {
            reservationStatus: [
                RC.STATUS.CONFIRMED,
                RC.STATUS.PRELIMINARY,
                RC.STATUS.PLANNED,
                RC.STATUS.REQUESTED,
                RC.STATUS.REJECTED,
            ],
            reservationVariant: RC.VARIANT.CANCELLED,
        });

        this.setState({ searchOptions: _.extend({}, this.state.searchOptions, newSettings) }, cb);
    };

    editFields = (selectedReservationIds) => {
        const user = this.context.user;
        if (user.useInfoPopover) {
            this.context.presentModal(
                <div>{<p>{Language.get("nc_reservationinfo_moved")}</p>}</div>,
                null,
                Language.get("nc_reservationinfo_moved_title")
            );
            this.context.update(user, user.immutableSet({ useInfoPopover: !user.useInfoPopover }));
            TimeEditAPI.setPreferences("useInfoPopover", [!user.useInfoPopover], _.noop);
            this.props.onInfoOpen(selectedReservationIds, true, true);
        } else {
            this.props.onInfoOpen(selectedReservationIds, true, true);
        }
    };

    getReservationMenuItems = (selectedReservationIds, getDataAtIndex, refresh) => {
        const isAllowedToRestoreCancellations =
            this.context.user.isAdmin || !this.state.searchOptions.allReservations;
        if (!isAllowedToRestoreCancellations) {
            return [];
        }

        let label = Language.get("nc_restore_cancellation");
        if (selectedReservationIds.length > 1) {
            label = Language.get("nc_restore_cancellations", selectedReservationIds.length);
        }
        const items: any[] = [];
        const deleteAction = () => {
            const ids = [].concat(selectedReservationIds);
            TimeEditAPI.okToDeleteReservationsCancelled(ids, (okResult) => {
                if (_.every(okResult, (res) => res === true)) {
                    // eslint-disable-next-line no-unused-vars
                    TimeEditAPI.deleteReservationsCancelled(ids, (result) => {
                        refresh();
                    });
                } else {
                    Log.warning(Language.get("cal_res_below_delete_cancellations_failed"));
                }
            });
        };
        const restoreAction = (allowDoubleReservation) => {
            const ids = [].concat(selectedReservationIds);
            TimeEditAPI.restoreReservations(ids, allowDoubleReservation, (response) => {
                const errors = response.parameters[0]
                    .map((status, i) => _.extend(status, { originalId: ids[i] }))
                    .filter((status) => Boolean(status.details));
                const successes = response.parameters[0].filter(
                    (status) => !Boolean(status.details)
                );

                if (errors.length === ids.length) {
                    Log.warning(errors[0].details);
                    return;
                }

                this.context.fireEvent(
                    `reservationList${this.props.id}`,
                    Macros.Event.RESERVATION_RESTORED,
                    successes[0].reference
                );
                refresh();

                let successMessage = Language.get("nc_restore_cancellation_complete");
                if (successes.length !== 1) {
                    successMessage = Language.get(
                        "nc_restore_cancellations_complete",
                        successes.length
                    );
                }

                if (successes.length === ids.length) {
                    Log.info(successMessage);
                    return;
                }

                const modalContent = (
                    <div>
                        <p>{Language.get("nc_restore_cancellations_complete", successes.length)}</p>
                        <div>
                            {Language.get(
                                "nc_restore_cancellations_list_of_errors_title",
                                errors.length
                            )}
                            :
                            <ul>
                                {errors.map((error) => (
                                    <li>
                                        <strong>{error.originalId}</strong>: {error.details}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                );
                this.context.presentModal(modalContent);
            });
        };
        items.push(
            {
                label: Language.get("nc_cal_func_res_edit_fields"),
                isDisabled:
                    selectedReservationIds.length > MAX_CANCELLATIONS_TO_RESTORE ||
                    selectedReservationIds.length === 0,
                action: () => {
                    this.editFields(selectedReservationIds);
                },
                classNames: ["icon", "editFields"],
                shortcut: TimeEdit.presentShortcut("mod+e"),
            },
            {
                label,
                isDisabled:
                    selectedReservationIds.length === 0 ||
                    selectedReservationIds.length > MAX_CANCELLATIONS_TO_RESTORE,
                action: () => {
                    restoreAction(false);
                },
                submenu: [
                    {
                        label,
                        isDisabled:
                            selectedReservationIds.length === 0 ||
                            selectedReservationIds.length > MAX_CANCELLATIONS_TO_RESTORE,
                        action: () => {
                            restoreAction(false);
                        },
                    },
                    {
                        label: `${label} (${Language.get(
                            "cal_selected_allow_double_res"
                        ).toLowerCase()})`,
                        isDisabled:
                            selectedReservationIds.length === 0 ||
                            selectedReservationIds.length > MAX_CANCELLATIONS_TO_RESTORE,
                        action: () => {
                            restoreAction(true);
                        },
                    },
                ],
            }
        );
        if (this.state.canDeleteCancellations) {
            items.push({
                label: `${Language.get("cal_res_below_delete_cancellations_title")} (${
                    selectedReservationIds.length
                })`,
                submenu: [
                    {
                        label: `${Language.get("cal_res_below_delete_cancellations_title")} (${
                            selectedReservationIds.length
                        })`,
                        action: () => {
                            deleteAction();
                        },
                    },
                ],
            });
        }
        return items;
    };

    getSearchHelp() {
        return (
            <div className="reservationSearchHelp">
                <table>
                    <tbody>
                        <tr>
                            <td>#</td>
                            <td>{Language.get("nc_search_help_reservation_id")}</td>
                        </tr>
                        <tr>
                            <td>##</td>
                            <td>{Language.get("nc_search_help_object_id")}</td>
                        </tr>
                        <tr>
                            <td>@</td>
                            <td>{Language.get("nc_search_help_cancelled_by")}</td>
                        </tr>
                        <tr>
                            <td>d:</td>
                            <td>{Language.get("nc_search_help_cancellation_date")}</td>
                        </tr>
                        <tr>
                            <td>t:</td>
                            <td>{Language.get("nc_search_help_has_objects_of_type")}</td>
                        </tr>
                        <tr>
                            <td>p:</td>
                            <td>{Language.get("nc_search_help_has_placeholder_for_type")}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    getSettingsComponent = (
        saveDefaultSettings,
        saveSettings,
        getSavedSettings,
        removeSettings
    ) => {
        const opts = this.state.searchOptions;

        return (
            <CancellationListSettings
                onChange={this.onSearchSettingsChange}
                isAdmin={this.context.user.isAdmin}
                allReservations={opts.allReservations}
                reservationStatus={opts.reservationStatus}
                includeMembers={opts.includeMembers}
                saveDefaultSettings={saveDefaultSettings}
                saveSettings={saveSettings}
                removeSettings={removeSettings}
                getSavedSettings={getSavedSettings}
                reservationVariant={opts.reservationVariant}
                templateKind={opts.templateKind}
                complete={opts.complete}
                incomplete={opts.incomplete}
                useTemplateGroup={opts.useTemplateGroup}
            />
        );
    };

    render() {
        const classes = {
            cancellationList: true,
        };

        return (
            <ReservationTypeList
                {...this.props}
                onSearchSettingsChange={this.onSearchSettingsChange}
                searchOptions={this.state.searchOptions}
                defaultStatus={RC.STATUS.TIME}
                columns={getAvailableColumns(this.context)}
                defaultSelectedColumns={[
                    COL_IDS.DATE,
                    COL_IDS.TIME,
                    COL_IDS.TYPES,
                    COL_IDS.CANCELLED,
                ]}
                getDataMenuItems={this.getReservationMenuItems}
                getSettingsComponent={this.getSettingsComponent}
                title={Language.get("nc_cancellation_list_title")}
                classes={classes}
                allowMultiSelection
                supportsEmail
                addTypeColumns={TemplateKind.equals(
                    TemplateKind.RESERVATION,
                    this.state.searchOptions.templateKind
                )}
                getSearchHelp={this.getSearchHelp}
            />
        );
    }
}

export default CancellationList;
