import API from "../lib/TimeEditAPI";
import React from "react";
import Accordion from "./Accordion";
import FieldTableRow from "./FieldTableRow";
import Language from "../lib/Language";
import _ from "underscore";

class FieldDialog extends React.Component {
    state = {
        reservationFields: [],
        fieldDefs: [],
        fieldsValid: false,
    };

    componentDidMount() {
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({ reservationFields: this.props.reservationFields });
        this.loadFieldDefs(this.props);
    }

    componentDidUpdate(prevProps) {
        if (_.isEqual(this.props.reservationFields, prevProps.reservationFields)) {
            return;
        }

        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ reservationFields: this.props.reservationFields });
        this.loadFieldDefs(this.props);
    }

    loadFieldDefs = (props) => {
        const ids = props.reservationFields.map((field) => field.id);
        API.getFieldDefs(ids, (defs) => this.setState({ fieldDefs: defs }));
    };

    getFieldDef = (fieldId) =>
        _.find(this.state.fieldDefs, (field) => field.id === fieldId) || null;

    validateFields = (fields) =>
        _.every(
            fields,
            (field) => this.getFieldDef(field.id).mandatory !== true || this.isValidField(field)
        );

    isValidField = (field) => field.values && field.values.length !== 0 && Boolean(field.values[0]);

    addField = (field) => {
        this.updateField(field, (values) => {
            if (values.length === 0) {
                // From the user's perspective, zero values are displayed as one empty value. Thus we need to go from 0 -> 2.
                values.push("");
            }
            values.push("");
        });
    };

    removeField = (field, index) => {
        this.updateField(field, (values) => values.splice(index, 1));
    };

    setFieldValue = (field, event, newValue, valueIndex) => {
        // eslint-disable-next-line no-param-reassign
        this.updateField(field, (values) => (values[valueIndex] = newValue));
    };

    updateField = (field, updateFn) => {
        const fields = [].concat(this.state.reservationFields);
        for (let i = 0; i < fields.length; i++) {
            if (fields[i].id === field.id) {
                const values = [].concat(fields[i].values);
                updateFn(values);
                fields[i] = _.extend({}, fields[i], { values });
            }
        }

        this.setState({
            reservationFields: fields,
            fieldsValid: this.validateFields(fields),
        });
        if (this.props.onUpdate) {
            this.props.onUpdate(fields);
        }
    };

    onFinished = (e) => {
        this.props.onFinished(this.state.reservationFields);
        e.preventDefault();
    };

    onCancel = (e) => {
        this.props.onCancel(e);
        e.preventDefault();
    };

    render() {
        if (!this.state || !this.state.reservationFields) {
            return null;
        }

        if (this.props.wrapper === false) {
            return this._renderFieldTable();
        }

        const sections = [];
        if (this.state.reservationFields) {
            sections.push({
                title: Language.get("nc_cal_func_res_edit_fields"),
                content: this._renderFieldTable(),
            });
        }

        return (
            <form onSubmit={this.onFinished}>
                <Accordion sections={sections} />
                <button type="submit" className="save">
                    {Language.get("nc_dialog_done")}
                </button>
                <button className="cancel" onClick={this.onCancel}>
                    {Language.get("dialog_cancel")}
                </button>
            </form>
        );
    }

    _renderFieldTable = () => {
        if (!this.state.reservationFields) {
            return null;
        }

        return (
            <table>
                {this.state.reservationFields.map(function (field) {
                    const definition = this.getFieldDef(field.id);
                    if (!definition) {
                        return null;
                    }
                    return (
                        <FieldTableRow
                            key={field.id}
                            field={field}
                            definition={definition}
                            addField={this.addField.bind(this, field)}
                            removeField={this.removeField.bind(this, field)}
                            setFieldValue={this.setFieldValue.bind(this, field)}
                        />
                    );
                }, this)}
            </table>
        );
    };
}

export default FieldDialog;
