const ACTIVE = {
    INACTIVE: 0,
    ACTIVE: 1,
    ALL: 2,
};

const VIRTUAL = {
    NONVIRTUAL: 0,
    VIRTUAL: 1,
    ALL: 2,
};

const ABSTRACT = {
    NONABSTRACT: 0,
    ABSTRACT: 1,
    ALL: 2,
};

const OTHER_OBJECTS = {
    EXCLUDE: 0,
    INCLUDE: 1,
    RELATE: 2,
};

const RESERVE = {
    READ: 0,
    RESERVE: 1,
    PLAN: 2,
};

const DEFAULTS = {
    active: ACTIVE.ACTIVE,
    virtual: VIRTUAL.ALL,
    abstract: ABSTRACT.ALL,
    reserve: RESERVE.RESERVE,
};

export default {
    ACTIVE,
    VIRTUAL,
    ABSTRACT,
    OTHER_OBJECTS,
    RESERVE,
    DEFAULTS,
};
