import Language from "../lib/Language";
import IntegerInput from "./IntegerInput";

const MassMoveDurationSelect = (props) => (
    <div className="massMoveTimeSelect">
        <p className="headline">{Language.get("nc_mass_move_adjust_length_headline")}</p>
        <div>
            <div>
                <input
                    type="radio"
                    value={true}
                    checked={props.isDurationRelative}
                    onChange={props.onRelativeDurationChanged}
                />
                <span>{Language.get("nc_mass_move_length_relative")}</span>
                <br />
                <input
                    type="radio"
                    value={false}
                    checked={!props.isDurationRelative}
                    onChange={props.onRelativeDurationChanged}
                />
                <span>{Language.get("nc_mass_move_length_absolute")}</span>
            </div>
            <div>
                <span>
                    {props.isDurationRelative
                        ? Language.get("nc_mass_move_all_lengths_will_change_with_this_amount")
                        : Language.get(
                              "nc_mass_move_all_x_reservations_will_get_this_length",
                              props.numberOfReservations
                          )}
                </span>{" "}
                <br />
                {props.isDurationRelative ? null : (
                    <>
                        {Language.get("nc_summary_hours")}{" "}
                        <IntegerInput onUpdate={props.onHoursChanged} defaultValue={props.hours} />{" "}
                        <br />
                    </>
                )}
                {Language.get("nc_summary_minutes")}{" "}
                <IntegerInput onUpdate={props.onMinutesChanged} defaultValue={props.minutes} />{" "}
            </div>
        </div>
    </div>
);

export default MassMoveDurationSelect;
