import { MillenniumWeek, MillenniumWeekday, SimpleDateFormat } from "@timeedit/millennium-time";
import { PeriodHeader } from "./PeriodHeader";

import Locale from "../lib/Language";
import _ from "underscore";

const DAYS_IN_WEEK = 7;

export class WeekPeriodHeader extends PeriodHeader {
    weekdays: any[];

    constructor(visibleValues, firstVisibleValue, subheader?) {
        super(visibleValues || 1, firstVisibleValue, subheader, "WeekPeriodHeader");
        this.weekdays = [];
    }

    getKind() {
        return PeriodHeader.KIND.WEEK;
    }

    getIndexOfDate(date, onlyVisible = false) {
        const soughtDay = date.getDayNumber();
        const soughtWeek = getWeekFromDayNumber(soughtDay);
        return this.getIndexOfWeek(soughtWeek, onlyVisible);
    }

    getIndexOfWeek(soughtWeek, onlyVisible = false) {
        const values = onlyVisible ? this.getVisibleValues() : this.getValues();
        return values.findIndex((weeks) => weeks.some((week) => week.week() === soughtWeek));
    }

    getSettings(providers) {
        const settings = super.getSettings();
        const self = this;

        settings.items.push({
            id: "period",
            label: Locale.get("cal_list_weeks"),
            type: "groups",
            availableValues() {
                const startDay = self.limits.getStartDate().getDayNumber();
                return _.range(
                    startDay,
                    startDay + self.limits.dayCount + DAYS_IN_WEEK - 1,
                    DAYS_IN_WEEK
                ).map(getWeekFromDayNumber);
            },
            get() {
                return {
                    names: self.names,
                    values: self.values.map((period) => period.map((item) => item.week())),
                };
            },
            set(groups) {
                return self.immutableSet({
                    names: groups.names,
                    values: groups.values.map((period) =>
                        period.map((week) =>
                            MillenniumWeek.create(
                                week,
                                Locale.firstDayOfWeek,
                                Locale.daysInFirstWeek
                            )
                        )
                    ),
                });
            },
        });

        if (providers.weekday === true) {
            return settings;
        }

        settings.items.push({
            id: "weekdays",
            label: Locale.get("cal_list_weekdays"),
            type: "array",
            limit: DAYS_IN_WEEK,
            get: self.getWeekdays.bind(self),
            set(val) {
                return self.immutableSet({ weekdays: val });
            },
        });

        return settings;
    }

    getSimplePeriodIndex(entry, onlyVisible = false) {
        if (!this.isSimplePeriod()) {
            throw new Error("Cannot use getSimplePeriodIndex if period is not a simple period.");
        }

        const isMatch = (value) =>
            value.week(true) ===
            entry.startTimes[0].getWeek(true, Locale.firstDayOfWeek, Locale.daysInFirstWeek);
        const index = _.flatten(this.getValues()).findIndex(isMatch);
        if (!onlyVisible) {
            return index;
        }
        return index - this.firstVisibleValue;
    }

    getWeekdays() {
        const labels = Locale.getWeekdayLabels();
        const values = MillenniumWeekday.getLocalizedWeekdayList(Locale.firstDayOfWeek).map(
            (val) => ({
                value: val,
                label: labels[val.getDay()],
            })
        );

        return values.map((item) =>
            _.extend(item, {
                selected: this.weekdays.some((weekday) => weekday === item.value),
            })
        );
    }

    periodsToJSON() {
        return _.flatten(this.values.map((weeks) => weeks.map((week) => week.week())));
    }

    toJSON() {
        const json = super.toJSON();
        return _.extend(json, {
            dayProvider: true,
            kind: "periodweek",
            periods: this.values.map((weeks) => weeks.map((week) => week.week())),
            weekDays: this.weekdays.map((weekday) => weekday.getRepresentation()),
        });
    }
}

const getWeekFromDayNumber = function (day) {
    const week = new MillenniumWeek(day, Locale.firstDayOfWeek, Locale.daysInFirstWeek);
    return {
        label: SimpleDateFormat.format(
            week.getStartOfWeek(),
            Locale.getDateFormat("date_f_yyyy_ww_l")
        ),
        value: week.week(),
    };
};
