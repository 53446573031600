import _ from "underscore";

/* eslint-disable no-undef */
const setupMixpanel = (user, env) => {
    if (typeof mixpanel === "undefined") {
        // Don't let the abscense of Mixpanel stop anything
        window.mixpanel = {
            track: () => {},
        };
        return;
    }
    // Turn off Mixpanel outside of actual production
    if ((env && env !== "production") || process.env.NODE_ENV !== "production") {
        // eslint-disable-next-line no-undef
        mixpanel.disable();
        // eslint-disable-next-line no-undef
        mixpanel.track = (event, properties) => {
            // eslint-disable-next-line no-console
            console.log(`Mixpanel disabled in dev: ${event}: ${JSON.stringify(properties)}`);
        };
        return;
    }
    const db = user.database;
    const server = user.server;
    mixpanel.register({ customer_signature: server });
    const textEncode = (str) => {
        if (window.TextEncoder) {
            return new TextEncoder("utf-8").encode(str);
        }
        const utf8 = unescape(encodeURIComponent(str));
        const result = new Uint8Array(utf8.length);
        for (let i = 0; i < utf8.length; i++) {
            result[i] = utf8.charCodeAt(i);
        }
        return result;
    };
    const hexString = (buffer) => {
        const byteArray = new Uint8Array(buffer);
        const hexCodes = _.map(byteArray, (value) => {
            // eslint-disable-next-line no-magic-numbers
            const hexCode = value.toString(16);
            return hexCode;
            //const paddedHexCode = hexCode.padStart(2, '0');
            //return paddedHexCode;
        });

        return hexCodes.join("");
    };
    const crypto = window.crypto || window.msCrypto;
    const identifier = textEncode(`${user.userId}-${user.login}-${user.email}`);
    const isAdmin = user.isAdmin;
    if (window.msCrypto) {
        const op = crypto.subtle.digest("SHA-256", identifier);
        op.oncomplete = function (event) {
            const encodedId = event.target.result;
            mixpanel.identify(`${hexString(encodedId)}-${db}-${server}`);
            mixpanel.people.set({ server, database: db, admin: isAdmin });
        };
    } else {
        try {
            // Natalia got Chrome crashing because subtle was undefined. Why?!
            crypto.subtle.digest("SHA-256", identifier).then((encodedId) => {
                mixpanel.identify(`${hexString(encodedId)}-${db}-${server}`);
                mixpanel.people.set({ server, database: db, admin: isAdmin });
            });
        } catch (ex) {
            // eslint-disable-next-line no-console
            console.error(ex);
        }
    }
};

export default setupMixpanel;
