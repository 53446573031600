import { Header } from "./Header";

export class EmptyHeader extends Header {
    constructor() {
        super(1, 0, null, "EmptyHeader");
        this.hide = true;
    }

    // eslint-disable-next-line no-unused-vars
    indexOf(entry, onlyVisible) {
        return 0;
    }

    // eslint-disable-next-line no-unused-vars
    lastIndexOf(entry, onlyVisible) {
        return 1;
    }

    getValuesfunction() {
        return [{}];
    }

    valueAt() {
        return {};
    }

    // eslint-disable-next-line no-unused-vars
    getLabel(obj, size) {
        return "";
    }
    // eslint-disable-next-line no-unused-vars
    getSettings(providers) {
        return null;
    }
}
