/* eslint-disable no-useless-escape */
/*
    cookie.js
    Based on Mozilla Developer Networks' "Simple cookie framework", available at
    https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie/Simple_document.cookie_framework
    and in the public domain as stated in
    https://developer.mozilla.org/en-US/docs/MDN/About#Copyrights_and_licenses
    Any copyright is dedicated to the Public Domain. http://creativecommons.org/publicdomain/zero/1.0/
*/

export default {
    get: (sKey) => {
        if (!sKey) {
            return null;
        }
        return (
            decodeURIComponent(
                document.cookie.replace(
                    new RegExp(
                        `(?:(?:^|.*;)\\s*${encodeURIComponent(sKey).replace(
                            /[\-\.\+\*]/g,
                            "\\$&"
                        )}\\s*\\=\\s*([^;]*).*$)|^.*$`
                    ),
                    "$1"
                )
            ) || null
        );
    },
    set: (sKey, sValue, vEnd, sPath, sDomain, bSecure) => {
        if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
            return false;
        }
        let sExpires = "";
        if (vEnd) {
            // eslint-disable-next-line default-case
            switch (vEnd.constructor) {
                case Number:
                    sExpires =
                        vEnd === Infinity
                            ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT"
                            : `; max-age=${vEnd}`;
                    break;
                case String:
                    sExpires = `; expires=${vEnd}`;
                    break;
                case Date:
                    sExpires = `; expires=${vEnd.toUTCString()}`;
                    break;
            }
        }
        document.cookie = `${encodeURIComponent(sKey)}=${encodeURIComponent(sValue)}${sExpires}${
            sDomain ? `; domain=${sDomain}` : ""
        }${sPath ? `; path=${sPath}` : ""}${bSecure ? "; secure" : ""}`;
        return true;
    },
    remove: (sKey, sPath, sDomain) => {
        if (!this.has(sKey)) {
            return false;
        }
        document.cookie = `${encodeURIComponent(sKey)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT${
            sDomain ? `; domain=${sDomain}` : ""
        }${sPath ? `; path=${sPath}` : ""}`;
        return true;
    },
    has: (sKey) => {
        if (!sKey) {
            return false;
        }
        return new RegExp(
            `(?:^|;\\s*)${encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&")}\\s*\\=`
        ).test(document.cookie);
    },
    keys: () => {
        const aKeys = document.cookie
            .replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "")
            .split(/\s*(?:\=[^;]*)?;\s*/);
        for (let nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) {
            aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]);
        }
        return aKeys;
    },
};
