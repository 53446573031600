import { DATE_HEADER_DAYS_IN_FULL_WEEK, DateHeader } from "./DateHeader";
import { DatePeriodHeader } from "./DatePeriodHeader";
import { OBJECT_HEADER_DEFAULT_VISIBLE_VALUES, ObjectHeader } from "./ObjectHeader";
import { PeriodHeader } from "./PeriodHeader";
import { TIME_HEADER_DEFAULT_VISIBLE_VALUES, TimeHeader } from "./TimeHeader";
import { TimePeriodHeader } from "./TimePeriodHeader";
import { WEEKDAY_HEADER_DAYS_IN_WEEK, WeekdayHeader } from "./WeekdayHeader";
import { WeekdayPeriodHeader } from "./WeekdayPeriodHeader";
import { WEEK_HEADER_VISIBLE_WEEKS, WEEK_HEADER_WEEK_SIZE, WeekHeader } from "./WeekHeader";
import { WeekPeriodHeader } from "./WeekPeriodHeader";
import {
    MillenniumWeek,
    MillenniumWeekday,
    MillenniumDate,
    MillenniumTime,
} from "@timeedit/millennium-time";
import Language from "../lib/Language";

import _ from "underscore";

const DEFAULT_SIZE = 18;

/**
 * Header utils
 */

export const toPeriodHeader = (period) => {
    const data = _.extend({}, period, {
        visibleValues: 1,
        firstVisibleValue: 0,
        weekDays: [],
        weeks: [],
        size: period.size || DEFAULT_SIZE,
    });

    let header: PeriodHeader;
    switch (data.kind) {
        case "perioddate":
            header = parseDatePeriodHeader(data);
            break;
        case "periodweek":
            header = parseWeekPeriodHeader(data);
            break;
        case "periodweekday":
            header = parseWeekdayPeriodHeader(data);
            break;
        case "periodtime":
            header = parseTimePeriodHeader(data);
            break;
        default:
            throw new Error(`Unknown header type in toPeriodHeader: ${data.kind}.`);
    }

    header.name = period.name;
    return header.freeze();
};

export const parseWeekPeriodHeader = (data, limits?) => {
    // eslint-disable-line no-unused-vars
    const header = new WeekPeriodHeader(data.visibleValues, data.firstValue);
    header.id = data.id;
    header.name = data.name;
    header.names = data.names;
    header.size = data.size;

    header.values = data.periods.map((period) =>
        MillenniumWeek.create(period, Language.firstDayOfWeek, Language.daysInFirstWeek)
    );

    header.weekdays = data.weekDays.map((weekday) =>
        MillenniumWeekday.getByRepresentation(weekday)
    );
    if (header.weekdays.length === 0) {
        header.weekdays = [Language.firstDayOfWeek];
    }

    header.validateValues();

    return header;
};

export const parseDatePeriodHeader = (data, limits?) => {
    // eslint-disable-line no-unused-vars
    const header = new DatePeriodHeader(data.visibleValues, data.firstValue);
    header.id = data.id;
    header.name = data.name;
    header.names = data.names;
    header.size = data.size;

    header.isWeekPeriod = data.isWeekPeriod || header.isWeekPeriod;
    header.weeks = [];
    if (data.weeks) {
        header.weeks = data.weeks.map((week) =>
            MillenniumWeek.create(week, Language.firstDayOfWeek, Language.daysInFirstWeek)
        );
    }
    header.futureWeeksOnly = data.futureWeeksOnly || false;

    header.values = [];
    const toMillenniumDate = (dateNumber) => new MillenniumDate(dateNumber);
    for (let i = 0; i < data.periods.length; i++) {
        header.values.push(data.periods[i].map(toMillenniumDate));
    }

    header._filteredValues = getFilteredValues(header.weeks, header.values);

    header.validateValues();

    return header;
};

export const parseWeekdayPeriodHeader = (data, limits?) => {
    const header = new WeekdayPeriodHeader(data.visibleValues, data.firstValue);
    header.id = data.id;
    header.name = data.name;
    header.names = data.names;
    header.size = data.size;

    header.values = [];
    let tmp;
    const weekdaySort = function (a, b) {
        return a.getAbsoluteWeekdayNumber() - b.getAbsoluteWeekdayNumber();
    };
    for (let i = 0; i < data.periods.length; i++) {
        tmp = [];
        const periodValues = _.uniq(data.periods[i]);
        for (let j = 0; j < periodValues.length; j++) {
            tmp.push(MillenniumWeekday.getByRepresentation(periodValues[j]));
        }
        tmp.sort(weekdaySort);
        header.values.push(tmp);
    }

    header.weeks = MillenniumWeek.create(
        data.weeks,
        Language.firstDayOfWeek,
        Language.daysInFirstWeek
    );
    if (header.weeks.length === 0) {
        header.weeks.push(MillenniumWeek.today(Language.firstDayOfWeek, Language.daysInFirstWeek));
    }
    header.futureWeeksOnly = data.futureWeeksOnly || false;

    header.validateValues();

    return header;
};

export const parseTimePeriodHeader = (data, limits?) => {
    // eslint-disable-line no-unused-vars
    const header = new TimePeriodHeader(data.visibleValues, data.firstValue);
    header.id = data.id;
    header.name = data.name;
    header.names = data.names;
    header.size = data.size;

    const timestampsToObjects = function (range) {
        return {
            start: new MillenniumTime(range.start),
            end: new MillenniumTime(range.end),
        };
    };

    header.values = [];
    for (let i = 0; i < data.periods.length; i++) {
        header.values.push(data.periods[i].map(timestampsToObjects));
    }

    header.validateValues();

    return header;
};

export const parseWeekHeader = (data, limits?) => {
    const header = new WeekHeader(WEEK_HEADER_VISIBLE_WEEKS);

    const startDate = limits.getStartDate();
    const endDate = limits.getEndDate();
    header.start = new MillenniumWeek(startDate, Language.firstDayOfWeek, Language.daysInFirstWeek);
    const endWeek = new MillenniumWeek(endDate, Language.firstDayOfWeek, Language.daysInFirstWeek);
    header.numberOfWeeks = endWeek.weeksBetween(header.start);
    header.size = data.size;

    const currentWeek = new MillenniumWeek(
        startDate.addDays(data.firstValue * WEEK_HEADER_WEEK_SIZE),
        Language.firstDayOfWeek,
        Language.daysInFirstWeek
    );
    header._firstVisibleValue = header.indexOf(currentWeek, false);
    header.visibleValues = data.visibleValues;

    header.weekdays = [];
    for (let i = 0; i < data.weekDays.length; i++) {
        header.weekdays.push(MillenniumWeekday.getByRepresentation(data.weekDays[i]));
    }
    if (header.weekdays.length === 0) {
        header.weekdays.push(Language.firstDayOfWeek);
    }

    return header;
};

export const parseWeekdayHeader = (data, limits?) => {
    const header = new WeekdayHeader(WEEKDAY_HEADER_DAYS_IN_WEEK);
    header.firstVisibleValue = data.firstValue;
    header.visibleValues = data.visibleValues;
    header.size = data.size;

    const weekLimits = getWeekLimits(limits);

    const currentWeek = MillenniumWeek.today(Language.firstDayOfWeek, Language.daysInFirstWeek);
    const limitsContainWeek = (week) =>
        week.week() >= weekLimits.start.week() && week.week() <= weekLimits.end.week();

    header.weeks = data.weeks
        .map((week) =>
            MillenniumWeek.create(week, Language.firstDayOfWeek, Language.daysInFirstWeek)
        )
        .filter(limitsContainWeek);

    if (header.weeks.length === 0) {
        header.weeks = [limitsContainWeek(currentWeek) ? currentWeek : weekLimits.start];
    }
    header.futureWeeksOnly = data.futureWeeksOnly || false;

    return header;
};

export const parseObjectHeader = (data, limits?) => {
    const header = new ObjectHeader(OBJECT_HEADER_DEFAULT_VISIBLE_VALUES);
    header.firstVisibleValue = data.firstValue;
    header.visibleValues = data.visibleValues;
    header.searchCriteria.type = data.type;
    header.objectLabelFieldId = data.field || null;
    header.infoLabel = "Default";
    header.size = data.size;

    header.searchCriteria.includeObjects = data.includeObjects;
    header.searchCriteria.excludeObjects = data.excludeObjects;

    return header;
};

export const parseTimeHeader = (data, limits?) => {
    const header = new TimeHeader(TIME_HEADER_DEFAULT_VISIBLE_VALUES);
    header.limits = limits;
    header._firstVisibleValue = Math.round(data.firstValue / header.discreteStep);
    header.visibleValues = Math.ceil(data.visibleValues / header.discreteStep);

    return header;
};

export const parseDateHeader = (data: any, limits?: any) => {
    const daysPerWeek = data.daysPerWeek || DATE_HEADER_DAYS_IN_FULL_WEEK;
    const header = new DateHeader(daysPerWeek);
    header.daysPerWeek = daysPerWeek;
    header.majorStepLimit = daysPerWeek;
    header.limits = limits;
    header.size = data.size;
    if (data.opensOnToday !== undefined && limits.isStartDayAbsolute) {
        header.opensOnToday = data.opensOnToday;
    } else {
        header.opensOnToday = true;
    }
    let today = MillenniumDate.today();
    if (!limits.isStartDayAbsolute) {
        if (data.visibleValues < data.daysPerWeek) {
            if (header.opensOnToday) {
                header._firstVisibleValue = header.getIndexOfDate(today);
            } else {
                header._firstVisibleValue = data.firstValue;
            }
        } else {
            const week = new MillenniumWeek(
                header.getStartDate().addDays(header.getConvertedIndex(data.firstValue)),
                Language.firstDayOfWeek,
                Language.daysInFirstWeek
            );
            const firstDateInWeek = week.getStartOfWeek().getMillenniumDate();
            header._firstVisibleValue = header.getIndexOfDate(firstDateInWeek);
        }
    } else {
        if (
            header.opensOnToday &&
            today.getDayNumber() >= limits.startDay &&
            today.getDayNumber() <= limits.startDay + limits.dayCount
        ) {
            while (!header.isWeekdayVisible(today)) {
                today = today.addDays(1);
            }
            // If today plus visible days streches beyond the calendar limit
            // find the appropriate first visible day taking skipped days into account
            const allVisible = header.getValues();
            const lastVisibleDay = new MillenniumDate(limits.startDay).addDays(limits.dayCount);
            const todayIndex = allVisible.findIndex(
                (date) => date.getDayNumber() === today.getDayNumber()
            );
            const lastDayIndex = allVisible.findIndex(
                (date) => date.getDayNumber() === lastVisibleDay.getDayNumber()
            );
            if (!todayIndex) {
                header._firstVisibleValue = data.firstValue;
            } else if (lastDayIndex === -1) {
                header._firstVisibleValue = header.getIndexOfDate(today);
            } else {
                if (lastDayIndex - todayIndex < data.visibleValues) {
                    const stepsBack = data.visibleValues - (lastDayIndex - todayIndex);
                    today = allVisible[Math.max(0, todayIndex - stepsBack)];
                }
                header._firstVisibleValue = header.getIndexOfDate(today);
            }
        } else {
            header._firstVisibleValue = data.firstValue;
        }
    }
    header.visibleValues = data.visibleValues;
    return header;
};

/**
 * Other utils
 */

const getFilteredValues = (weeks, values) => {
    const weekNumbers = weeks.map((week) => week.week(true));
    const isDateInWeekList = (date) =>
        _.contains(
            weekNumbers,
            date.getWeek(true, Language.firstDayOfWeek, Language.daysInFirstWeek)
        );

    return values.map((dates) => dates.filter(isDateInWeekList));
};

export const getWeekLimits = (limits) => ({
    start: new MillenniumWeek(
        limits.getStartDate(),
        Language.firstDayOfWeek,
        Language.daysInFirstWeek
    ),
    end: new MillenniumWeek(limits.getEndDate(), Language.firstDayOfWeek, Language.daysInFirstWeek),
});
