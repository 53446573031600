import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import ContextMenu from "../lib/ContextMenu";
import _ from "underscore";

class Cell extends React.Component {
    static propTypes = {
        text: PropTypes.string.isRequired,
        title: PropTypes.string,
        axis: PropTypes.oneOf(["x", "y"]).isRequired,
        subheader: PropTypes.element,
        menuItems: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.string,
                label: PropTypes.string,
                action: PropTypes.func,
            })
        ),
        size: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }).isRequired,
        isCurrent: PropTypes.bool,
        onClick: PropTypes.func,
    };

    componentDidMount() {
        this.registerMenu(this.props);
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps, this.props)) {
            this.registerMenu(this.props);
        }

        // On resize, prevent call to onClick handler
        if (!_.isEqual(prevProps.size, this.props.size)) {
            this._willCallClickHandler = false;
        }
    }

    componentWillUnmount() {
        this.deregisterMenu();
    }

    onMouseDown = () => {
        this._willCallClickHandler = true;
    };

    onMouseUp = () => {
        if (this.props.onClick && !this._willCallClickHandler) {
            return;
        }

        this._willCallClickHandler = false;
        if (this.props.onClick) {
            this.props.onClick();
        }
    };

    deregisterMenu = () => {
        if (this.state && this.state.listenerId) {
            ContextMenu.removeListener(this.state.listenerId);
        }
    };

    registerMenu = (props) => {
        this.deregisterMenu();
        const listenerId = ContextMenu.addListener(
            ReactDOM.findDOMNode(this),
            props.menuItems,
            ContextMenu.RIGHT_CLICK
        );
        this.setState({ listenerId });
    };

    render() {
        const dragBefore = <div className={this.props.axis === "y" ? "dragTop" : "dragLeft"} />;
        const dragAfter = <div className={this.props.axis === "y" ? "dragBottom" : "dragRight"} />;
        const dragHeaderRight =
            this.props.axis === "y" ? <div className={"dragHeaderRight"} /> : null;

        const style = _.clone(this.props.size);
        if (this.props.axis === "x") {
            style.lineHeight = `${style.height}px`;
        }

        const classes = ["cell"];
        if (this.props.classNames) {
            Object.keys(this.props.classNames).forEach((className) => {
                classes.push(className);
            });
        }
        if (!this.props.subheader) {
            classes.push("headerText");
            if (this.props.isCurrent === true) {
                classes.push("current");
            }
            return (
                <div
                    className={classes.join(" ")}
                    title={this.props.title || this.props.text}
                    style={style}
                    onMouseDown={this.onMouseDown}
                    onMouseUp={this.onMouseUp}
                >
                    {dragBefore}
                    {this.props.text}
                    {dragAfter}
                    {dragHeaderRight}
                </div>
            );
        }

        const headerClasses = ["headerText"];
        if (this.props.isCurrent === true) {
            headerClasses.push("current");
        }

        return (
            <div className={classes.join(" ")}>
                {dragBefore}
                <div
                    className={headerClasses.join(" ")}
                    title={this.props.text}
                    style={style}
                    onMouseDown={this.onMouseDown}
                    onMouseUp={this.onMouseUp}
                >
                    {dragHeaderRight}
                    {this.props.text}
                </div>
                <div className="subcell">{this.props.subheader}</div>
                {dragAfter}
            </div>
        );
    }
}

export default Cell;
