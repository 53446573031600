import React from "react";
import Language from "../lib/Language";

class ErrorComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showStack: false,
        };
    }

    render() {
        const supportLink = (
            <a href="https://support.timeedit.net/" target="_blank">
                TimeEdit Support
            </a>
        );

        const crashReportStrings = Language.get("nc_error_crash_report_issue_info").split(
            /\{\s*0\s*\}/
        );

        return (
            <div className="errorBoundaryWrapper" style={this.props.style}>
                <div style={{ width: "600px", maxWidth: "90%" }}>
                    <h1>{Language.get("nc_error_crash_oops")}</h1>
                    <p>{this.props.text}</p>
                    <p>
                        {crashReportStrings[0]}
                        {supportLink}
                        {crashReportStrings[1]}
                    </p>
                    {this._renderStack()}
                </div>
            </div>
        );
    }

    _renderStack() {
        if (!this.state.showStack) {
            const showStack = () => this.setState({ showStack: true });
            return (
                <pre className="stack" onClick={showStack} style={{ cursor: "pointer" }}>
                    {Language.get("nc_error_crash_click_here_to_show_technical_information")}
                </pre>
            );
        }

        const nl2br = (str) => (str ? str.split("\n").map((row) => [row, <br />]) : <br />);
        const { error, componentStack } = this.props;
        const errorDescription = error ? error.toString() : "";
        const componentStackString = `Component stack:${componentStack}`;
        const jsStackString = error.stack ? `\n\nJavascript stack:\n${error.stack}` : null;
        return (
            <pre className="stack">
                {errorDescription}
                <br />
                <br />
                {nl2br(componentStackString)}
                {nl2br(jsStackString)}
            </pre>
        );
    }
}

export default ErrorComponent;
