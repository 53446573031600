import { Limits } from "./Limits";
import { Model } from "./Model";
import TemplateKind from "./TemplateKind";
import { MillenniumDateTime } from "@timeedit/millennium-time";
import { Settings } from "./Settings";

const DEFAULT_LIST_ID = 7;

export class PrefsData extends Model {
    templateKind: any;
    privateSelected: boolean;
    selection: null;
    limits: any;
    typeFilter: never[];
    isTimePeriod: boolean;
    listId: number;
    reservationIds: never[];

    constructor(limits = Limits.getDefaultList(), selection = null, reservationIds = []) {
        super("PrefsData");
        this.templateKind = TemplateKind.RESERVATION;
        this.privateSelected = false;
        this.selection = selection || null;
        this.limits = limits;
        this.typeFilter = [];
        this.isTimePeriod = false;
        this.listId = DEFAULT_LIST_ID;
        this.reservationIds = reservationIds;
    }

    getSettings() {
        const settings = [];
        return new Settings(settings);
    }

    getMacroFunctions() {
        const macros = [];
        return macros;
    }

    setLimits(limits) {
        if (!(limits instanceof Limits)) {
            // eslint-disable-next-line no-param-reassign
            limits = new Limits(limits);
        }

        return this.immutableSet({
            limits,
        });
    }

    setIsTimePeriod(isTimePeriod) {
        return this.immutableSet({ isTimePeriod });
    }

    updateTimeRange(newBeginTime, newEndTime) {
        let limits = new Limits(this.limits);
        limits = limits.setStartDayFromDate(
            new MillenniumDateTime(newBeginTime).getMillenniumDate()
        );
        limits = limits.setDayCountFromDate(new MillenniumDateTime(newEndTime).getMillenniumDate());
        return this.immutableSet({
            limits,
        });
    }

    // What about the search settings object and such? How to even get hold of it from here?
    toJSON() {
        return {
            limits: this.limits,
            privateSelectedList: this.privateSelected,
            templateKind: this.templateKind.mode,
            typeFilter: this.typeFilter,
            reservationIds: this.reservationIds,
            function: this.getMacroFunctions(),
            isList: false,
            isPrefsComponent: true,
            listId: this.listId,
        };
    }
}
