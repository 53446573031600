import API from "../lib/TimeEditAPI";
import _ from "underscore";
import { Header } from "./Header";
import Lang from "../lib/Language";

export const OBJECT_HEADER_DEFAULT_VISIBLE_VALUES = 4;

const setNameToField = function (objects, fieldId) {
    return objects.map((object) => {
        const field = _.find(object.fields, (item) => item.id === fieldId);
        let name = "";
        if (field) {
            name = field.values[0];
        } else if (object.fields[0].values) {
            name = object.fields[0].values[0];
        }
        return _.extend(_.clone(object), { name });
    });
};

const getObjects = function (searchCriteria, fields, callback) {
    if (searchCriteria.type === null) {
        return callback([]);
    }
    if (searchCriteria.includeObjects.length > 0) {
        return API.getObjects(searchCriteria.includeObjects, callback);
    }

    const criteria = _.clone(searchCriteria);
    criteria.returnFields = fields;

    API.findObjects(criteria, (objects) => {
        if (criteria.excludeObjects && criteria.excludeObjects.length > 0) {
            callback(
                _.filter(objects, (object) => !_.contains(criteria.excludeObjects, object.id))
            );
        } else {
            callback(objects);
        }
    });
    return null;
};
export class ObjectHeader extends Header {
    searchCriteria: { type: null; includeObjects: any[]; excludeObjects: any[] };
    values: any[];
    infoLabel: string;
    objectLabelFieldId: null;
    primaryFields: any[];
    isUpdated: boolean;

    constructor(visibleValues?, firstVisibleValue?, subheader?) {
        super(visibleValues || 0, firstVisibleValue, subheader, "ObjectHeader");
        this.searchCriteria = {
            type: null,
            includeObjects: [],
            excludeObjects: [],
        };
        this.values = [];
        this.infoLabel = "";
        this.objectLabelFieldId = null;
        this.primaryFields = [];
        this.isUpdated = false;
        this.size = 18;
    }

    updateSearchCriteria(criteria, callback) {
        API.findTypes((types) => {
            const currentType = _.find(types, (type) => type.id === criteria.type);

            const getObjectsWithFields = function (header) {
                const fields = header.primaryFields.map((field) => field.value);
                const done = (values) => {
                    callback(
                        header.immutableSet({
                            searchCriteria: criteria,
                            values,
                            infoLabel: currentType ? currentType.name : "",
                            isUpdated: true,
                            visibleValues:
                                header.visibleValues > values.length
                                    ? values.length
                                    : header.visibleValues,
                        })
                    );
                };

                getObjects(criteria, fields, (result) => {
                    let values = result;
                    values = setNameToField(result, header.objectLabelFieldId);

                    if (header.objectLabelFieldId) {
                        done(values);
                        return;
                    }

                    const objectTypes = _.pluck(result, "id").map((id) => ({
                        id,
                        type: criteria.type,
                    }));
                    API.getObjectNames(objectTypes, false, (objectNames) => {
                        const getName = (id) => {
                            const names = objectNames;
                            const item = _.find(names, (objectName) => objectName.id === id);
                            if (item) {
                                return item.fields[0].values[0];
                            }
                            return null;
                        };

                        values = values.map((item) =>
                            _.extend(item, {
                                name: getName(item.id) || item.name,
                            })
                        );
                        done(values);
                    });
                });
            };

            if (this.searchCriteria.type !== criteria.type || this.primaryFields.length === 0) {
                API.getAllFieldDefs(criteria.type, (fieldDefs) => {
                    const fields = fieldDefs
                        .filter((fieldDef) => fieldDef.primary === true)
                        .map((fieldDef) => ({
                            value: fieldDef.id,
                            label: fieldDef.name,
                        }));

                    const header = this.immutableSet({
                        primaryFields: fields,
                        objectLabelFieldId: this.objectLabelFieldId,
                    });
                    getObjectsWithFields(header);
                });
                return;
            }

            getObjectsWithFields(this);
        });
    }

    doUpdateObjects(typeId, criteria, callback) {
        const fieldId = this.searchCriteria.type !== typeId ? null : this.objectLabelFieldId;
        const newHeader = this.immutableSet({ objectLabelFieldId: fieldId });
        newHeader.updateSearchCriteria(criteria, (header) => {
            let visibleValues = newHeader.visibleValues;
            if (header.values.length === newHeader.values.length + 1) {
                visibleValues++;
            } else if (header.values.length < visibleValues) {
                visibleValues = header.values.length;
            }
            if (visibleValues < 2) {
                visibleValues = Math.min(
                    OBJECT_HEADER_DEFAULT_VISIBLE_VALUES + 1,
                    header.values.length
                );
            }
            callback(header.immutableSet({ visibleValues }));
        });
    }

    setObjects(typeId, objects, callback) {
        const criteria = {
            type: typeId,
            includeObjects: [].concat(objects),
            excludeObjects: [],
        };

        this.doUpdateObjects(typeId, criteria, callback);
    }

    addObjects(typeId, objects, callback) {
        const newObjects =
            this.searchCriteria.type === typeId
                ? _.uniq(this.searchCriteria.includeObjects.concat(objects))
                : [].concat(objects);
        const criteria = {
            type: typeId,
            includeObjects: newObjects,
            excludeObjects: [],
        };

        this.doUpdateObjects(typeId, criteria, callback);
    }

    addObject(object, callback) {
        const criteria: any = {
            type: this.searchCriteria.type,
            includeObjects: ([] as any[]).concat(this.searchCriteria.includeObjects),
            excludeObjects: ([] as any[]).concat(this.searchCriteria.excludeObjects),
        };

        if (object.type) {
            // If an object has been dragged from the selection list, the object passed in is the whole fluffy item
            // eslint-disable-next-line no-param-reassign
            object = {
                id: object.object.id,
                typeId: object.type.id,
            };
        }

        if (this.searchCriteria.type !== object.typeId) {
            // Object does not match old type. Replace all!
            criteria.type = object.typeId;
            criteria.includeObjects = [object.id];
        } else if (criteria.excludeObjects.indexOf(object.id) > -1) {
            // Object was previously excluded. Remove from exclude list.
            const index = this.searchCriteria.excludeObjects.indexOf(object.id);
            criteria.excludeObjects.splice(index, 1);
        } else if (criteria.includeObjects.indexOf(object.id) === -1) {
            // Object was not previously on include list. Include!
            criteria.includeObjects.push(object.id);
        }

        this.doUpdateObjects(criteria.type, criteria, callback);
    }

    removeObject(object, callback) {
        const criteria = {
            type: this.searchCriteria.type,
            includeObjects: ([] as any[]).concat(this.searchCriteria.includeObjects),
            excludeObjects: ([] as any[]).concat(this.searchCriteria.excludeObjects),
        };

        if (criteria.includeObjects.indexOf(object.id) > -1) {
            const index = criteria.includeObjects.indexOf(object.id);
            criteria.includeObjects.splice(index, 1);
            if (criteria.includeObjects.length === 0) {
                criteria.type = null;
            }
        } else {
            criteria.excludeObjects.push(object.id);
        }

        if (criteria.type === null) {
            return callback(
                this.immutableSet({ values: [], searchCriteria: criteria, visibleValues: 0 })
            );
        }

        this.updateSearchCriteria(criteria, (header) => {
            callback(header.immutableSet({ visibleValues: this.visibleValues - 1 }));
        });
        return null;
    }

    setType(object, callback) {
        const criteria = {
            type: object.type.id,
            includeObjects: [],
            excludeObjects: [],
        };

        const newHeader = this.immutableSet({ objectLabelFieldId: null });

        newHeader.updateSearchCriteria(criteria, (header) => {
            let visibleValues = 5;
            if (header.values.length < OBJECT_HEADER_DEFAULT_VISIBLE_VALUES + 1) {
                visibleValues = header.values.length;
            }

            callback(header.immutableSet({ visibleValues }));
        });
    }

    indexOf(entry, onlyVisible) {
        const values = Boolean(onlyVisible) ? this.getVisibleValues() : this.values;
        if (values.length === 0) {
            return 0;
        }
        if (!entry.objects || entry.objects.length === 0) {
            return 0;
        }

        let pos = -1;
        values.forEach((el, index) => {
            const foundObject = _.find(entry.objects, (entryObject) => entryObject === el.id);
            if (foundObject) {
                pos = index;
            }
        });
        return pos;
    }

    getValues() {
        return this.values;
    }

    lastIndexOf(entry, onlyVisible) {
        return this.indexOf(entry, onlyVisible) + 1;
    }

    valueAt(index, onlyVisible = true) {
        if (index < 0 || (onlyVisible && index >= this.visibleValues)) {
            console.error(`Index out of bounds in ObjectHeader.valueAt(${index})`);
            return null;
        }

        const values = onlyVisible ? this.getVisibleValues() : this.getValues();
        return values[index];
    }

    getLabel(obj) {
        return obj.name;
    }

    getInfo(value) {
        if (this.getVisibleValues().indexOf(value) === 0) {
            return this.infoLabel;
        }
        return null;
    }

    // eslint-disable-next-line no-unused-vars
    getSettings(providers) {
        const self = this;
        const settings = super.getSettings();

        const visibleValues = settings.find("visibleValues");
        visibleValues.label = Lang.get("cal_res_side_view_visible_objects");

        if (this.values.length === 0) {
            return settings;
        }

        settings.items.push({
            id: "objectLabelField",
            label: Lang.get("nc_header_settings_object_label_field"),
            type: "array",
            limit: 1,
            get() {
                // ArrayInput uses a selected property on objects to determine selection.
                // Our fields are frozen objects, so we have to make copies.
                // Should this be done in some nicer, cleaner way?
                // (For example, by providing ArrayInput a function to determine selection?)
                const result = self.primaryFields.map((field) => {
                    const newField = _.clone(field);
                    newField.selected = newField.value === self.objectLabelFieldId;
                    return newField;
                });
                const noSelection = {
                    label: "-",
                    value: null,
                    selected: self.objectLabelFieldId === null,
                };
                return [noSelection].concat(result);
            },
            set(val) {
                const newHeader = self.immutableSet({ objectLabelFieldId: val });
                if (_.isNullish(val)) {
                    return newHeader.immutableSet({ isUpdated: false });
                }

                return newHeader.immutableSet({ values: setNameToField(self.values, val) });
            },
        });

        return settings;
    }

    toJSON() {
        const json = super.toJSON();
        return _.extend(json, {
            kind: "object",
            type: this.searchCriteria.type,
            includeObjects: this.searchCriteria.includeObjects,
            excludeObjects: this.searchCriteria.excludeObjects,
            size: this.size,
            field: this.objectLabelFieldId,
        });
    }
}
