const Grid = {};

Grid.getIndexFromPosition = function (position, numCells, size, isContinuous) {
    if (position > size) {
        throw new Error("Position cannot be larger than the grid size.");
    }
    if (position < 0) {
        throw new Error("Position must be greater than or equal to 0.");
    }
    if (numCells < 0) {
        throw new Error("Number of cells must be greater than or equal to 0.");
    }

    // All indexes include the start position and exclude the end position
    // with the exception of the very last position which must be included in the last cell
    if (position === size && !isContinuous) {
        return numCells - 1;
    }

    let index = 0;
    const standardSize = Math.floor(size / numCells);
    const remainder = size % numCells;

    let counter = standardSize;
    while (position >= counter) {
        index = index + 1;

        counter = counter + standardSize;
        if (index >= numCells - remainder) {
            counter++;
        }
    }

    if (!isContinuous) {
        return index;
    }

    const fraction = 1 - (counter - position) / standardSize;
    return index + fraction;
};

Grid.getPositionFromIndex = function (index, numCells, size) {
    if (index === -1) {
        return 0;
    }
    const standardSize = Math.floor(size / numCells);
    const remainder = size % numCells;
    let position = index * standardSize;

    if (index >= numCells - remainder) {
        position += index - (numCells - remainder);
    }

    return Math.round(position);
};

Grid.getSizeFromIndexes = function (startIndex, endIndex, numCells, size) {
    if (endIndex < startIndex) {
        throw new Error("End index must be larger than start index.");
    }

    return (
        this.getPositionFromIndex(endIndex, numCells, size) -
        this.getPositionFromIndex(startIndex, numCells, size)
    );
};

export default Grid;
