import React from "react";
import createReactClass from "create-react-class";
import ReactDOM from "react-dom";
import Log from "../lib/Log";
import _ from "underscore";

const IGNORE_CODE = -9065;
const FADE_TIMEOUT = 5000;
const EXIT_ANIM_TIMEOUT = 4000;

const shouldIgnore = (context) => {
    if (context && context.code) {
        if (context.code === IGNORE_CODE) {
            return true;
        }
    }
    return false;
};

const MessageBar = createReactClass({
    displayName: "MessageBar",

    getInitialState() {
        return {
            message: null,
            color: null,
        };
    },

    componentDidMount() {
        this._isMounted = true;
        const levels =
            process.env.NODE_ENV === "development"
                ? Log.ALL
                : [
                      Log.INFO,
                      Log.NOTICE,
                      Log.WARNING,
                      Log.ERROR,
                      Log.CRITICAL,
                      Log.ALERT,
                      Log.EMERGENCY,
                  ];
        const listener = (logEntry) => {
            // eslint-disable-next-line no-console
            console.log(logEntry);
            if (shouldIgnore(logEntry.context)) {
                return;
            }
            let color = "red";
            let isImportant = false;
            if (logEntry.level < Log.WARNING) {
                isImportant = true;
            }
            if (logEntry.level === Log.WARNING) {
                color = "yellow";
            }
            if (logEntry.level > Log.WARNING) {
                color = "blue";
            }
            const url = logEntry.context ? logEntry.context.url : undefined;
            this.setState({
                message: logEntry.message,
                color,
                isImportant: isImportant || logEntry.context.isImportant,
                url,
            });
        };
        Log.listen(listener, levels);
    },

    componentWillUnmount() {
        this._isMounted = false;
        clearTimeout(this._timeoutId);
        clearTimeout(this._exitAnimationTimeoutId);
    },

    componentDidUpdate() {
        if (this._timeoutId) {
            clearTimeout(this._timeoutId);
            clearTimeout(this._exitAnimationTimeoutId);
        }
        if (!this.state.isImportant) {
            this._exitAnimationTimeoutId = setTimeout(() => {
                if (this._isMounted) {
                    ReactDOM.findDOMNode(this).classList.add("messageBarFadeOut");
                }
            }, EXIT_ANIM_TIMEOUT);
            this._timeoutId = setTimeout(this.dismiss, FADE_TIMEOUT);
        }
    },

    dismissActive() {
        // Invoked when the user clicks the dismissal-X of the message bar.
        this.dismiss();
    },

    dismiss() {
        if (this._isMounted) {
            this.setState({
                message: null,
                color: null,
            });
        }
    },

    stopFade() {
        if (this._timeoutId) {
            clearTimeout(this._timeoutId);
            clearTimeout(this._exitAnimationTimeoutId);
        }
    },

    resumeFade() {
        if (!this.state.isImportant) {
            const self = this;
            this._exitAnimationTimeoutId = setTimeout(() => {
                if (self._isMounted) {
                    ReactDOM.findDOMNode(self).classList.add("messageBarFadeOut");
                }
            }, EXIT_ANIM_TIMEOUT);
            this._timeoutId = setTimeout(this.dismiss, FADE_TIMEOUT);
        }
    },

    render() {
        const style = _.clone(this.props.style);
        const message = this.state.message;
        if (!message || message === Log.ALL_LOADS_FINISHED_MSG) {
            return <span />;
        }

        const classNames = ["messageBar"];
        const color = this.state.color;
        if (color) {
            classNames.push(color);
        }
        if (this.state.isImportant === true) {
            classNames.push("important");
        }
        if (color === "blue") {
            style.width = undefined;
        }

        classNames.push("messageBarFadeIn");

        if (this.state.url) {
            return (
                <div
                    className={classNames.join(" ")}
                    style={style}
                    onMouseOver={this.stopFade}
                    onMouseOut={this.resumeFade}
                >
                    <div className="messageContent">
                        <a href={this.state.url}>{message}</a>
                        <span onClick={this.dismissActive} className="closeButton" />
                    </div>
                </div>
            );
        }

        return (
            <div
                className={classNames.join(" ")}
                style={style}
                onClick={this.dismissActive}
                onMouseOver={this.stopFade}
                onMouseOut={this.resumeFade}
            >
                <div className="messageContent">
                    {message}
                    <span onClick={this.dismissActive} className="closeButton" />
                </div>
            </div>
        );
    },

    shouldComponentUpdate(nextProps, nextState) {
        const willUpdateState =
            nextState.message !== this.state.message ||
            nextState.color !== this.state.color ||
            nextState.isImportant !== this.state.isImportant ||
            nextState.url !== this.state.url;

        if (willUpdateState) {
            return true;
        }
        return false;
    },
});

export default MessageBar;
