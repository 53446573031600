import React from "react";
import ObjectSelect from "./ObjectSelect";
import API from "../lib/TimeEditAPI";
import Language from "../lib/Language";
import _ from "underscore";
import { TimeEdit } from "../lib/TimeEdit";
import Log from "../lib/Log";

const flexParent = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
};

const verticalFlex = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
};

const horizontalFlex = {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "300px",
};

const leftList = {
    flexGrow: "1",
    marginRight: "10px",
};

const flexGrow = {
    flexGrow: "1",
};

class ExceptionEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedGroupType: null,
            selectedMemberType: TimeEdit.rootType,
            membershipType: null,
            selectedObjects: [],
            parentMemberTypes: [],
            childMemberTypes: [],
            membershipObjects: [],
            exceptions: [],
            addExceptions: [],
            removeExceptions: [],
        };
    }

    componentDidMount() {
        API.getMemberTypesForReservations(this.props.reservationIds, (result) => {
            this.setState(result);
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.selectedGroupType && this.state.selectedMemberType) {
            if (
                prevState.selectedGroupType !== this.state.selectedGroupType ||
                prevState.selectedMemberType !== this.state.selectedMemberType
            ) {
                API.getMemberObjectsForReservations(
                    this.props.reservationIds,
                    this.state.selectedGroupType,
                    this.state.selectedMemberType,
                    (result) => {
                        API.getMemberExceptionObjectsForReservations(
                            this.props.reservationIds,
                            this.state.selectedMemberType,
                            (exceptions) => {
                                this.setState({ membershipObjects: result, exceptions });
                            }
                        );
                    }
                );
            }
        }
    }

    handleAddException(exception) {
        const typedException = { exception, types: this.state.childMemberTypes };
        this.setState({
            addExceptions: this.state.addExceptions.concat(typedException),
            removeExceptions: this.state.removeExceptions.filter((re) => re.id !== exception.id),
            selectedObjects: this.state.selectedObjects.concat(exception),
        });
    }

    handleRemoveException(exception) {
        this.setState({
            addExceptions: this.state.addExceptions.filter(
                (aE) => aE.exception.id !== exception.id
            ),
            removeExceptions: this.state.removeExceptions.concat(exception),
            selectedObjects: this.state.selectedObjects.filter((sO) => sO.id !== exception.id),
        });
    }

    onGroupTypeSelected(event) {
        this.setState({ selectedGroupType: parseInt(event.target.value, 10) });
    }

    onMemberTypeSelected(event) {
        this.setState({ selectedMemberType: parseInt(event.target.value, 10) });
    }

    getType(typeId, typeList) {
        return _.find(typeList, (tp) => tp.id === typeId);
    }

    onSave() {
        API.saveExceptionObjectsForReservations(
            this.props.reservationIds,
            _.uniq(_.flatten(this.state.addExceptions.map((aE) => aE.types))), // Need to track all used lists, appending them if necessary
            this.state.addExceptions.map((aE) => aE.exception.id),
            this.state.removeExceptions.map((rE) => rE.id),
            (result) => {
                if (result[0] === false) {
                    Log.error(result[1][0].details);
                } else {
                    this.props.onClose();
                }
            }
        );
    }

    onCancel() {
        this.props.onClose();
    }

    renderButtons() {
        return (
            <div>
                <button className="modernButton" onClick={this.onCancel.bind(this)}>
                    {Language.get("dialog_cancel")}
                </button>
                <button className="modernButton" onClick={this.onSave.bind(this)}>
                    {Language.get("dialog_save")}
                </button>
            </div>
        );
    }

    render() {
        let childTypes = [];
        if (this.state.selectedGroupType && this.state.childMemberTypes.length > 0) {
            childTypes =
                this.state.childMemberTypes[
                    this.state.parentMemberTypes
                        .map((type) => type.id)
                        .indexOf(this.state.selectedGroupType)
                ];
        }
        const removeIds = this.state.removeExceptions.map((re) => re.id);
        const memberObjects = this.state.membershipObjects.map((obj) => obj.id).concat(removeIds);
        const excludeObjects = this.state.selectedObjects
            .map((obj) => obj.id)
            .filter((eO) => memberObjects.indexOf(eO) !== -1)
            .concat(this.state.exceptions.map((ex) => ex.id))
            .filter((id) => removeIds.indexOf(id) === -1);
        const availableObjects = _.uniq(
            memberObjects.filter((id) => excludeObjects.indexOf(id) === -1)
        );
        const buttons = <div className="buttons btnGroup horizontal">{this.renderButtons()}</div>;
        return (
            <div style={flexParent}>
                <div className="bottomPaddedSelects" style={verticalFlex}>
                    <select
                        onChange={this.onGroupTypeSelected.bind(this)}
                        value={this.state.selectedGroupType || ""}
                    >
                        <option>{Language.get("nc_reservation_exception_select_type")}</option>
                        {this.state.parentMemberTypes.map((type) => (
                            <option key={type.id} value={type.id}>
                                {type.name}
                            </option>
                        ))}
                    </select>
                    <label>{Language.get("nc_reservation_exception_member_type_to_exclude")}</label>
                    <select
                        onChange={this.onMemberTypeSelected.bind(this)}
                        value={this.state.selectedMemberType || ""}
                    >
                        {childTypes.map((type) => (
                            <option key={type.id} value={type.id}>
                                {type.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div style={horizontalFlex}>
                    <div style={leftList} className="objectSelectList">
                        <ObjectSelect
                            width={"100%"}
                            selectedType={this.getType(this.state.selectedMemberType, childTypes)}
                            onObjectSearcherChange={null}
                            selectedFluffyItem={null}
                            onClick={this.handleAddException.bind(this)}
                            onObjectInfo={null}
                            addReloadFunction={null}
                            ref="objectSelection"
                            onRowHover={null}
                            hoverButton={null}
                            modalKey={"allowSecondModal"}
                            highlightSelection={false}
                            reservationIds={this.props.reservationIds}
                            isStatic={true}
                            staticObjects={availableObjects}
                            user={this.props.user}
                        />
                    </div>
                    <div style={flexGrow} className="objectSelectList">
                        <label>{Language.get("nc_reservation_exception_excepted_members")}</label>
                        <br />
                        <ObjectSelect
                            width={"100%"}
                            selectedType={this.getType(this.state.selectedMemberType, childTypes)}
                            onObjectSearcherChange={null}
                            selectedFluffyItem={null}
                            onClick={this.handleRemoveException.bind(this)}
                            onObjectInfo={null}
                            addReloadFunction={null}
                            ref="objectSelection2"
                            onRowHover={null}
                            hoverButton={null}
                            modalKey={"allowSecondModal"}
                            reservationIds={this.props.reservationIds}
                            isStatic={true}
                            staticObjects={excludeObjects}
                            occuringObjects={this.state.selectedObjects}
                            disabled={this.state.removeAllOfType}
                            highlightSelection={true}
                            allowMultiSelection={true}
                            setClearSelection={this.registerClearSelection}
                            user={this.props.user}
                        />
                    </div>
                </div>
                {buttons}
            </div>
        );
    }
}

export default ExceptionEditor;
