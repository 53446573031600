import React from "react";
import Language from "../lib/Language";

class SummaryBar extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    onClick(event) {
        event.stopPropagation();
        this.props.displaySumWindow();
    }

    render() {
        return (
            <div className="summaryWrapper">
                <div className="summaryBar">
                    <span className="summaryText">{this.props.summaryText}</span>
                    <button
                        className="summaryButton"
                        title={Language.get("nc_reservation_summary")}
                        onClick={this.onClick.bind(this)}
                    />
                </div>
            </div>
        );
    }
    shouldComponentUpdate(prevProps) {
        return this.props.summaryText !== prevProps.summaryText;
    }
}

export default SummaryBar;
