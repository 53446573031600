import React from "react";
import ErrorComponent from "./ErrorComponent";
import Language from "../lib/Language";

class SectionErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            info: null,
        };
    }

    componentDidCatch(error, info) {
        this.setState({ error, info });
    }

    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ error: null, info: null });
        }
    }

    render() {
        const { error, info } = this.state;
        if (error) {
            return (
                <ErrorComponent
                    componentStack={info ? info.componentStack : ""}
                    error={error}
                    style={this.props.size}
                    text={Language.get("nc_error_crash_try_another_view")}
                />
            );
        }
        return this.props.children;
    }
}

export default SectionErrorBoundary;
