import { Macros } from "./Macros";
import API from "../lib/TimeEditAPI";

export class PrimaryFieldManager {
    fields: any;
    fireEvent: any;

    constructor(registerMacro, fireEvent) {
        this.fields = {};
        this.fireEvent = fireEvent;
        // This macro is deregistered by App.jsx when unmounting
        registerMacro(`primaryFieldManager`, {
            events: [Macros.Event.PRIMARY_FIELD_CHANGED],
            actions: [
                {
                    key: Macros.Action.REFRESH,
                    action: (typeId) => {
                        this.getPrimaryField(typeId);
                    },
                },
            ],
        });
    }

    setPrimaryField(typeId, fieldId, fromFindObjects = false) {
        if (this.fields[typeId] === fieldId) {
            return;
        }
        this.fields[typeId] = fieldId;
        API.setPrimaryField(typeId, fieldId, () => {
            this.fireEvent(
                "primaryFieldManager",
                Macros.Event.PRIMARY_FIELD_CHANGED,
                typeId,
                fromFindObjects
            );
        });
    }

    getPrimaryField(typeId) {
        API.getPrimaryFields(typeId, (result) => {
            const resultId = result[0] ? result[0].id : null;
            this.fields[typeId] = resultId;
        });
    }
}
