import DataStore from "../lib/DataStore";

let currentModelId = 0;
const nextModelId = function () {
    return currentModelId++;
};

export class Model {
    _typeName: any;
    _modelId: number;
    _transient: any[];

    constructor(typeName: string) {
        this._typeName = typeName || "Model";
        this._modelId = nextModelId();
        this._transient = [];
    }

    immutableSet(props: any) {
        return DataStore.update(this, this, props) as typeof this;
    }

    freeze() {
        return DataStore.deepFreeze(this) as typeof this;
    }

    mutableCopy() {
        const copy = DataStore.deepClone(this);
        copy._modelId = nextModelId();
        return copy as typeof this;
    }

    getId(): number {
        return this._modelId;
    }

    toJSON() {
        const result = {};
        // eslint-disable-next-line no-restricted-syntax
        for (const prop in this) {
            if (
                this.hasOwnProperty(prop) &&
                prop !== "_transient" &&
                this._transient.indexOf(prop) === -1
            ) {
                result[prop as any] = this[prop];
            }
        }
        return result;
    }

    toString() {
        return `[object ${this._typeName}]`;
    }
}
