import PropTypes from "prop-types";
import React from "react";
import API from "../lib/TimeEditAPI";
import ArrayInput from "./ArrayInput";
import _ from "underscore";

const DEFAULT_SIZE = 5;

class PeriodTypeInput extends React.Component {
    static propTypes = {
        defaultValue: PropTypes.number,
        onUpdate: PropTypes.func,
    };

    state = {
        periods: [],
    };

    componentDidMount() {
        API.findPeriods((periods) => {
            this.setState({ periods: [{ id: 0, name: "-" }].concat(periods) });
        });
    }

    onUpdate(selectedId) {
        this.props.onUpdate(_.find(this.state.periods, (period) => period.id === selectedId));
    }

    render() {
        const periods = this.state.periods.map((period) => ({
            value: period.id,
            label: period.name,
            selected: period.id === this.props.defaultValue,
        }));
        return (
            <ArrayInput
                defaultValue={periods}
                disabled={this.state.periods.length === 0}
                onUpdate={this.onUpdate.bind(this)}
                limit={this.props.limit || 0}
                defaultSize={this.props.defaultSize || DEFAULT_SIZE}
            />
        );
    }
}

export default PeriodTypeInput;
