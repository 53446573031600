import React from "react";
import Language from "../lib/Language";
import _ from "underscore";
import { MillenniumDateTime, SimpleDateFormat } from "@timeedit/millennium-time";

const getRowStatusObject = (statusValue, orderStarted = false) => {
    const statusLabels = {
        0: {
            short: "-",
            long: "-",
            symbol: "",
            color: "",
        },

        1: {
            short: Language.get("order_status_row_partial_short"),
            long: Language.get("order_status_row_partial"),
            symbol: "<",

            // (om levererat antal och levererad tid === 0)
            color: orderStarted ? "rgb(248,248,168)" : "white",
        },

        2: {
            short: Language.get("order_status_row_fulfilled_short"),
            long: Language.get("order_status_row_fulfilled"),
            symbol: "=",
            color: "rgb(168,232,168)",
        },

        3: {
            short: Language.get("order_status_row_exceeded_short"),
            long: Language.get("order_status_row_exceeded"),
            symbol: ">",
            color: "rgb(255,196,196)",
        },

        4: {
            short: Language.get("order_status_row_unlimited_short"),
            long: Language.get("order_status_row_unlimited"),
            symbol: "o",

            // (om inte påbörjad dvs både levererat antal och tid === 0)
            color: orderStarted ? "rgb(208,224,255)" : "white",
        },

        5: {
            short: Language.get("order_status_row_passive_short"),
            long: Language.get("order_status_row_passive"),
            symbol: "-",
            color: "rgb(208,208,208)",
        },
    };
    return statusLabels[statusValue];
};

const renderUnlocalizedLine = (title, value, key, indented = false) => (
    <tr className={indented ? "indented" : ""} key={key || title}>
        <th>{title}</th>
        <td>{value}</td>
    </tr>
);

const getTypeName = (typeId, types) => {
    const type = _.find(types, (tp) => tp.id === typeId);
    if (type) {
        return type.name;
    }
    return typeId;
};

const getUserLabel = (userId, users) => {
    const user = _.find(users, (usr) => usr.id === userId);
    if (!user) {
        return userId;
    }

    if (user.fname && user.lname) {
        return `${user.fname} ${user.lname}`;
    }
    return user.extid ? user.extid : user.id;
};

const getTime = (time) => {
    const formatLong = Language.getDateFormat("date_f_yyyy_mm_dd_hh_mm");
    return SimpleDateFormat.format(new MillenniumDateTime(time), formatLong);
};

const OrderUtils = {
    getOrderStatus: (statusValue) => {
        const statusLabels = {
            0: {
                // Unknown
                label: "-",
            },
            1: {
                // Active
                label: Language.get("order_status_active"),
            },
            2: {
                // Passive
                label: Language.get("order_status_passive"),
            },
        };
        return (statusLabels[statusValue] || statusLabels[0]).label;
    },

    orderRowStatusToString: (statusValue) => {
        const status = getRowStatusObject(statusValue);
        if (status) {
            return status.long;
        }
        return statusValue;
    },

    renderLine: (titleKey, value, key, className = "") => {
        if (value === "") {
            return (
                <tr className={className} key={key || titleKey}>
                    <th colSpan="2">{Language.get(titleKey)}</th>
                </tr>
            );
        }
        return (
            <tr className={className} key={key || titleKey}>
                <th>{Language.get(titleKey)}</th>
                <td>{value}</td>
            </tr>
        );
    },

    getPeriodName: (periodId, periods) => {
        const period = _.find(periods, (pr) => pr.id === periodId);
        if (period) {
            return period.name;
        }
        return periodId === 0 ? "-" : periodId;
    },

    presentObjects: (objects, types) => {
        if (!objects || objects.length === 0) {
            return null;
        }
        return objects.map((object) => {
            if (_.isNullish(object)) {
                return null;
            }
            return renderUnlocalizedLine(
                getTypeName(object.type.id, types),
                object.fields.map((field) => field.values.join(", ")).join(", "),
                `object${object.id}`,
                true
            );
        });
    },

    getOrgName: (id, definitions) => {
        const org = _.find(definitions, (def) => def.id === id) || null;
        if (org) {
            return org.extid;
        }
        return id;
    },

    getUserTime: (time, userId, users) => {
        const userLabel = getUserLabel(userId, users);
        if (!userLabel) {
            return <span>{getTime(time)}</span>;
        }

        return (
            <span>
                {getTime(time)}
                <br />({getUserLabel(userId, users)})
            </span>
        );
    },

    SORT_ORDERS: {
        ORDER_ROW_SORT_ID_ASC: 0,
        ORDER_ROW_SORT_ID_DESC: 1,
        ORDER_ROW_SORT_TIME_ASC: 2,
        ORDER_ROW_SORT_TIME_DESC: 3,
    },

    STATUS: {
        NONE: 0,
        PARTIAL: 1,
        FULFILLED: 2,
        EXCEEDED: 3,
        UNLIMITED: 4,
        PASSIVE: 5,
    },

    ORDER_ID_OFFSET: 1000,
};

export default OrderUtils;
