import _ from "underscore";

const Viewer = (function () {
    function handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }

    const findPages = (language, callback) => {
        const url = `${Viewer.url()}pages.json?l=${language}`;
        //console.log(`findViewerPages url: ${url}`);
        fetch(url)
            .then(handleErrors)
            .then((res) => res.json())
            .then(
                (result) => {
                    callback(result);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                // eslint-disable-next-line no-unused-vars
                (error) => {
                    //console.log(error);
                    callback([]);
                }
            );
    };

    const url = () => {
        const path = window.location.pathname;
        const newPath = `/${path.split("/")[1]}/`;
        const result = `${window.location.protocol}//${window.location.host}${newPath}web/`;
        if (process.env.NODE_ENV === "development") {
            //console.log(`Viewer url debug: ${result}`);
            return "http://localhost:8182/nord/SU/";
        }
        return result;
    };

    const open = (path, user) => {
        window.open(Viewer.url() + user.viewerPageCurrent + path, "OpenInViewer");
    };

    const isActive = (user) => {
        if (_.isEmpty(user.viewerPageCurrent)) {
            return false;
        }
        if (user.viewerPageCurrent === "") {
            return false;
        }
        return true;
    };

    const isEnabled = () => false;

    const load = () => {
        const ids = window.localStorage.getItem("TEViewerResIds");
        if (_.isEmpty(ids) || ids === "") {
            return [];
        }
        return ids
            .split(",")
            .filter(Number)
            .map((text) => parseInt(text, 10));
    };

    const loadAndClear = () => {
        const ids = load();
        window.localStorage.removeItem("TEViewerResIds");
        return ids;
    };

    return {
        url,
        open,
        isActive,
        isEnabled,
        findPages,
        load,
        loadAndClear,
    };
})();

export default Viewer;
