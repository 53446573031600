import React from "react";
import createReactClass from "create-react-class";
import _ from "underscore";
import ScratchpadList from "./ScratchpadList";
import LayerComponent from "../lib/LayerComponent";
import Popover from "./Popover";
import Language from "../lib/Language";

let PinboardButton = createReactClass({
    componentDidMount() {
        this.props.setLayerContentProvider(this.getLayerContent);
        this.props.setLayerCloseHandler(this.onLayerClose);
    },
    componentDidUpdate() {
        if (this.props.isLayerShown) {
            this.props.forceLayerUpdate();
        }
    },
    getLayerContent() {
        const offset = _.nodeOffset(this.refs.pinboardSourceButton);
        const target = {
            top: offset.top,
            left: offset.left,
            width: 32,
            height: 32,
        };
        return (
            <Popover
                target={target}
                style={{ width: Popover.DEFAULT_WIDTH }}
                onClose={this.onLayerClose}
            >
                <ScratchpadList
                    showExtraInfo={this.props.showExtraInfo}
                    objects={this.props.scratchpad.getObjects()}
                    onObjectSelected={this.props.onObjectSelected || _.noop}
                    allowMultiSelection={false}
                    selectedType={this.props.selectedType}
                    hidePlusButton={true}
                />
            </Popover>
        );
    },
    onLayerClose() {},
    render() {
        return (
            <>
                <button
                    ref="pinboardSourceButton"
                    title={Language.get("nc_pinboard")}
                    onClick={this.props.toggleLayer}
                    className="pinboardToggleButton"
                ></button>
            </>
        );
    },
});

export default LayerComponent.wrap(PinboardButton);
