import PropTypes from "prop-types";
import React from "react";
import _ from "underscore";

class ArrayInput extends React.Component {
    static propTypes = {
        defaultValue: PropTypes.array,
        onUpdate: PropTypes.func,
        noValueChangeCheck: PropTypes.bool, // Workaround specifically for TrackPane, is there a better way?
    };

    componentDidUpdate() {
        if (this.props.noValueChangeCheck) {
            // Workaround specifically for TrackPane, is there a better way?
            return;
        }
        const indexes = this.getSelectedIndexes(this.props);
        if (!_.isEqual(indexes, this.state.value)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ value: indexes });
        }
    }

    // eslint-disable-next-line no-unused-vars
    onChange = (evt) => {
        let val = [];
        let indexes = [];
        this.props.defaultValue.forEach(function (item, index) {
            if (this.refs[index].selected) {
                indexes.push(index);
                val.push(item.value);
            }
        }, this);

        if (this.props.limit === 1) {
            val = val[0];
            indexes = indexes[0];
        }

        this.setState({ value: indexes }, () => {
            this.props.onUpdate(val);
        });
    };

    getSelectedIndexes = (props) => {
        const selection = props.defaultValue.reduce((indexes, item, index) => {
            if (item.selected) {
                return indexes.concat(index);
            }
            return indexes;
        }, []);

        if (props.limit === 1) {
            return selection[0];
        }

        return selection;
    };

    state = {
        value: this.getSelectedIndexes(this.props),
    };

    render() {
        let size = this.props.defaultSize || 0;
        if (this.props.limit === 1) {
            size = 1;
        }

        let style = null;

        if (this.props.height) {
            style = {
                height: this.props.height,
            };
        }

        return (
            <select
                disabled={this.props.disabled || false}
                style={style}
                multiple={this.props.limit !== 1}
                onChange={this.onChange}
                value={this.state.value}
                size={size}
            >
                {this.props.defaultValue.map((item, index) => (
                    <option
                        key={index}
                        disabled={item.disabled ? true : null}
                        ref={index}
                        value={index}
                    >
                        {item.label}
                    </option>
                ))}
            </select>
        );
    }
}

export default ArrayInput;
