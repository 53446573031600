import React from "react";
import parser from "ua-parser-js";
import _ from "underscore";
import API from "../../lib/TimeEditAPI";
import PasswordReset from "./PasswordReset";
import Login from "./Login";
import PasswordChange from "./PasswordChange";
// Webpack alias for @resouces
// eslint-disable-next-line import/no-unresolved
import "@resources/sass/login.sass";

class LoginPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        const params = this.getQueryParameters();

        this.state = {
            databases: [],
            selectedDatabase: undefined,
            authServers: [],
            errorMessage: params.message || null,
            rules: params.rules ? params.rules.split("|") : [],
            showBrowserSupportText: false,
        };
    }

    getQueryParameters = () => {
        if (window.location.href.indexOf("?") === -1) {
            return {};
        }

        const params = window.location.href.split("?")[1].split("&");
        return params.reduce((dict, param) => {
            const [key, value] = param.split("=");
            return _.extend(dict, { [key]: decodeURIComponent(value) });
        }, {});
    };

    componentDidMount() {
        // Password change means user is already logged in
        if (this.isPasswordChange()) {
            return;
        }

        API.getDatabases(this.getClient(), (response) => {
            const signature = window.localStorage.getItem("serverSignature");
            const databases = response.map((database) => ({
                name: database.name || database.signature,
                signature: database.signature,
            }));
            const lastSelectedDatabase = _.find(
                databases,
                (database) => database.signature === signature
            );
            const selectedDatabase = lastSelectedDatabase
                ? lastSelectedDatabase.signature
                : databases[0].signature;

            this.setState({ databases });
            this.setSelectedDatabase(selectedDatabase);
        });
    }

    getClient = () => {
        const segments = window.TIMEEDIT_APP_PATH.split("/");
        return segments[segments.length - 1];
    };

    hasError = () => {
        const params = this.getQueryParameters();
        return (
            params.error === "true" || params.error === "false" || Boolean(this.state.errorMessage)
        );
    };

    isPasswordChange = () => this.getQueryParameters().passwordChange === "true";

    setSelectedDatabase = (signature) => {
        this.setState({ authServers: [] });
        const database = signature.split("@")[0];

        API.getAllAuthServers(this.getClient(), database, (response) => {
            if (response instanceof Error) {
                this.setState({ errorMessage: this.props.strings.login_database_not_available });
                return;
            }

            let servers = response.parameters[0].filter((server) => server.hidden !== true);
            if (!this.props.isRoot) {
                servers = servers.filter((server) => server.sso !== true);
            }

            const authServers = servers.map((server) => ({
                name: server.name,
                signature: server.signature,
            }));
            this.setState({ authServers });
        });
        if (window.localStorage) {
            window.localStorage.setItem("serverSignature", signature);
        }
        this.setState({ selectedDatabase: signature });
    };

    onDatabaseSelected = (event) => {
        this.setSelectedDatabase(event.target.value);
    };

    isBrowserSupported = () => {
        const userAgent = parser(navigator.userAgent);
        const VERSIONS = {
            EDGE: 102,
            FIREFOX: 98,
            SAFARI: 13,
            MOBILE_SAFARI: 13,
            CHROME: 103,
        };
        const MIN_SUPPORTED_VERSIONS = [
            ["Edge", VERSIONS.EDGE],
            ["Firefox", VERSIONS.FIREFOX],
            ["Safari", VERSIONS.SAFARI],
            ["Mobile Safari", VERSIONS.MOBILE_SAFARI],
            ["Chrome", VERSIONS.CHROME],
        ];
        return _.some(
            MIN_SUPPORTED_VERSIONS,
            (browser) =>
                userAgent.browser.name === browser[0] &&
                parseInt(userAgent.browser.major, 10) >= browser[1]
        );
    };

    isPageAvailable = () =>
        !this.props.tokenError && navigator.cookieEnabled && this.isLoadingComplete();

    isLoadingComplete = () => this.isPasswordChange() || this.state.authServers.length > 0;

    isPasswordReset = () => window.location.pathname.indexOf("/reset") > -1;

    toggleBrowserSupportText = () => {
        this.setState({ showBrowserSupportText: !this.state.showBrowserSupportText });
    };

    render() {
        const STRINGS = this.props.strings;

        return (
            <div className="login-page-container loginBox">
                <h1>
                    TimeEdit {STRINGS.server_name ? ` - ${STRINGS.server_name}` : ""}
                    <span
                        title={STRINGS.login_loading}
                        className={_.classSet({
                            progress: true,
                            small: true,
                            rightFloating: true,
                            hidden: this.isLoadingComplete(),
                        })}
                    />
                </h1>
                <div className="loginItemsContainer">
                    <div
                        className={_.classSet({ hidden: !this.props.tokenError, error: true })}
                        id="tokenError"
                    >
                        {STRINGS.login_insecure_browser}
                    </div>
                    <div
                        className={_.classSet({ hidden: navigator.cookieEnabled, error: true })}
                        id="cookieError"
                    >
                        {STRINGS.login_cookies_disabled}
                    </div>
                    <div
                        className={_.classSet({ hidden: !this.hasError(), error: true })}
                        id="errorMessage"
                    >
                        {this._renderError()}
                    </div>

                    {this._renderForm()}

                    <div
                        className={_.classSet({
                            hidden: !this.state.showBrowserSupportText,
                            box: true,
                        })}
                        style={{ width: "100%" }}
                    >
                        <p>{this.props.strings.supported_browsers_text}</p>
                    </div>
                    {this._renderSupportButton()}
                </div>
            </div>
        );
    }

    _renderForm = () => {
        const props = {
            isAvailable: this.isPageAvailable(),
            client: this.getClient(),
            appPath: this.props.appPath,
            databases: this.state.databases,
            authServers: this.state.authServers,
            selectedDatabase: this.state.selectedDatabase,
            onDatabaseSelected: this.onDatabaseSelected,
            strings: this.props.strings,
            isRootLogin: this.props.isRoot,
            setErrorMessage: (errorMessage) => this.setState({ errorMessage }),
        };

        if (this.isPasswordReset()) {
            return <PasswordReset {...props} />;
        }

        if (this.isPasswordChange()) {
            return <PasswordChange {...props} />;
        }

        return <Login {...props} />;
    };

    _renderError = () => {
        if (this.state.rules.length > 0) {
            return this._renderRules();
        }

        return this.state.errorMessage || this.props.strings.login_incorrect_user_or_password;
    };

    _renderRules = () => (
        <ul
            className={_.classSet({ hidden: this.state.rules.length === 0 })}
            style={{ width: "100%" }}
        >
            {this.state.rules.map((rule, index) => (
                <li key={index}>{rule}</li>
            ))}
        </ul>
    );

    _renderSupportButton = () => {
        if (this.isBrowserSupported()) {
            return null;
        }

        return (
            <p style={{ textAlign: "right", marginBottom: 5, marginTop: 5, paddingRight: 5 }}>
                <button id="browserSupportButton" onClick={this.toggleBrowserSupportText}>
                    {this.props.strings.supported_browsers}
                </button>
            </p>
        );
    };
}

export default LoginPage;
