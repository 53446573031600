import React from "react";
import ReactDOM from "react-dom";
import Log from "./lib/Log";
import CSRF from "./lib/CSRF";
import { TimeEdit } from "./lib/TimeEdit";
import TimeEditAPI from "./lib/TimeEditAPI";
import path from "path";
import _ from "underscore";
import MessageDispatcher from "./lib/MessageDispatcher";
import UnderscoreExt from "./lib/underscore-ext";
import LoginPage from "./components/login/LoginPage";
import Glitch from "./lib/Glitch";
import { getLoginStringsForLanguage, getUserLanguage } from "./lib/LangUtils";
import Language from "./lib/Language";

window.TIMEEDIT_LOGIN_STRINGS = getLoginStringsForLanguage(getUserLanguage());

const BASE_URL = path.resolve("/", window.TIMEEDIT_APP_PATH);

/**
 * Main application
 */
const loadApp = function (useSSO, environment) {
    TimeEdit.run(useSSO, environment);
};

/**
 * Login page
 */
const loadLogin = function (tokenError) {
    const LoginPageComponent = React.createFactory(LoginPage);
    ReactDOM.render(
        LoginPageComponent({
            strings: window.TIMEEDIT_LOGIN_STRINGS,
            isRoot: window.IS_ROOT,
            tokenError,
            appPath: window.TIMEEDIT_APP_PATH,
        }),
        document.getElementById("container")
    );
};

const REQ_READY = 4;
const HTTP_OK = 200;
const TEN_MINS = 10 * 60000;

function updateLoop() {
    const req = new XMLHttpRequest();
    req.onreadystatechange = () => {
        if (req.readyState === REQ_READY) {
            if (req.status === HTTP_OK) {
                const response = req.response.trim();
                if (!response.startsWith("silent") && window.BUILD_NUM !== response) {
                    // eslint-disable-next-line no-console
                    console.log("update requested");
                    if (!window.updateRequested) {
                        window.updateRequested = true;
                        // eslint-disable-next-line no-alert
                        if (window.confirm(Language.get("nc_client_update_needed"))) {
                            this._isReloading = true;
                            document.location.reload(true);
                        }
                    }
                }
            }
        }
    };
    const url = `${window.CDN_HOST}/version`;
    req.open("get", url, false);
    req.send("");
}

setInterval(updateLoop, TEN_MINS);

document.addEventListener("DOMContentLoaded", () => {
    // Extend underscore.js with any custom utility functions we might need
    _.mixin(UnderscoreExt);

    const isSecure = process.env.NODE_ENV !== "development";
    const tokenError = CSRF.updateToken(isSecure);
    if (tokenError) {
        // eslint-disable-next-line no-console
        console.log("Token error!");
        // eslint-disable-next-line no-console
        console.log(tokenError);
    }

    // Force a slash at the end to play nice with nginx
    // eslint-disable-next-line no-magic-numbers
    const apiTimeout = process.env.NODE_ENV === "development" ? 60000 : 30000;
    const dispatcher = new MessageDispatcher(`${BASE_URL}/`, apiTimeout, () => {
        if (!dispatcher.useSSO) {
            const loginPath = `${BASE_URL}/login`;
            if (
                window.location.pathname !== loginPath &&
                window.location.pathname !== `${loginPath}/`
            ) {
                window.location.href = `${BASE_URL}/login`;
            }
        } else {
            // When using SSO, never redirect to login page
            window.location.reload();
        }
    });
    dispatcher.on("complete", () => {
        document.body.style.cursor = "auto";
    });

    // Log messages
    let eventList = [];
    dispatcher.on("complete", (data) => {
        if (!data || !data.response || !data.response.time) {
            return;
        }

        const customer = TimeEdit.serverInfo ? TimeEdit.serverInfo.server.signature : null;
        const state = TimeEdit.State.get();
        const username = state && state.user ? state.user.login : "";
        const method = data.response.name;
        const time = data.response.time;
        eventList.push({
            customer_signature: customer,
            req_function: method,
            start_time_epoch: Date.now(),
            application_type: "teclient",
            end_username: username,

            end_user_time: time.rtt,
            teserver_time: time.server,
            database_time: time.database,
            proxy_api_time: time.proxy_api,
            proxy_stalled_time: time.proxy_stalled,
            proxy_processing_time: time.proxy_processing,
            server_type: data.response.type,
        });

        const EVENT_BATCH_SIZE = 20;
        if (eventList.length < EVENT_BATCH_SIZE) {
            return;
        }

        const eventData = {
            data: eventList,
        };

        if (process.env.NODE_ENV !== "production") {
            // eslint-disable-next-line no-console
            console.log("Logging API calls:", eventData);
        } else {
            const req = new XMLHttpRequest();
            const url = `${window.location.protocol}//${window.location.host}/v1/events`;
            req.open("post", url, true);
            req.setRequestHeader("Content-Type", "application/json");
            req.send(JSON.stringify(eventData));
        }
        eventList = [];
    });
    dispatcher.on("slowResponse", () => {
        document.body.style.cursor = "progress";
    });
    dispatcher.on("timeout", (data) => {
        Log.debug("Request timed out.", data);
        if (!data.handled) {
            Log.error(Language.get("nc_api_connection_problem"), data);
            const customer = TimeEdit.serverInfo ? TimeEdit.serverInfo.server.signature : null;
            const state = TimeEdit.State.get();
            const username = state && state.user ? state.user.login : "";
            const time = data?.response?.time || {
                rtt: -1,
                server: -1,
                database: -1,
                proxy_api: -1,
                proxy_stalled: -1,
                proxy_processing: -1,
            };
            eventList.push({
                customer_signature: customer,
                req_function: data.method,
                start_time_epoch: Date.now(),
                application_type: "teclient",
                end_username: username,

                end_user_time: time.rtt,
                teserver_time: time.server,
                database_time: time.database,
                proxy_api_time: time.proxy_api,
                proxy_stalled_time: time.proxy_stalled,
                proxy_processing_time: time.proxy_processing,
                server_type: "timeout",
            });
        }
    });

    TimeEditAPI.setMessageDispatcher(dispatcher, Log);

    window.onerror = Glitch.create({
        url: path.resolve(BASE_URL, "errors"),
        batchTime: 5000,
        beforeSend(xhr) {
            xhr.setRequestHeader("X-CSRF-TOKEN", CSRF.getToken());
        },
    });

    if (!String.prototype.startsWith) {
        Object.defineProperty(String.prototype, "startsWith", {
            value(search, rawPos) {
                const pos = rawPos > 0 ? rawPos | 0 : 0;
                return this.substring(pos, pos + search.length) === search;
            },
        });
    }

    dispatcher.send(
        "getServerEnv",
        {},
        (envResult) => {
            const result = envResult.parameters[0];
            // eslint-disable-next-line no-console
            console.log("getServerEnv", result);
            const majorVersion = parseInt(result.platformVersion.split(".")[0], 10);
            const PLATFORM_WITHOUT_SSO = 2;
            let useSSO = false;
            let environment;
            if (result) {
                useSSO = majorVersion > PLATFORM_WITHOUT_SSO;
                environment = result;
                dispatcher.useSSO = useSSO;
            }
            if (!useSSO && document.body.classList.contains("login")) {
                loadLogin(tokenError);
                return;
            }

            loadApp(useSSO, environment);
        },
        false,
        (errorType, message) => {
            Log.error(errorType, message);
            if (document.body.classList.contains("login")) {
                document.getElementById(
                    "container"
                ).innerHTML = `<div class="loginBox"><div class="loginItemsContainer" title="${errorType}: ${JSON.stringify(
                    message
                )}"><p>Could not get a response from the server.<br/>Please try reloading the page, or contact support if the problem persists.</p></div></div>`;
            } else {
                document.getElementById(
                    "container"
                ).innerHTML = `<div><div title="${errorType}: ${JSON.stringify(
                    message
                )}"><p>Could not get a response from the server.<br/>Please try reloading the page, or contact support if the problem persists.</p></div></div>`;
            }
        }
    );
});
