import PropTypes from "prop-types";
import React from "react";
import FieldTableRow from "./FieldTableRow";
import _ from "underscore";
import API from "../lib/TimeEditAPI";
import Lang from "../lib/Language";

const SCROLL_HEIGHT_MOD = 78;

class SelectionListFieldEditor extends React.Component {
    constructor(props) {
        super(props);
        this.displayName = "SelectionListFieldEditor";
        this.state = {
            fields: [],
            fieldDefs: [],
        };
    }

    componentDidMount() {
        this.loadFields();
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props.fields, prevProps.fields)) {
            this.loadFields();
        }
    }

    loadFields() {
        const fieldIds = this.props.fields.map((fld) => fld.id);
        API.getFields(fieldIds, (fields) => {
            API.getFieldDefs(fieldIds, (defs) => {
                this.setState({ fieldDefs: defs, fields });
            });
        });
    }

    hasMultipleValues(field) {
        const valueField = _.find(this.props.fields, (fld) => fld.id === field.id);
        if (valueField) {
            return valueField.values.length > 1;
        }
        return false;
    }

    updateField(field, updateFn) {
        let fieldItem = _.find(this.props.fields, (item) => item.id === field.id);
        const newValues = [].concat(fieldItem.values || []);
        updateFn(newValues);
        fieldItem = _.extend({}, fieldItem, { values: newValues });
        this.context.update(this.props.fluffy, this.props.fluffy.setFields([fieldItem]));
    }

    addField(field) {
        this.updateField(field, (values) => {
            if (values.length === 0) {
                // From the user"s perspective, zero values are displayed as one empty value. Thus we need to go from 0 -> 2.
                values.push("");
            }
            values.push("");
        });
    }

    removeField(field, index) {
        this.updateField(field, (values) => values.splice(index, 1));
    }

    setFieldValue(field, event, newValue, valueIndex) {
        this.updateField(field, (values) => {
            // eslint-disable-next-line no-param-reassign
            values[valueIndex] = newValue;
        });
    }

    getFieldValues(fieldId) {
        const field = _.find(this.props.fields, (fld) => fld.id === fieldId);
        if (!field) {
            return [];
        }
        return field.values;
    }

    render() {
        const fields = this.state.fields
            .map((field, index) => {
                const definition = _.find(this.state.fieldDefs, (def) => def.id === field.id);
                if (!definition) {
                    return null;
                }
                if (!definition.editable) {
                    return null;
                }
                const values = this.getFieldValues(field.id);
                if (values.length === 0) {
                    return null;
                }
                const valuedField = _.extend({}, field, {
                    values,
                });
                const indeterminate = false;
                return (
                    <FieldTableRow
                        key={field.id}
                        isFirstField={index === 0}
                        editable={false}
                        isCompact={true}
                        field={valuedField}
                        definition={definition}
                        indeterminate={indeterminate}
                        addField={this.addField.bind(this, field)}
                        removeField={this.removeField.bind(this, field)}
                        setFieldValue={this.setFieldValue.bind(this, field)}
                    />
                );
            })
            .filter((fld) => fld !== null);
        if (fields.length === 0) {
            fields.push(
                <tbody key={"emptyState"}>
                    <tr key={"emptyState"}>
                        <td style={{ width: "100%", position: "relative", padding: "10px" }}>
                            {Lang.get("nc_field_list_empty")}
                        </td>
                    </tr>
                </tbody>
            );
        }
        return (
            <div className={"selectFieldBox"} id="selectionListFields">
                <div className={"listTopLine"}>
                    <h1 style={{ flexGrow: "1" }}>
                        {Lang.get("cal_reservation_list_column_field")}
                    </h1>
                    <button
                        className={"editGroupButton save"}
                        title={Lang.get("cal_res_below_clear")}
                        onClick={this.props.clearFieldValues}
                        disabled={this.props.disableClear || this.props.fluffy.allFieldsEmpty()}
                    >
                        {Lang.get("nc_dynamic_object_list_clear_search_settings")}
                    </button>
                </div>
                <div
                    style={{
                        overflowY: "scroll",
                        height: `${this.props.height - SCROLL_HEIGHT_MOD}px`,
                    }}
                >
                    <table>{fields}</table>
                </div>
            </div>
        );
    }
}

SelectionListFieldEditor.contextTypes = {
    update: PropTypes.func,
};

export default SelectionListFieldEditor;
