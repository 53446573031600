import React from "react";
import Language from "../../lib/Language";

// eslint-disable-next-line react/prefer-stateless-function
class TopLeft extends React.Component {
    render() {
        return (
            <div className="listTopLine">
                {this.props.topLeft}{" "}
                <span style={{ fontWeight: "bold", paddingLeft: "10px" }} className="summaryLabel">
                    {Language.get("nc_activity_manager")}
                </span>
                {this.props.contents}
            </div>
        );
    }
}

export default TopLeft;
