import { Limits } from "./Limits";
import { Model } from "./Model";
import TemplateKind from "./TemplateKind";
import Language from "../lib/Language";
import { Selection } from "./Selection";
import { MillenniumDateTime } from "@timeedit/millennium-time";
import { Settings } from "./Settings";

const DEFAULT_LIST_ID = 3;

export class WaitingListSearch extends Model {
    templateKind: any;
    privateSelected: boolean;
    selection: any;
    limits: any;
    listId: number;

    constructor(limits = Limits.getDefaultList()) {
        super("WaitingListSearch");
        this.templateKind = TemplateKind.RESERVATION;
        this.privateSelected = true;
        this.selection = new Selection();
        this.limits = limits;
        this.listId = DEFAULT_LIST_ID;
    }

    getSettings(publicSelection) {
        const self = this;
        const settingsArray = [
            {
                id: "isPrivate",
                label: Language.get("cal_header_topleft_ignore_selected"),
                type: "boolean",
                get() {
                    return self.privateSelected;
                },
                set(isPrivate) {
                    if (isPrivate === self.privateSelected) {
                        return self;
                    }

                    const selection = isPrivate ? publicSelection.mutableCopy() : null;

                    return self.immutableSet({
                        privateSelected: isPrivate,
                        selection,
                    });
                },
            },
        ];
        return new Settings(settingsArray);
    }

    getMacroFunctions() {
        const macros = [];
        return macros;
    }

    setLimits(limits) {
        let newLimits = limits;
        if (!(limits instanceof Limits)) {
            newLimits = new Limits(limits);
        }

        return this.immutableSet({
            limits: newLimits,
        });
    }

    updateTimeRange(newBeginTime, newEndTime) {
        let limits = new Limits(this.limits);
        limits = limits.setStartDayFromDate(
            new MillenniumDateTime(newBeginTime).getMillenniumDate()
        );
        limits = limits.setDayCountFromDate(new MillenniumDateTime(newEndTime).getMillenniumDate());
        return this.immutableSet({
            limits,
        });
    }

    // What about the search settings object and such? How to even get hold of it from here?
    toJSON() {
        return {
            limits: this.limits,
            privateSelectedList: this.privateSelected,
            function: this.getMacroFunctions(),
            templateKind: this.templateKind.mode,
            isList: false,
            isWaitingList: true,
            listId: this.listId,
        };
    }
}
