import PropTypes from "prop-types";
import React from "react";
import createReactClass from "create-react-class";
import ReactDOM from "react-dom";
import { TimeEdit } from "../lib/TimeEdit";
import API from "../lib/TimeEditAPI";
import DataStore from "../lib/DataStore";

import _ from "underscore";
import ScrollWheelHandler from "../lib/ScrollWheelHandler";
import ContextMenu from "../lib/ContextMenu";
import { toPeriodHeader } from "../models/utils";
import { Header as HeaderWithLabel } from "../models/Header";
const Size = HeaderWithLabel.Label;
import Language from "../lib/Language";
import ResizeableComponent from "../lib/ResizeableComponent";
import Grid from "../lib/Grid";
import { MillenniumDate } from "@timeedit/millennium-time";
import { DateHeader } from "../models/DateHeader";
import { ObjectHeader } from "../models/ObjectHeader";

const MIN_PIXELS_PER_CELL = 10;
const FORCE_UPDATE_INTERVAL_IN_SECONDS = 60000;
const MIN_PIXELS_PER_INFO_CELL = 30;
const DRAG_TARGET_THRESHOLD = 20;
const MIN_PIXELS_VERTICAL_WIDTH = 18;

/* Used in Render function*/
import Cell from "./Cell";
import InfoRow from "./InfoRow";

/**
 * The height of an X header.
 * @type {Number}
 */
const MIN_X_SIZE = 20;

/**
 * The width of a Y header.
 * @type {Number}
 */
const MIN_Y_SIZE = 40;

const Header = _.extend(
    ResizeableComponent.wrap(
        createReactClass({
            displayName: "Header",

            contextTypes: {
                update: PropTypes.func,
                customWeekNames: PropTypes.array,
            },

            getDefaultProps() {
                return {
                    isRemovable: true,
                };
            },

            componentDidMount() {
                this._isMounted = true;
                this.props.setResizeEndCallback(this.onResizeEnd);

                this.handleScroll = ScrollWheelHandler.create({
                    step: (isXAxis, stepIncreased) => {
                        let updated;
                        if (stepIncreased) {
                            updated = this.props.data.increaseFirstVisibleValue();
                        } else {
                            updated = this.props.data.decreaseFirstVisibleValue();
                        }
                        this.context.update(this.props.data, updated);
                    },
                    shouldEventPropagate: () => false,
                });
                this.registerMenu(this.props);
                this._intervalUpdate = window.setInterval(
                    () => this.forceUpdate(),
                    FORCE_UPDATE_INTERVAL_IN_SECONDS
                );
            },

            componentDidUpdate() {
                this.registerMenu(this.props);
            },

            componentWillUnmount() {
                this._isMounted = false;
                this.deregisterMenu();
                window.clearInterval(this._intervalUpdate);
            },

            onResizeEnd() {
                const startCoords = _.getClientPos(this.props.startPosition);
                const lastCoords = _.getClientPos(this.props.lastMousePosition);

                let newSize;
                if (this.props.axis === "y" && this._resizeType === "width") {
                    document.body.classList.remove("col-resizing");
                    newSize = this.props.data.size + (lastCoords.x - startCoords.x);
                    if (newSize < MIN_PIXELS_VERTICAL_WIDTH) {
                        newSize = MIN_PIXELS_VERTICAL_WIDTH;
                    }
                    this.context.update(this.props.data, this.props.data.setSize(newSize));
                    return;
                }

                const prevSize = Math.floor(
                    this.props.size[this._resizeType] / this.props.data.visibleValues
                );
                newSize = prevSize + (lastCoords[this.props.axis] - startCoords[this.props.axis]);
                const length = this.props.data.getValues().length;
                document.body.classList.remove(
                    this.props.axis === "x" ? "col-resizing" : "row-resizing"
                );

                const opts = this.getVisibilityOptions(
                    newSize,
                    length,
                    this.props.data.firstVisibleValue
                );
                const newModel = this.props.data
                    .setVisibleValues(opts.visibleValues)
                    .immutableSet({ firstVisibleValue: opts.firstVisibleValue });
                this.context.update(this.props.data, newModel);
            },

            handleResizeStart(evt) {
                const rect = evt.target.getBoundingClientRect();
                const isVertical = this.props.axis === "y";

                const coords = _.getClientPos(evt);
                const DRAG_TARGET_THRESHOLD_VERTICAL = 8;
                if (isVertical && rect.right - coords.x <= DRAG_TARGET_THRESHOLD_VERTICAL) {
                    document.body.classList.add("col-resizing");
                    this._resizeType = "width";
                    return this.props.onResizeStart(evt);
                }

                if (
                    !isVertical &&
                    coords.x - rect.left > DRAG_TARGET_THRESHOLD &&
                    coords.x - rect.left < rect.width - DRAG_TARGET_THRESHOLD
                ) {
                    return null;
                }

                if (
                    isVertical &&
                    coords.y - rect.top > DRAG_TARGET_THRESHOLD &&
                    coords.y - rect.top < rect.height - DRAG_TARGET_THRESHOLD
                ) {
                    return null;
                }

                this._resizeType = isVertical ? "height" : "width";
                document.body.classList.add(!isVertical ? "col-resizing" : "row-resizing");

                return this.props.onResizeStart(evt);
            },

            calculateCellSizes(props) {
                const prop = props.axis === "x" ? "width" : "height";
                const otherProp = props.axis === "y" ? "width" : "height";
                const model = props.data;

                const size = props.size[prop];
                let numCells = model.visibleValues;

                if (numCells === 0) {
                    return [];
                }

                if (props.isResizing && !(props.axis === "y" && this._resizeType === "width")) {
                    const eventProp = "client".concat(props.axis.toUpperCase());
                    const newSize =
                        Grid.getSizeFromIndexes(0, 1, numCells, size) +
                        (props.lastMousePosition[eventProp] - props.startPosition[eventProp]);
                    const options = this.getVisibilityOptions(
                        newSize,
                        model.length(),
                        model.firstVisibleValue
                    );
                    numCells = options.visibleValues;
                }

                return _.range(0, numCells).map((index) => {
                    const cellSize = _.clone(props.size);
                    cellSize[prop] = Grid.getSizeFromIndexes(index, index + 1, numCells, size);
                    let infoCellSize: any | null = null;

                    if (model.showInfo) {
                        infoCellSize = _.clone(cellSize);
                        infoCellSize[otherProp] = props.axis === "y" ? MIN_Y_SIZE : MIN_X_SIZE;
                        cellSize[otherProp] =
                            cellSize[otherProp] - (props.axis === "y" ? MIN_Y_SIZE : MIN_X_SIZE);
                    }
                    return { cellSize, infoCellSize };
                });
            },

            registerMenu(props) {
                this.deregisterMenu();
                let submenu;

                const menuItems: any[] = [];
                if (props.data.hasInfo()) {
                    menuItems.push(
                        {
                            key: "header.info",
                            label: Language.get("cal_res_side_view_info_header"),
                            checked: () => props.data.showInfo,
                            action: TimeEdit.State.update.bind(null, props.data, {
                                showInfo: !props.data.showInfo,
                            }),
                        },
                        {
                            key: "sep",
                            isSeparator: true,
                        }
                    );
                }

                menuItems.push({
                    key: "header.swap",
                    label: Language.get("nc_cal_header_popup_move_down"),
                    action: props.onHeaderSwap && props.data.subheader ? props.onHeaderSwap : null,
                });

                const self = this;
                let periodSubmenu;
                let availablePeriods;
                if (props.getAvailableHeaders) {
                    submenu = [];
                    const model = props.data;
                    // Add header
                    const available = props.getAvailableHeaders();
                    available.forEach((item) => {
                        submenu.push({
                            key: `header.add${item.name.replace(" ", "")}`,
                            label: item.name,
                            action() {
                                const HeaderType = item.header;
                                let header = new HeaderType();
                                header = header.freeze();
                                header = header.setLimits(props.data.limits);
                                const LIMIT_BEGIN =
                                    MillenniumDate.today().getDayNumber() +
                                    props.data.limits.startDay;
                                const LIMIT_END = props.data.limits.getEndDate().getDayNumber();
                                let _subHeader = model.addSubheader(
                                    header.immutableSet({ firstVisibleValue: 0 })
                                );
                                if (
                                    HeaderType instanceof DateHeader &&
                                    MillenniumDate.today().getDayNumber() >= LIMIT_BEGIN &&
                                    MillenniumDate.today().getDayNumber() < LIMIT_END
                                ) {
                                    _subHeader = model.addSubheader(
                                        header.immutableSet({
                                            firstVisibleValue: -props.data.limits.startDay,
                                        })
                                    );
                                }
                                self.context.update(model, _subHeader);
                            },
                        });
                    });
                    if (this._periods) {
                        // Add period header
                        periodSubmenu = [];
                        availablePeriods = available
                            .filter((item) => item.period)
                            .map((item) => item.period);
                        this._periods.forEach((item) => {
                            if (_.find(availablePeriods, (kind) => kind === item.kind)) {
                                periodSubmenu.push({
                                    key: `header.addPeriod.${item.id}`,
                                    label: item.name,
                                    action() {
                                        API.getHPeriodDefs(item.id, (defs) => {
                                            let header = toPeriodHeader(defs[0]);
                                            header = header.setLimits(props.data.limits);
                                            self.context.update(model, model.addSubheader(header));
                                        });
                                    },
                                });
                            }
                        });
                        submenu.push({
                            key: "header.addPeriod",
                            label: Language.get("cal_header_kind_period"),
                            submenu: periodSubmenu,
                        });
                    }

                    if (props.data.isContinuous !== true) {
                        menuItems.push({
                            key: "header.add",
                            label: Language.get("cal_header_popup_add_header"),
                            submenu,
                        });
                    }

                    // Change header
                    submenu = [];
                    props.getAvailableHeaders(model).forEach((item) => {
                        submenu.push({
                            key: `header.change${item.name.replace(" ", "")}`,
                            label: item.name,
                            action() {
                                const HeaderType = item.header;
                                let header = new HeaderType();
                                header.isActive = props.data.isActive;
                                if (header.hasInfo()) {
                                    header.showInfo = props.data.showInfo;
                                }
                                header.subheader = props.data.subheader;
                                header = header.freeze();
                                header = header.setLimits(props.data.limits);
                                props.onHeaderChange(header);
                            },
                        });
                    });

                    if (this._periods) {
                        // Change period
                        periodSubmenu = [];
                        availablePeriods = props
                            .getAvailableHeaders(model, true)
                            .filter((item) => item.period)
                            .map((item) => item.period);
                        this._periods.forEach((item) => {
                            if (_.find(availablePeriods, (kind) => kind === item.kind)) {
                                periodSubmenu.push({
                                    key: `header.changePeriod.${item.id}`,
                                    label: item.name,
                                    action() {
                                        API.getHPeriodDefs(item.id, (defs) => {
                                            let header = toPeriodHeader(defs[0]);
                                            header = header.immutableSet({
                                                isActive: props.data.isActive,
                                                subheader: props.data.subheader,
                                                visibleValues:
                                                    header.length() >= props.data.visibleValues
                                                        ? props.data.visibleValues
                                                        : header.length(),
                                            });
                                            if (header.hasInfo()) {
                                                header = header.immutableSet({
                                                    showInfo: props.data.showInfo,
                                                });
                                            }
                                            header = header.setLimits(props.data.limits);
                                            props.onHeaderChange(header);
                                        });
                                    },
                                });
                            }
                        });
                        submenu.push({
                            key: "header.changePeriod",
                            label: Language.get("cal_header_kind_period"),
                            submenu: periodSubmenu,
                        });
                    }

                    menuItems.push({
                        key: "header.change",
                        label: Language.get("cal_header_popup_change_header"),
                        submenu,
                    });
                }

                if (!this._periods) {
                    API.findHPeriods((periods) => {
                        this._periods = periods;
                        this.registerMenu(props);
                    });
                }

                if (props.isRemovable) {
                    menuItems.push({
                        key: "header.remove",
                        label: Language.get("cal_header_popup_remove_header"),
                        action() {
                            props.onHeaderChange(null);
                        },
                    });
                }

                if (this._isMounted) {
                    const listenerId = ContextMenu.addListener(
                        ReactDOM.findDOMNode(this),
                        menuItems,
                        ContextMenu.RIGHT_CLICK
                    );
                    this.setState({ listenerId });
                }
            },

            removeObject(object) {
                const self = this;
                this.props.data.removeObject(object, (newHeader) => {
                    self.context.update(self.props.data, newHeader);
                });
            },

            toggleSideBySide(object) {
                this.context.update(
                    this.props.data,
                    this.props.data.toggleSideBySideForValue(object)
                );
            },

            handleSwapHeaders() {
                const model = this.props.data;
                this.context.update(model, model.swapHeader());
            },

            handleSetSubheader(newSubheader) {
                this.context.update(this.props.data, this.props.data.setSubheader(newSubheader));
            },

            deregisterMenu() {
                if (this.state && this.state.listenerId) {
                    ContextMenu.removeListener(this.state.listenerId);
                }
            },

            getVisibilityOptions(partialSize, length, firstVisibleValue) {
                const prop = this.props.axis === "x" ? "width" : "height";
                let newVisibleValues = Math.floor(this.props.size[prop] / partialSize);
                const maxVisibleValues = Math.floor(this.props.size[prop] / MIN_PIXELS_PER_CELL);
                if (newVisibleValues > length || newVisibleValues < 0) {
                    newVisibleValues = length;
                }
                if (newVisibleValues > maxVisibleValues) {
                    newVisibleValues = maxVisibleValues;
                }
                if (newVisibleValues < 1) {
                    newVisibleValues = 1;
                }

                let newFirstVisibleValue = firstVisibleValue;
                if (newFirstVisibleValue + newVisibleValues > length) {
                    newFirstVisibleValue = length - newVisibleValues;
                }

                return {
                    visibleValues: newVisibleValues,
                    firstVisibleValue: newFirstVisibleValue,
                };
            },

            getLabelSize(size) {
                const XS = 30;
                const S = 40;
                const M = 60;
                const L = 120;
                if (size <= MIN_PIXELS_PER_CELL) {
                    return null;
                }
                if (size < XS) {
                    return Size.XS;
                }
                if (size < S) {
                    return Size.S;
                }
                if (size < M) {
                    return Size.M;
                }
                if (size < L) {
                    return Size.L;
                }
                return Size.XL;
            },

            handleDrop(evt) {
                if (
                    !_.isEventDragDataOfType(evt, [
                        "application/x-timeedit-type",
                        "application/x-timeedit-object",
                    ])
                ) {
                    return;
                }

                const dataType = _.isEventDragDataOfType(evt, "application/x-timeedit-type")
                    ? "application/x-timeedit-type"
                    : "application/x-timeedit-object";
                let data = _.getDragData(evt.nativeEvent, dataType);
                data = JSON.parse(data);
                evt.stopPropagation();

                const updateHeader = function (header, cb) {
                    if (dataType === "application/x-timeedit-type") {
                        header.setType(data, cb);
                    } else {
                        header.addObject(data, cb);
                    }
                };

                if (this.props.data instanceof ObjectHeader) {
                    updateHeader(this.props.data, (newHeader) => {
                        this.context.update(this.props.data, newHeader);
                    });
                    return;
                }

                const header = new ObjectHeader();
                header.limits = this.props.data.limits;

                if (this.props.data.isContinuous === true) {
                    header.subheader = this.props.data;
                    updateHeader(DataStore.deepFreeze(header), (newHeader) => {
                        this.context.update(this.props.data, newHeader);
                    });
                    return;
                }

                header.subheader = this.props.data.subheader;
                updateHeader(DataStore.deepFreeze(header), (newHeader) => {
                    this.context.update(this.props.data, this.props.data.addSubheader(newHeader));
                });
            },

            handleDragOver(evt) {
                if (
                    _.isEventDragDataOfType(evt, [
                        "application/x-timeedit-object",
                        "application/x-timeedit-type",
                    ])
                ) {
                    evt.preventDefault();
                }
            },

            handleSetActiveHeader(evt) {
                this.props.onActiveHeaderChange(this.props.data);
                evt.stopPropagation();
            },

            render() {
                const self = this;
                const prop = this.props.axis === "x" ? "width" : "height";
                const otherProp = this.props.axis === "y" ? "width" : "height";
                const model = this.props.data;

                const cells: any[] = [];
                const defaultSize = Math.floor(this.props.size[prop] / model.visibleValues);

                let cellValues = model.getVisibleValues();

                const FIFTY = 50;
                const THIRTY = 30;
                const hasSubheader = model.subheader && !model.subheader.hide;
                const headerClasses = {
                    header: true,
                    xHeader: this.props.axis === "x",
                    yHeader: this.props.axis === "y",
                    active: model.isActive,
                    hasSubheader,
                    noSubheader: !hasSubheader,
                    hasManyCells:
                        defaultSize <= FIFTY || (this.props.axis === "y" && defaultSize <= THIRTY),
                    objectHeader: model instanceof ObjectHeader,
                };

                if (
                    this.props.isResizing &&
                    !(this.props.axis === "y" && this._resizeType === "width")
                ) {
                    const eventProp = "client".concat(this.props.axis.toUpperCase());
                    let newSize =
                        defaultSize +
                        (this.props.lastMousePosition[eventProp] -
                            this.props.startPosition[eventProp]);
                    const values = model.getValues();
                    const options = this.getVisibilityOptions(
                        newSize,
                        model.length(),
                        model.firstVisibleValue
                    );
                    newSize = Math.floor(this.props.size[prop] / options.visibleValues);
                    cellValues = values.slice(
                        options.firstVisibleValue,
                        options.firstVisibleValue + options.visibleValues
                    );
                    headerClasses.hasManyCells =
                        newSize <= FIFTY || (this.props.axis === "y" && newSize <= THIRTY);
                }

                const infoCells: any[] = [];
                const cellSizes = this.calculateCellSizes(this.props, this.state);

                let infoCellSizeCounter = 0;
                const infoWidths: any[] = [];
                const labelSize =
                    cellValues.length > 0 ? this.getLabelSize(cellSizes[0].cellSize.width) : 0;

                cellValues.forEach((el, index) => {
                    const cellSize = cellSizes[index].cellSize;
                    infoCellSizeCounter = infoCellSizeCounter + cellSize[prop];

                    const minSize = this.props.axis === "x" ? MIN_X_SIZE : MIN_Y_SIZE;
                    const size = _.clone(cellSize);
                    size[otherProp] =
                        !this.props.data.size || this.props.data.size < minSize
                            ? minSize
                            : this.props.data.size;

                    const text =
                        labelSize !== null
                            ? model.getLabel(
                                  el,
                                  labelSize,
                                  index,
                                  true,
                                  this.context.customWeekNames
                              )
                            : "";
                    const title =
                        labelSize === Size.XL
                            ? text
                            : model.getLabel(
                                  el,
                                  Size.XL,
                                  index,
                                  true,
                                  this.context.customWeekNames
                              );

                    let infoLabelSize = labelSize;
                    if (this.props.axis === "x") {
                        infoLabelSize = this.getLabelSize(size.width * model.getCellsPerInfoCell());
                    }

                    if (model.showInfo) {
                        let infoText = model.getInfo(
                            el,
                            infoLabelSize || Size.XS,
                            this.context.customWeekNames,
                            this.props.providers
                        );

                        if (
                            infoText &&
                            this.props.info &&
                            this.props.info.length > 0 &&
                            index === 0
                        ) {
                            const calendarInfo = this.props.info.reduce((items, item, i) => {
                                if (i === 0) {
                                    return items.concat(item);
                                }
                                return items.concat([", ", item]);
                            }, []);
                            infoText = (
                                <span>
                                    [{calendarInfo}] {infoText}
                                </span>
                            );
                        }
                        const infoRowStyle = _.clone(cellSizes[index].infoCellSize);
                        if (this.props.axis === "x") {
                            infoRowStyle.lineHeight = `${infoRowStyle.height}px`;
                        }
                        if (this.props.axis === "y" && model.getCellsPerInfoCell() > 1) {
                            infoRowStyle.overflow = "visible";
                        } else {
                            infoRowStyle.overflow = "hidden";
                        }
                        if (
                            (infoCellSizeCounter >= MIN_PIXELS_PER_INFO_CELL && infoText) ||
                            index === 0
                        ) {
                            infoCells.push({ key: index, text: infoText, style: infoRowStyle });
                            infoWidths.push(infoCellSizeCounter);
                            infoCellSizeCounter = 0;
                        }
                    }

                    let subheader: React.ReactElement | null = null;
                    if (model.subheader && !model.subheader.hide) {
                        const subheaderSize = _.clone(cellSize);
                        subheaderSize[otherProp] =
                            subheaderSize[otherProp] -
                            (!this.props.data.size || this.props.data.size < minSize
                                ? minSize
                                : this.props.data.size);

                        subheader = (
                            <Header
                                axis={self.props.axis}
                                data={model.subheader}
                                onHeaderSwap={this.handleSwapHeaders}
                                onHeaderChange={this.handleSetSubheader}
                                getAvailableHeaders={this.props.getAvailableHeaders}
                                onActiveHeaderChange={this.props.onActiveHeaderChange}
                                size={subheaderSize}
                                onDateClick={this.props.onDateClick}
                            />
                        );
                    }
                    const cellMenuItems: any[] = [];
                    if (model instanceof ObjectHeader) {
                        cellMenuItems.push({
                            key: "cell.remove",
                            label: Language.get("cal_header_popup_object_remove"),
                            action: this.removeObject.bind(this, el),
                        });
                    }

                    cellMenuItems.push({
                        key: "cell.sideBySide",
                        label: Language.get("nc_side_by_side_details"),
                        action: this.toggleSideBySide.bind(this, el),
                    });

                    let onClick;
                    if (model instanceof DateHeader) {
                        onClick = () => {
                            this.props.onDateClick(el);
                        };
                    }

                    const toClassName = (lSize) => {
                        if (lSize === Size.XS) {
                            return "XSMALL";
                        }
                        if (lSize === Size.S) {
                            return "SMALL";
                        }
                        if (lSize === Size.M) {
                            return "MEDIUM";
                        }
                        if (lSize === Size.L) {
                            return "LARGE";
                        }
                        if (lSize === Size.XL) {
                            return "XLARGE";
                        }
                        return "MEDIUM";
                    };

                    const classes = {};
                    classes[toClassName(labelSize)] = true;

                    cells.push(
                        <Cell
                            classNames={classes}
                            key={index}
                            text={text}
                            title={title}
                            axis={self.props.axis}
                            size={size}
                            subheader={subheader}
                            menuItems={cellMenuItems}
                            isCurrent={model.isCurrent(el, this.props.currentDateTime)}
                            onClick={onClick}
                        />
                    );
                }, this);

                let infoRow: React.ReactElement | null = null;
                if (model.showInfo) {
                    infoWidths.push(infoCellSizeCounter);
                    const size = _.clone(this.props.size);
                    size[otherProp] = this.props.axis === "x" ? MIN_X_SIZE : MIN_Y_SIZE;
                    infoRow = (
                        <div className="infoRow" style={size}>
                            {infoCells.map(({ key, text, style }, index) => {
                                // eslint-disable-next-line no-param-reassign
                                style[prop] = infoWidths[index + 1];
                                return <InfoRow key={key} text={text} style={style} />;
                            })}
                        </div>
                    );
                }

                if (this.props.axis === "x") {
                    const size = _.clone(this.props.size);
                    size[otherProp] = this.props.axis === "x" ? MIN_X_SIZE : MIN_Y_SIZE;
                    return (
                        <div
                            className={_.classSet(headerClasses)}
                            style={this.props.size}
                            onClick={this.handleSetActiveHeader}
                            onTouchStart={_.handlePreventDefault}
                            onMouseDown={this.handleResizeStart}
                            onDragOver={this.handleDragOver}
                            onDrop={this.handleDrop}
                        >
                            {infoRow}
                            <div
                                className="cells"
                                style={size}
                                onWheel={this.handleScroll}
                                onTouchMove={this.handleScroll}
                            >
                                {cells}
                            </div>
                        </div>
                    );
                }
                return (
                    <div
                        className={_.classSet(headerClasses)}
                        style={this.props.size}
                        onClick={this.handleSetActiveHeader}
                        onTouchStart={_.handlePreventDefault}
                        onMouseDown={this.handleResizeStart}
                        onDragOver={this.handleDragOver}
                        onDrop={this.handleDrop}
                    >
                        {infoRow}
                        <div
                            className="cells"
                            onWheel={this.handleScroll}
                            onTouchMove={this.handleScroll}
                        >
                            {cells}
                        </div>
                    </div>
                );
            },

            shouldComponentUpdate(nextProps) {
                return (
                    nextProps.data !== this.props.data ||
                    nextProps.axis !== this.props.axis ||
                    !_.isEqual(nextProps.size, this.props.size) ||
                    nextProps.isResizing !== this.props.isResizing ||
                    nextProps.info !== this.props.info ||
                    !_.isEqual(this.props.lastMousePosition, nextProps.lastMousePosition) ||
                    !_.isEqual(this.props.startPosition, nextProps.startPosition) ||
                    !_.isEqual(nextProps.currentDateTime, this.props.currentDateTime)
                );
            },
        })
    ),
    { MIN_X_SIZE, MIN_Y_SIZE }
);

export default Header;
