import StaticReservationList from "./StaticReservationList";
import Language from "../lib/Language";
import _ from "underscore";

import ColumnConstants from "../lib/ColumnConstants";

const { getMessageColumn } = ColumnConstants;

const listSummary = (isFailureList, number) => {
    let langKey;
    if (isFailureList) {
        langKey = "nc_mass_move_x_changes_not_performed";
    } else {
        langKey = "nc_mass_move_x_changes_performed";
    }
    return Language.get(langKey, number);
};

const getIconClass = (isFailureList) => {
    if (isFailureList) {
        return "resultListIcon failures";
    }
    return "resultListIcon successes";
};

const MassChangeResultList = (props) => {
    const messageColumn = getMessageColumn();
    const listDiv = !props.isExpanded ? null : (
        <div key="body" className="accordionSection">
            <StaticReservationList
                height={"200px"}
                onSelect={_.noop}
                onSearchSettingsChange={_.noop}
                defaultSelectedColumns={props.defaultSelectedColumns.concat(messageColumn.id)}
                columns={props.isFailureList ? props.columns.concat(messageColumn) : props.columns}
                reservations={props.reservations}
                messages={props.isFailureList ? props.messages : []}
            />
        </div>
    );
    return (
        <div className="accordion active">
            <h3 key="header" onClick={() => props.onHeaderClick(props.listState)}>
                <div className={getIconClass(props.isFailureList)} />
                {listSummary(props.isFailureList, props.reservations.length)}
            </h3>
            {listDiv}
        </div>
    );
};

export default MassChangeResultList;
