import PropTypes from "prop-types";
import React from "react";
import Language from "../lib/Language";
import ReservationInfo from "./ReservationInfo";
import { Reservation } from "../models/Reservation";
import { Macros } from "../models/Macros";
import _ from "underscore";

class ReservationPane extends React.Component {
    static contextTypes = {
        update: PropTypes.func,
        user: PropTypes.object,
        fireEvent: PropTypes.func,
        presentModal: PropTypes.func,
        useNewReservationGroups: PropTypes.bool,
    };

    state = {};

    setTitle = (title) => {
        this.setState({ title });
    };

    getTitle = () => {
        if (this.state.title) {
            return this.state.title;
        }

        const reservationIds = this.props.reservationIds;
        if (reservationIds.length === 1) {
            return `${Language.get("cal_func_res_reservation")} #${reservationIds[0]}`;
        }

        if (reservationIds.length === 0 || !this.props.isEntryInfo) {
            return Language.get("cal_res_side_tab_res_info");
        }

        return Language.get("period_prefs_cluster");
    };

    onDelete = () => {
        const reservationId = this.props.reservationIds;

        // eslint-disable-next-line no-shadow
        const removeEntry = (reservationId) => {
            Reservation.cancel(
                reservationId,
                (wasSuccessful) => {
                    if (!wasSuccessful) {
                        return;
                    }

                    this.props.onEntryInfoOpen(null);
                    this.context.fireEvent("menu", Macros.Event.RESERVATION_MADE_OR_MODIFIED, []);
                },
                this.context.useNewReservationGroups
            );
        };

        const onRemember = () => {
            const user = this.context.user.immutableSet({ showConfirmEntryRemovalWarning: false });
            this.context.update(this.context.user, user);
        };

        Reservation.get(reservationId, (reservation) => {
            if (_.isEqual(reservation[0].createdby, this.context.user.userId)) {
                removeEntry(reservationId);
            } else {
                if (this.context.user.showConfirmEntryRemovalWarning) {
                    const yesButton = {
                        title: Language.get("dialog_yes"),
                        cb: () => {
                            removeEntry(reservationId);
                        },
                        remember: true,
                        value: false,
                    };
                    const noButton = { title: Language.get("dialog_no"), remember: false };
                    const buttons = [yesButton, noButton];
                    this.context.presentModal(
                        <div>{<p>{Language.get("nc_confirm_entry_removal_message")}</p>}</div>,
                        "confirm_entry_removal",
                        Language.get("nc_confirm_entry_removal_title"),
                        buttons,
                        onRemember
                    );
                } else {
                    removeEntry(reservationId);
                }
            }
        });
    };

    onUpdate = (ids = this.props.reservationIds) => {
        if (ids) {
            this.context.fireEvent("menu", Macros.Event.RESERVATION_MADE_OR_MODIFIED, ids);
        }
    };

    render() {
        const classNames = ["reservationPane"];
        if (this.props.dynamic === true) {
            classNames.push("fixToBottom");
        }
        const validIds = this.props.reservationIds.filter(
            (id) => id !== null && id !== undefined && id > 0
        );
        if (validIds.length === 0) {
            return null;
        }
        return (
            <div className={classNames.join(" ")}>
                <div style={{ display: "block" }}>
                    <div className="dismiss" onClick={this.props.onClose} />
                    <h2>{this.getTitle()}</h2>
                </div>
                {this._renderContent(validIds)}
            </div>
        );
    }

    _renderContent = (validIds) => {
        if (validIds.length > 0) {
            return (
                <ReservationInfo
                    {...this.props}
                    onDelete={this.onDelete}
                    onUpdate={this.onUpdate}
                    reservationIds={this.props.reservationIds}
                    dynamic={this.props.dynamic}
                    isEditMode={this.props.isInfoEntryEdited}
                    onReservationEditChange={this.props.onReservationEditChange}
                    setTitle={this.setTitle}
                    onObjectInfo={this.props.onObjectInfo}
                    onEntryInfoOpen={this.props.onEntryInfoOpen}
                />
            );
        }

        return <p>{Language.get("nc_menu_reservation_not_selected")}</p>;
    };
}

export default ReservationPane;
