import React from "react";

class IntegerInput extends React.Component {
    state = {
        value: this.props.defaultValue,
    };

    componentDidUpdate(prevProps) {
        if (this.props.defaultValue !== prevProps.defaultValue) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ value: this.props.defaultValue });
        }
    }

    getValue = () => {
        const val = parseInt(this.state.value, 10);
        if (isNaN(val)) {
            return this.props.defaultValue;
        }
        return val;
    };

    returnValue = () => {
        const val = this.getValue();
        this.setState({ value: val });
        this.props.onUpdate(val);
    };

    onChange = (evt) => {
        this.setState({ value: evt.target.value });
    };

    onKeyDown = (evt) => {
        if (evt.key !== "ArrowDown" && evt.key !== "ArrowUp" && evt.key !== "Enter") {
            return;
        }

        const val = this.getValue();
        if (evt.key === "ArrowUp") {
            this.setState({ value: val + 1 });
        } else if (evt.key === "ArrowDown") {
            this.setState({ value: val - 1 });
        } else if (evt.key === "Enter") {
            this.returnValue();
            evt.preventDefault();
        }

        evt.stopPropagation();
    };

    render() {
        const disabled = this.props.disabled || false;
        const className = this.props.classNames || "";
        return (
            <input
                className={className}
                disabled={disabled}
                type="text"
                pattern="[\-0-9]*"
                value={this.state.value}
                onChange={this.onChange}
                onBlur={this.returnValue}
                onKeyDown={this.onKeyDown}
            />
        );
    }
}

export default IntegerInput;
