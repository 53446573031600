import { Model } from "./Model";
import { Selection } from "./Selection";

export class App extends Model {
    menu: any;
    view: any;
    user: any;
    selection: any;
    _transient: string[];

    constructor(menu?, view?, user?) {
        super("App");
        this.menu = menu || null;
        this.view = view || null;
        this.user = user || null;
        this.selection = new Selection();
        this._transient = ["menu"];
    }

    setView(view: any) {
        return this.immutableSet({ view });
    }

    asProps() {
        // eslint-disable-next-line no-unused-vars
        const { _transient, _typeName, _modelId, ...props } = this; // jshint ignore:line
        return props;
    }
}
