import { Header } from "./Header";

export class IndexHeader extends Header {
    constructor(visibleValues, firstVisibleValue?, subheader?) {
        super(visibleValues, firstVisibleValue || 0, subheader, "IndexHeader");
    }
    // eslint-disable-next-line no-unused-vars
    indexOf(entry, onlyVisible) {
        return entry.overlappingEntryIndex;
    }

    lastIndexOf(entry, onlyVisible) {
        return this.indexOf(entry, onlyVisible) + 1;
    }

    getValues() {
        return [{}];
    }

    // eslint-disable-next-line no-unused-vars
    getLabel(obj, size) {
        return obj.label || "";
    }
}
