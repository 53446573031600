import React from "react";

// eslint-disable-next-line react/prefer-stateless-function
class InfoRow extends React.Component {
    render() {
        let classes = ["active"];
        if (!this.props.text) {
            classes = ["inactive"];
        }
        classes.push("info");

        return (
            <div className={classes.join(" ")} style={this.props.style}>
                {this.props.text}
            </div>
        );
    }
}

export default InfoRow;
