import { MillenniumWeek, SimpleDateFormat } from "@timeedit/millennium-time";
import { PeriodHeader } from "./PeriodHeader";
import Language from "../lib/Language";
import _ from "underscore";

export class DatePeriodHeader extends PeriodHeader {
    isWeekPeriod: boolean;
    weeks: any[];
    futureWeeksOnly: boolean;
    _filteredValues: any;

    constructor(visibleValues, firstVisibleValue, subheader?) {
        super(visibleValues || 1, firstVisibleValue, subheader, "DatePeriodHeader");
        this.isWeekPeriod = false;
        this.weeks = [];
        this.futureWeeksOnly = false;
    }

    getKind() {
        return PeriodHeader.KIND.DATE;
    }

    getValues() {
        if (this.weeks.length === 0) {
            return this.values;
        }

        return this._filteredValues;
    }

    getLabel(value, size, index?, onlyVisible = false) {
        if (index === undefined) {
            throw new Error(
                "DatePeriodHeader requires the index of the sought period value to get its label."
            );
        }

        const absoluteIndex = onlyVisible ? index + this.firstVisibleValue : index;
        const label = this.names[absoluteIndex];

        return `(${value.length}) ${label}`;
    }

    getInfo(values, size?, customWeekNames = []) {
        if (!this.isWeekPeriod || values.length === 0) {
            return null;
        }

        const allWeeks = this.getWeeks(customWeekNames);
        const selectedWeeks = _.filter(allWeeks, (week) => week.selected);
        const weekItems = _.map(selectedWeeks, (week) => week.value);

        const weekInfo = weekItems || _.map(allWeeks, (week) => week.value);
        const weekText =
            customWeekNames.length > 0
                ? (selectedWeeks || allWeeks).map((week) => week.label).join(", ")
                : MillenniumWeek.toString(weekInfo);
        const headerText = `${this.name} (${weekText})`;

        const firstVisible = this.values[this.firstVisibleValue];
        if (values === firstVisible) {
            return headerText;
        }

        const firstVisibleContainsAllValues = _.every(values, (value) =>
            _.some(firstVisible, (item) => value.equals(item))
        );
        return firstVisibleContainsAllValues ? headerText : null;
    }

    getWeeks(customWeekNames = []) {
        const allDates = _.flatten(this.values);
        const allWeeks = _.uniq(
            allDates.map((date) =>
                date.getMillenniumWeek(Language.firstDayOfWeek, Language.daysInFirstWeek)
            ),
            false,
            (week) => week.week(true)
        );
        const allWeeksSorted = _.sortBy(allWeeks, (week) => week.week(true));

        return allWeeksSorted.map((week) => {
            const label =
                getCustomWeekName(week, customWeekNames) ||
                SimpleDateFormat.format(
                    week.getStartOfWeek(),
                    Language.getDateFormat("date_f_yyyy_ww_l")
                );
            return {
                value: week,
                label,
                selected:
                    this.weeks.length === 0 ||
                    this.weeks.some((filterWeek) => week.week(true) === filterWeek.week(true)),
            };
        });
    }

    getIndexOfDate(date, onlyVisible = false) {
        const soughtDay = date.getDayNumber();
        const values = onlyVisible ? this.getVisibleValues() : this.getValues();
        return values.findIndex((dates) => dates.some((dt) => dt.getDayNumber() === soughtDay));
    }

    setWeeks(weeks) {
        const values = getFilteredValues(weeks, this.values);
        return this.immutableSet({
            weeks,
            _filteredValues: values,
        });
    }

    // eslint-disable-next-line no-unused-vars
    getSettings(providers) {
        // eslint-disable-line no-unused-vars
        const settings = super.getSettings();

        if (!this.isWeekPeriod) {
            return settings;
        }

        const self = this;
        settings.items.push({
            id: "weeks",
            label: Language.get("cal_list_weeks"),
            type: "array",
            limit: 0,
            get: self.getWeeks.bind(self),
            set(val) {
                const allWeeks = self.getWeeks();
                if (allWeeks.length === val.length) {
                    return self.setWeeks([]);
                }

                return self.setWeeks(val);
            },
        });

        return settings;
    }

    getSimplePeriodIndex(entry, onlyVisible = false) {
        if (!this.isSimplePeriod()) {
            throw new Error("Cannot use getSimplePeriodIndex if period is not a simple period.");
        }

        const isMatch = (value) => value.equals(entry.startTimes[0].getMillenniumDate());
        const index = _.flatten(this.getValues()).findIndex(isMatch);
        if (!onlyVisible) {
            return index;
        }
        return index - this.firstVisibleValue;
    }

    periodsToJSON() {
        return _.flatten(
            this.getValues().map((dates) => {
                if (dates.length > 0) {
                    return dates.map((date) => date.getDayNumber());
                }
                return [null];
            })
        );
    }

    toJSON() {
        const json = super.toJSON();
        return _.extend(json, {
            dayProvider: true,
            kind: "perioddate",
            periods: this.values.map((dates) => dates.map((date) => date.getDayNumber())),
            weeks: this.weeks.map((week) => `20${week.week()}`),
            futureWeeksOnly: this.futureWeeksOnly,
        });
    }
}

const getCustomWeekName = (week, customWeekNames) => {
    const name = _.find(
        customWeekNames,
        (customWeek) => customWeek.dayNumber === week.date.dayNumber
    );
    if (name) {
        return name.getLongestName();
    }
    return null;
};

const getFilteredValues = (weeks, values) => {
    const weekNumbers = weeks.map((week) => week.week(true));
    const isDateInWeekList = (date) =>
        _.contains(
            weekNumbers,
            date.getWeek(true, Language.firstDayOfWeek, Language.daysInFirstWeek)
        );

    return values.map((dates) => dates.filter(isDateInWeekList));
};
