import PropTypes from "prop-types";
import React from "react";
import RequestList from "./RequestList";
import ListCalendar from "./ListCalendar";
import { Macros } from "../models/Macros";

class RequestListCalendar extends React.Component {
    static contextTypes = {
        fireEvent: PropTypes.func,
    };

    onSelection = (selection) => {
        this.context.fireEvent(
            `reservationList${this.props.id}`,
            Macros.Event.SELECT_REQUEST,
            selection
        );
        this.props.onInfoOpen([selection.id], false);
    };

    getRefreshEvents = () => [
        Macros.Event.SELECT_RESERVATION,
        Macros.Event.RESERVATION_MADE_OR_MODIFIED,
    ];

    render() {
        return (
            <ListCalendar
                {...this.props}
                type={"requestList"}
                onSelection={this.onSelection}
                refreshEvents={this.getRefreshEvents()}
                settingsRef={(node) => {
                    this._settingsButton = node;
                }}
                listRef={(node) => {
                    this._wrapper = node;
                }}
            >
                <RequestList />
            </ListCalendar>
        );
    }
}

export default RequestListCalendar;
