import React from "react";
import ReservationListSettings from "./ReservationListSettings";
import Language from "../lib/Language";
import _ from "underscore";
import TemplateKind from "../models/TemplateKind";
import RC from "../lib/ReservationConstants";
import ReservationTypeList from "./ReservationTypeList";
import API from "../lib/TimeEditAPI";

const COL_IDS = {
    ID: 0,
    LENGTH: 10,
    TYPES: 3,
};

const getAvailableColumns = () => [
    { name: Language.get("cal_reservation_list_column_id"), sortable: true, id: COL_IDS.ID },
    {
        name: Language.get("cal_reservation_list_column_length"),
        sortable: false,
        id: COL_IDS.LENGTH,
    },
    {
        name: Language.get("cal_reservation_list_column_type_all"),
        sortable: false,
        id: COL_IDS.TYPES,
    },
    { name: Language.get("cal_reservation_list_column_field_all"), sortable: false, id: 4 },
    { name: Language.get("cal_reservation_list_column_created"), sortable: true, id: 5 },
    { name: Language.get("cal_reservation_list_column_modified"), sortable: true, id: 6 },
    { name: Language.get("cal_reservation_list_column_status"), sortable: false, id: 11 },
    { name: Language.get("nc_reservation_exception_title"), sortable: false, id: 12 },
];

class WaitingList extends React.Component {
    state = {
        searchOptions: {
            templateKind: TemplateKind.RESERVATION,
            searchString: "",
            allReservations: true,
            reservationStatus: [
                RC.STATUS.CONFIRMED,
                RC.STATUS.PRELIMINARY,
                RC.STATUS.PLANNED,
                RC.STATUS.REQUESTED,
                RC.STATUS.REJECTED,
            ],
            includeMembers: RC.MEMBERS.EXCLUDE,
            useTemplateGroup: false,
            templateGroup: 0,
            reservationVariant: RC.VARIANT.RESERVATION,
            complete: true,
            incomplete: true,
            permission: RC.PERMISSION.READ,
            objectCategories: [],
            reservationCategories: [],
            placeholderType: null,
        },
        availableReservationCategories: [],
        availableReservationCheckboxes: [],
        availableObjectCategories: [],
        availableObjectCheckboxes: [],
        placeholderType: null,
    };

    componentDidMount() {
        API.getObjectCategories(this.setState.bind(this));
    }

    onSearchSettingsChange = (settings, cb) => {
        this.setState({ searchOptions: _.extend({}, this.state.searchOptions, settings) }, cb);
    };

    limitTo = (categories, allowedCategories) => {
        const result = _.omit(
            categories,
            (value, category) =>
                !_.some(
                    allowedCategories,
                    (allowedCategory) =>
                        String(allowedCategory.id) === category &&
                        _.every(value, (val) => _.contains(allowedCategory.categories, val))
                )
        );
        return result;
    };

    getSettingsComponent = (
        saveDefaultSettings,
        saveSettings,
        getSavedSettings,
        removeSettings
    ) => {
        const opts = this.state.searchOptions;

        return (
            <ReservationListSettings
                prefix={"WL"}
                onChange={this.onSearchSettingsChange}
                allReservations={opts.allReservations}
                reservationStatus={opts.reservationStatus}
                includeMembers={opts.includeMembers}
                saveDefaultSettings={saveDefaultSettings}
                saveSettings={saveSettings}
                removeSettings={removeSettings}
                getSavedSettings={getSavedSettings}
                templateKind={opts.templateKind}
                complete={opts.complete}
                incomplete={opts.incomplete}
                permission={opts.permission}
                useTemplateGroup={opts.useTemplateGroup}
                objectCategories={this.limitTo(
                    opts.objectCategories,
                    this.state.availableObjectCategories.concat(
                        this.state.availableObjectCheckboxes
                    )
                )}
                reservationCategories={this.limitTo(
                    opts.reservationCategories,
                    this.state.availableReservationCategories.concat(
                        this.state.availableReservationCheckboxes
                    )
                )}
                availableReservationCategories={this.state.availableReservationCategories}
                availableReservationCheckboxes={this.state.availableReservationCheckboxes}
                availableObjectCategories={this.state.availableObjectCategories}
                availableObjectCheckboxes={this.state.availableObjectCheckboxes}
                placeholderType={opts.placeholderType}
            />
        );
    };

    render() {
        const classes = {
            waitingList: true,
        };

        return (
            <ReservationTypeList
                {...this.props}
                allowMultiSelection={true}
                supportsBatchOperations={true}
                onSearchSettingsChange={this.onSearchSettingsChange}
                searchOptions={this.state.searchOptions}
                defaultStatus={RC.STATUS.WAITING_LIST}
                columns={getAvailableColumns()}
                defaultSelectedColumns={[COL_IDS.ID, COL_IDS.LENGTH, COL_IDS.TYPES]}
                getSettingsComponent={this.getSettingsComponent}
                showSearchInterval={false}
                classes={classes}
                title={Language.get("dynamic_reserv_list_reserv_wl_wl_title")}
                addTypeColumns={TemplateKind.equals(
                    TemplateKind.RESERVATION,
                    this.state.searchOptions.templateKind
                )}
            />
        );
    }
}

export default WaitingList;
