import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import ObjectSelect from "./ObjectSelect";
import SelectionList from "./SelectionList";
import TemplateKind from "../models/TemplateKind";
import ArrayInput from "./ArrayInput";
import WeekList from "./WeekList";
import TextDisplay from "./TextDisplay";
import Mousetrap from "@timeedit/mousetrap";
import Language from "../lib/Language";
import API from "../lib/TimeEditAPI";
import _ from "underscore";
import Popover from "./Popover";
import ObjectInfo from "./ObjectInfo";
import GroupInformationPane from "./GroupInformationPane";
import Log from "../lib/Log";
import { MillenniumDateTime } from "@timeedit/millennium-time";
import SelectionListFieldEditor from "./SelectionListFieldEditor";
import { TimeConstants as TC } from "../lib/TimeConstants";
import TrackPane from "./TrackPane";
import { McFluffy } from "../models/McFluffy";
import { OPERATION_TYPES } from "./preferences/PrefsCoreAPI";
import { EntryKind } from "../lib/EntryConstants";
import { Calendar } from "../models/Calendar";
import { ObjectHeader } from "../models/ObjectHeader";
import { Macros } from "../models/Macros";
import { MemberData } from "../models/MemberData";
import { Reservation } from "../models/Reservation";
import { Selection } from "../models/Selection";

const SEPARATOR_WIDTH = 10;
const LIST_PADDING = 8;
const PANE_RIGHT_PADDING = 4;
const PROVIDER_WIDTH = 170;
const NUM_PROVIDERS = 2;
const HELP_WIDTH = PROVIDER_WIDTH * NUM_PROVIDERS;
const NARROW_WIDTH = 1024;
const MIN_GROUP_PANE_WIDTH = 300;

class SelectionPane extends React.Component {
    static contextTypes = {
        update: PropTypes.func,
        user: PropTypes.object,
        fireEvent: PropTypes.func,
        presentModal: PropTypes.func,
        registerMacro: PropTypes.func,
        deregisterMacro: PropTypes.func,
        customWeekNames: PropTypes.array,
        useNewReservationGroups: PropTypes.bool,
    };

    constructor(props, context) {
        super(props, context);
        const weights = context.user.belowbarWeights;

        this.state = {
            selectedItem: null,
            selectNextItem: false,
            weightObjectList: weights[0],
            weightTypeList: weights[1],
            focusObjectId: null,
            displayObjectInfo: false,
            templateGroups: [],
            showTemplateHelp: false,
            lockedItems: [],
            selectedObject: null,
            isSelectObjectOperation: false,
            selectObjectCallback: null,
            selectObjectTypeId: null,
            multiSelectActive: false,
        };
    }

    componentDidMount() {
        Mousetrap.bindWithHelp(
            "space",
            () => {
                this.handleToggleObjectInfo(null, true);
                return false;
            },
            undefined,
            Language.get("nc_object_list_show_object_information.")
        );
        this.updateSelection(this.props);
        API.getPreferences("showTemplateHelp", (showTemplateHelp) => {
            this.setState({
                showTemplateHelp: _.isNullish(showTemplateHelp) ? true : Boolean(showTemplateHelp),
            });
        });
        this.registerMacros();
        API.getPreferences("lockedObjects", (value) => {
            if (value) {
                const items = JSON.parse(value);
                API.getObjectNames(
                    items.map((item) => ({ id: item.object.id, type: item.type.id })),
                    false,
                    (names) => {
                        items.forEach((item, index) => {
                            const name = names[index];
                            if (
                                name &&
                                name.fields &&
                                name.fields.length > 0 &&
                                name.fields[0].values &&
                                name.fields[0].values.length > 0
                            ) {
                                // eslint-disable-next-line no-param-reassign
                                item.objectText = name.fields[0].values[0];
                            }
                        });
                        this.setState({
                            lockedItems: items,
                        });
                    }
                );
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const active = prevProps.activeCalendar;
        const nextActive = this.props.activeCalendar;
        const isActivePrivate = active ? active.privateSelected : false;
        const isNextPrivate = nextActive ? nextActive.privateSelected : false;
        if (isActivePrivate !== isNextPrivate) {
            this.fluffyItemChanged(null, null);
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                selectedItem: null,
            });
            return;
        }

        if (!prevProps.data.isEditMode() && this.props.data.isEditMode()) {
            const firstVirtualObject = _.find(
                this.props.data.fluffy.objectItems,
                (item) => item.virtual
            );
            if (firstVirtualObject) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({ selectedItem: firstVirtualObject });
            }
        }

        if (!this.props.data.fluffy) {
            const data = this.props.data;
            this.props.data.createFluffy(
                null,
                this.props.activeLayer,
                this.props.activeCalendar
                    ? this.props.activeCalendar.templateKind
                    : TemplateKind.RESERVATION,
                (newSelection) => {
                    this.context.update(data, newSelection);
                }
            );
            return;
        }

        if (this.props.data.fluffy !== prevProps.data.fluffy) {
            const saveFluffy = this.props.data.fluffy.toJson();
            saveFluffy.labels = this.props.data.fluffy.labels;
            const FLUFFY_SAVE_TIMEOUT = 5000;
            clearTimeout(this._fluffySaveTimeout);
            this._fluffySaveTimeout = setTimeout(() => {
                API.setPreferences("currentFluffy", [JSON.stringify(saveFluffy)], _.noop);
            }, FLUFFY_SAVE_TIMEOUT);
        }

        if (
            !_.isEqual(
                this.props.data.fluffy ? this.props.data.fluffy.toJson() : null,
                prevProps.data.fluffy ? prevProps.data.fluffy.toJson() : null
            )
        ) {
            this.updateSelection(this.props);
        }

        // Clear selection time limit if a new item is selected
        if (
            this.state.selectedItem !== prevState.selectedItem &&
            this.props.data.hasTimeLimit() &&
            this.props.data.mode !== Selection.EDIT
        ) {
            this.context.update(this.props.data, this.props.data.removeTimeLimit());
        }
    }

    registerMacros() {
        this.context.registerMacro("selectionPane", {
            events: [Macros.Event.SELECT_EXTERNAL_TYPE],
            actions: [
                {
                    key: Macros.Action.SELECT_EXTERNAL_TYPE,
                    action: (type, callback = _.noop) => {
                        this.selectType(type.id, callback);
                    },
                },
            ],
        });
        this.context.registerMacro("selectionPane", {
            events: [Macros.Event.RESERVATION_MADE_OR_MODIFIED],
            actions: [
                {
                    key: Macros.Action.RESERVATION_MADE_OR_MODIFIED,
                    action: (reservationIds = []) => {
                        if (
                            reservationIds.length === 0 &&
                            this.props.data.reservations &&
                            this.props.data.reservations.length > 0
                        ) {
                            this.context.update(
                                this.props.data,
                                this.props.data.clearReservations()
                            );
                        }
                    },
                },
            ],
        });
        this.context.registerMacro("selectionPane", {
            events: [Macros.Event.SELECT_EXTERNAL_OBJECT],
            actions: [
                {
                    key: Macros.Action.SELECT_EXTERNAL_OBJECT,
                    action: (typedObjects) => {
                        const makeAdditionOfObjects = (object, remainingObjects, typeId) => {
                            // eslint-disable-next-line no-param-reassign
                            object.typeId = typeId;
                            this.handleAddObject(object, false, () => {
                                if (remainingObjects.length === 0) {
                                    return;
                                }
                                const nextObject = remainingObjects.pop();
                                makeAdditionOfObjects(nextObject, remainingObjects, typeId);
                            });
                        };
                        const allObjects = typedObjects.objects;
                        const firstObject = allObjects.pop();
                        makeAdditionOfObjects(firstObject, allObjects, typedObjects.type.id);
                    },
                },
            ],
        });
        this.context.registerMacro("selectionPane", {
            events: [Macros.Event.SET_EXTERNAL_SELECTION],
            actions: [
                {
                    key: Macros.Action.SET_EXTERNAL_SELECTION,
                    action: (reservationData, callback = _.noop) => {
                        if (reservationData.reservationMode) {
                            this.getTemplateGroupByExtid(
                                reservationData.reservationMode,
                                (groupId) => {
                                    this.handlePopulateSelection(
                                        reservationData,
                                        groupId,
                                        callback
                                    );
                                }
                            );
                        } else {
                            this.handlePopulateSelection(reservationData, null, callback);
                        }
                    },
                },
            ],
        });
        this.context.registerMacro("selectionPane", {
            events: [Macros.Event.REQUEST_OPERATION],
            actions: [
                {
                    key: Macros.Action.REQUEST_OPERATION,
                    action: (options) => {
                        // eslint-disable-next-line no-console
                        if (options.operationType === OPERATION_TYPES.SELECT_OBJECT) {
                            const typeId = options.data.typeId
                                ? options.data.typeId
                                : options.data.type
                                ? options.data.type.id
                                : null;
                            if (typeId) {
                                this.setState(
                                    {
                                        isSelectObjectOperation: true,
                                        selectObjectCallback: options.callback,
                                        selectObjectTypeId: typeId,
                                    },
                                    () => {
                                        if (options.data.reservationData.reservationMode) {
                                            this.setTemplateGroupByExtid(
                                                options.data.reservationData.reservationMode,
                                                () => {
                                                    this.handlePopulateSelection(
                                                        options.data.reservationData
                                                    );
                                                },
                                                true
                                            );
                                        } else {
                                            this.handlePopulateSelection(
                                                options.data.reservationData
                                            );
                                        }
                                    }
                                );
                            } else {
                                Log.error("The object type does not exist in TE Core.");
                                options.callback(null);
                            }
                        }
                    },
                },
            ],
        });
        this.context.registerMacro("selectionPane", {
            events: [Macros.Event.SET_EXTERNAL_TEMPLATE_GROUP],
            actions: [
                {
                    key: Macros.Action.SET_EXTERNAL_TEMPLATE_GROUP,
                    action: (templateGroupExtid) => {
                        this.setTemplateGroupByExtid(templateGroupExtid);
                    },
                },
            ],
        });
    }

    componentWillUnmount() {
        this.context.deregisterMacro("selectionPane");
    }

    getTemplateGroupByExtid = (templateGroupExtid, callback = _.noop) => {
        let tk = TemplateKind.RESERVATION;
        if (this.props.activeCalendar && this.props.activeCalendar.templateKind) {
            tk = this.props.activeCalendar.templateKind;
        }
        API.getTemplateGroups(tk.number, (result) => {
            const group = _.find(result.parameters[0], (grp) => grp.extid === templateGroupExtid);
            if (group) {
                callback(group.id);
            } else {
                callback();
            }
        });
    };

    setTemplateGroupByExtid = (templateGroupExtid, callback = _.noop, skipUpdates = false) => {
        let tk = TemplateKind.RESERVATION;
        if (this.props.activeCalendar && this.props.activeCalendar.templateKind) {
            tk = this.props.activeCalendar.templateKind;
        }
        API.getTemplateGroups(tk.number, (result) => {
            const group = _.find(result.parameters[0], (grp) => grp.extid === templateGroupExtid);
            if (group && group.id !== this.props.data.fluffy.templateGroupId) {
                this.handleSelectTemplateGroup(group.id, callback, skipUpdates);
            } else {
                callback();
            }
        });
    };

    handleClearSelection = () => {
        this.clearSelection();
        this.context.fireEvent(`selectionPane${this.props.id}`, Macros.Event.CLEAR_SELECTION);
    };

    // eslint-disable-next-line no-unused-vars
    handleToggleObjectInfo = (event, keyboardShortcutUsed = false) => {
        this.setState({ displayObjectInfo: !this.state.displayObjectInfo });
        // eslint-disable-next-line no-undef
        //mixpanel.track("Object info icon", {});
        if (event) {
            event.stopPropagation();
        }
    };

    handleFocusObject = (newObject) => {
        this.setState({
            focusObjectId: newObject ? newObject.id : 0,
        });
    };

    handleFluffyItem = (object, callback = _.noop, returnSelection) => {
        // Actually set the item
        this.fluffyItemChanged(
            object,
            this.props.data.fluffy,
            callback,
            this.state.selectNextItem,
            returnSelection
        );
    };

    handleSelectTemplateGroup = (groupId, callback = _.noop, skipUpdates = false) => {
        this.props.data.fluffy.setTemplateGroup(groupId, (fluffy) => {
            this.updateFluffy(fluffy, false, () => {
                if (!skipUpdates) {
                    if (this.props.activeCalendar) {
                        API.setPreferences(
                            `${this.props.activeCalendar.templateKind.name}lastSelectedTemplateGroup`,
                            [groupId],
                            _.noop
                        );
                    }
                    this.fluffyItemChanged(fluffy.objectItems[0], fluffy, callback);
                } else {
                    callback();
                }
            });
        });
    };

    setTemplateGroups = (allGroups) => {
        this.setState({ templateGroups: allGroups });
    };

    handleRemoveObject = (object, nextObject) => {
        let next = nextObject || object;
        this.props.data.fluffy.removeObject(
            object,
            (newFluffy) => {
                next = newFluffy.getLineForType(next.type.id);
                this.fluffyItemChanged(next, newFluffy, () => {
                    this.updateFluffy(newFluffy);
                });
            },
            false,
            this.props.data.isEditMode()
        );
    };

    handleAddObject = (object, isNotModKey, callback = _.noop) => {
        if (!_.isFunction(callback)) {
            // eslint-disable-next-line no-param-reassign
            callback = _.noop;
        }
        if (this.state.multiSelectActive) {
            return;
        }
        if (
            isNotModKey &&
            this.state.selectedItem.object &&
            this.state.selectedItem.object.id !== 0
        ) {
            this.props.data.fluffy.replaceObject(
                this.state.selectedItem,
                object,
                (newFluffy) => this.updateFluffy(newFluffy, false, callback),
                false,
                this.props.data.isEditMode()
            );
        } else {
            this.props.data.fluffy.addObject(
                object,
                (newFluffy) => this.updateFluffy(newFluffy, false, callback),
                false,
                this.props.data.isEditMode()
            );
        }

        if (isNotModKey && this.context.user.jumpToNextType === true) {
            this.setState({ selectNextItem: true, selectedObject: object });
        }
    };

    handleMultipleSelected = (objects, selectedIndexes, callback = _.noop) => {
        this.props.data.fluffy.addObjects(
            objects,
            (newFluffy) => this.updateFluffy(newFluffy, false, callback),
            false,
            this.props.data.isEditMode()
        );
    };

    handleLockItem = (object) => {
        // Only keep values that are required to add the object to McFluffy
        const lockedItem = _.pick(object, "object", "type", "objectText");
        this.updateLockedItems([lockedItem].concat(this.state.lockedItems));
    };

    handleUnlockItem = (object) => {
        this.updateLockedItems(
            this.state.lockedItems.filter((item) => item.object.id !== object.object.id)
        );
    };

    updateLockedItems = (lockedItems) => {
        this.setState({ lockedItems });
        API.setPreferences("lockedObjects", [JSON.stringify(lockedItems)], _.noop);
    };

    handleUpdate = (data) => {
        if (data.length === 0) {
            return null;
        }

        if (!this.props.activeCalendar) {
            return null;
        }
        this.context.fireEvent(
            `groupPane${this.props.id}`,
            Macros.Event.PROVIDER_UPDATE,
            data,
            this.props.activeCalendar._modelId
        );

        // Set active calendar directly to avoid flickering list. Duplicates handleProviderUpdate in Calendar.jsx
        const providers = this.props.activeCalendar.getProviderMap();
        const headers = this.props.activeCalendar.getHeaderTypeMap();

        if (providers.week && !providers.weekday) {
            const provider = headers.week || headers.weekperiod;
            if (provider) {
                return this.context.update(provider, provider.immutableSet({ weekdays: data }));
            }
        }

        if (providers.weekday && !providers.week) {
            const provider = headers.weekday || headers.weekdayperiod;
            if (provider) {
                return this.context.update(provider, provider.immutableSet({ weeks: data }));
            }
        }

        if (providers.date) {
            const provider = headers.dateperiod;
            if (provider) {
                return this.context.update(provider, provider.setWeeks(data));
            }
        }
        return null;
    };

    handlePreviousFluffy = () => {
        if (!this.props.data.hasSnapshots(true)) {
            return;
        }

        const snapshot = this.props.data.getPreviousSnapshot();
        this.updateFluffy(snapshot, false, (updatedFluffy) => {
            const firstItem = updatedFluffy.objectItems[0];
            this.fluffyItemChanged(firstItem, snapshot);
        });
    };

    handleNextFluffy = () => {
        if (!this.props.data.hasSnapshots(false)) {
            return;
        }

        const snapshot = this.props.data.getNextSnapshot();
        this.updateFluffy(snapshot, false, (updatedFluffy) => {
            const firstItem = updatedFluffy.objectItems[0];
            this.fluffyItemChanged(firstItem, snapshot);
        });
    };

    handleToggleTemplateHelp = () => {
        const newValue = !this.state.showTemplateHelp;
        this.setState({ showTemplateHelp: newValue });
        API.setPreferences("showTemplateHelp", [newValue], _.noop);
    };

    getProvider = () => {
        if (!(this.props.activeCalendar instanceof Calendar)) {
            return null;
        }
        const providers = this.props.activeCalendar.getProviderMap();
        const headers = this.props.activeCalendar.getHeaderTypeMap();
        let provider;
        if (providers.week && !providers.weekday) {
            provider = headers.week || headers.weekperiod;
            const INPUT_HEIGHT = 30;
            return (
                <li>
                    <div className="weekdayList">
                        <div className="listTopLine">
                            <h3>{Language.get("cal_list_weekdays")}</h3>
                        </div>
                        <ArrayInput
                            height={this.props.size.height - INPUT_HEIGHT}
                            defaultValue={provider.getWeekdays()}
                            defaultSize={7}
                            limit={7}
                            onUpdate={this.handleUpdate}
                        />
                    </div>
                </li>
            );
        }

        if (providers.weekday && !providers.week) {
            provider = headers.weekday || headers.weekdayperiod;
            return (
                <li>
                    <WeekList
                        outerHeight={this.props.size.height}
                        defaultValue={provider.getWeeks(this.context.customWeekNames)}
                        onUpdate={this.handleUpdate}
                        customWeekNames={this.context.customWeekNames}
                        isSchedulingTracks={Object.keys(this.props.schedulingTracks).length > 0}
                    />
                </li>
            );
        }

        if (headers.dateperiod && headers.dateperiod.isWeekPeriod) {
            provider = headers.dateperiod;
            return (
                <li>
                    <WeekList
                        outerHeight={this.props.size.height}
                        defaultValue={provider.getWeeks(this.context.customWeekNames)}
                        onUpdate={this.handleUpdate}
                        customWeekNames={this.context.customWeekNames}
                        isSchedulingTracks={Object.keys(this.props.schedulingTracks).length > 0}
                    />
                </li>
            );
        }
        return null;
    };

    getLayerContent = () => {
        const target = {
            bottom: 200,
            left: 20,
            width: 10,
            height: 15,
        };

        return (
            <Popover
                style={{ width: Popover.DEFAULT_WIDTH }}
                target={target}
                bottomArrow={true}
                onClose={this.handleToggleObjectInfo}
            >
                <ObjectInfo
                    user={this.props.user}
                    onClose={this.handleToggleObjectInfo}
                    typeId={this.state.selectedItem ? this.state.selectedItem.type.id : 0}
                    objectId={this.state.focusObjectId}
                />
            </Popover>
        );
    };

    getAvailableWidth = () => {
        const NUM_PADDED_SECTIONS = 4;
        const NUM_SEPARATORS = 2;
        return (
            this.props.size.width -
            LIST_PADDING * NUM_PADDED_SECTIONS -
            SEPARATOR_WIDTH * NUM_SEPARATORS -
            PANE_RIGHT_PADDING
        );
    };

    updateSelection = (props) => {
        if (!this.state.selectedItem || !props.data.fluffy) {
            return;
        }

        let items = props.data.fluffy.objectItems;

        if (
            !this.state.selectedItem ||
            (!this.state.selectNextItem &&
                (!this.state.selectedItem.object || this.state.selectedItem.object.id === 0))
        ) {
            return;
        }

        if (!_.isEqual(props.data.reservations, this.props.data.reservations)) {
            items = _.clone(items);
            items.reverse();
            const selectedItem =
                _.find(items, (item) => item.type.id === this.state.selectedItem.type.id) || null;
            this.fluffyItemChanged(selectedItem, props.data.fluffy);
            return;
        }

        this.selectNextItem(
            props.data.fluffy,
            this.state.selectNextItem || this.props.data.selectNextItem
        );
    };

    selectType = (typeId, callback) => {
        const items = this.props.data.fluffy.objectItems;
        const selected = _.find(items, (item) => item.type.id === typeId);
        if (selected) {
            this.fluffyItemChanged(selected, this.props.data.fluffy, callback, false);
        } else {
            callback();
        }
    };

    // Only used by external input, i.e. data from activity manager
    handlePopulateSelection = (reservationData, templateGroupId = null, cb = _.noop) => {
        const callback = () => {
            cb();
        };
        this.clearSelection(
            false,
            (newSelection) => {
                const objects = newSelection.fluffy.sortByTypes([...reservationData.objects]);
                const duration = reservationData.duration
                    ? reservationData.duration * TC.SECONDS_PER_MINUTE
                    : 0;

                const checkForTypeSkip = (fluffy, cb) => {
                    if (
                        this.context.user.jumpToNextType === false &&
                        !this.state.isSelectObjectOperation
                    ) {
                        cb();
                    } else {
                        let item = fluffy.objectItems.find((it) => it.object.id === 0);
                        if (this.state.isSelectObjectOperation) {
                            item = fluffy.objectItems.find(
                                (it) => it.type.id === this.state.selectObjectTypeId
                            );
                        }
                        // Lots of state setting!
                        if (item) {
                            this.selectFluffyItem(item, fluffy, cb);
                        } else {
                            cb();
                        }
                    }
                };

                const processResultFluffy = (resultFluffy) => {
                    if (reservationData.fields) {
                        resultFluffy.updateLabels((updatedFluffy) => {
                            this.updateFluffy(
                                updatedFluffy.setFields(reservationData.fields, true),
                                duration,
                                (newFluffy) => {
                                    if (
                                        duration &&
                                        duration > 0 &&
                                        !this.context.user.allowsSingleClickReservation()
                                    ) {
                                        // Temporarily enable single-click reservation
                                        this.context.update(
                                            this.context.user,
                                            this.context.user.immutableSet({
                                                allowTemporarySingleClickReservation: true,
                                            })
                                        );
                                    }
                                    checkForTypeSkip(newFluffy, () => {
                                        callback();
                                    });
                                }
                            );
                        });
                    } else {
                        resultFluffy.updateLabels((updatedFluffy) => {
                            this.updateFluffy(updatedFluffy, duration, (newFluffy) => {
                                if (
                                    duration &&
                                    duration > 0 &&
                                    !this.context.user.allowsSingleClickReservation()
                                ) {
                                    // Temporarily enable single-click reservation
                                    this.context.update(
                                        this.context.user,
                                        this.context.user.immutableSet({
                                            allowTemporarySingleClickReservation: true,
                                        })
                                    );
                                }
                                checkForTypeSkip(newFluffy, () => {
                                    callback();
                                });
                            });
                        });
                    }
                };

                this.addAllObjectsServer(newSelection.fluffy, objects, processResultFluffy);
            },
            true,
            templateGroupId || undefined
        );
    };

    selectFluffyItem = (item, fluffy, callback) => {
        this.setState({ selectedItem: item, selectedObject: null, selectNextItem: false }, () => {
            this.selectNextItem(fluffy, false, callback);
        });
    };

    selectNextItem = (fluffy, selectNextItem, callback = _.noop) => {
        const items = fluffy.objectItems;
        let selected = null;
        let foundIndex = 0;
        const maxIndex = items.length - 1;
        items.some((item, index, array) => {
            // If we should not skip to the next type, find the last row of the current type and select it
            if (!selectNextItem && selected && item.type.id !== this.state.selectedItem.type.id) {
                return true;
            }

            // Select the next empty item
            if (
                selectNextItem &&
                (item.object.id === 0 || (item.virtual && this.props.data.reservations.length > 0))
            ) {
                if (!this.state.selectedObject || this.state.selectedObject.id !== item.object.id) {
                    selected = item;
                    return true;
                }
            }

            // Mark current row as the potentially next one, but keep looking for more of the same type
            if (this.state.selectedItem && item.type.id === this.state.selectedItem.type.id) {
                foundIndex = index;
                selected = item;
                return false;
            }

            // Our last resort is selecting the last row
            if (index === maxIndex) {
                selected = array[maxIndex > foundIndex ? foundIndex + 1 : foundIndex]; // Return the line after the last selected one
                return true; // We are at the last item, return what we have.
            }
            return false;
        });

        this.fluffyItemChanged(
            selected || items[0],
            fluffy,
            () => {
                if (this.props.data.selectNextItem === true) {
                    this.context.update(this.props.data, this.props.data.setSelectNextItem(false));
                }
                callback();
            },
            false
        );
    };

    setFluffySize = (newSize) => {
        if (!newSize || newSize === "") {
            return;
        }
        const intSize = parseInt(newSize, 10);
        const newFluffy = this.props.data.fluffy.setSize(intSize);
        this.context.update(this.props.data, this.props.data.setFluffy(newFluffy));
    };

    setLockedItems = (selection, fluffy, lockedItems, callback = _.noop) => {
        fluffy.setObjects(lockedItems, false, (updatedFluffy) => {
            const lockSelection = selection.setFluffy(updatedFluffy);
            this.context.update(this.props.data, lockSelection);
            callback(lockSelection);
        });
    };

    clearSelection = (
        preserveLocks = true,
        callback = _.noop,
        skipUpdates = false,
        templateGroupId = undefined
    ) => {
        if (!this.props.activeCalendar) {
            callback(this.props.data);
            return;
        }
        if (this.context.user.allowTemporarySingleClickReservation && !skipUpdates) {
            this.context.update(
                this.context.user,
                this.context.user.immutableSet({
                    allowTemporarySingleClickReservation: false,
                })
            );
        }
        API.getPreferences(
            `${this.props.activeCalendar.templateKind.name}lastSelectedTemplateGroup`,
            (foundId) => {
                const groupId =
                    templateGroupId || foundId || this.props.data.fluffy.templateGroupId;
                this.props.data.createFluffy(
                    groupId,
                    this.props.activeLayer,
                    this.props.activeCalendar.templateKind,
                    (newSelection) => {
                        const nS = newSelection.immutableSet({ duration: false });
                        if (skipUpdates) {
                            callback(nS);
                            return;
                        }
                        const finish = (finishSelection) => {
                            if (finishSelection.fluffy.objectItems.length > 0) {
                                this.handleFluffyItem(
                                    finishSelection.fluffy.objectItems[0],
                                    callback,
                                    finishSelection
                                );
                            } else {
                                callback(finishSelection);
                            }
                        };
                        if (preserveLocks && this.state.lockedItems.length > 0) {
                            this.setLockedItems(
                                nS,
                                nS.fluffy,
                                [].concat(this.state.lockedItems),
                                finish
                            );
                        } else {
                            this.context.update(this.props.data, nS);
                            finish(nS);
                        }
                    }
                );
            }
        );
    };

    updateFluffy = (newFluffy, duration: number | false = false, callback = _.noop) => {
        if (this.props.data.fluffy && newFluffy) {
            this.context.update(
                this.props.data,
                this.props.data.setFluffy(newFluffy).setDuration(duration)
            );
        }
        callback(newFluffy);
    };

    fluffyItemChanged = (
        newItem,
        fluffy,
        callback = _.noop,
        selectNextItem = this.state.selectNextItem,
        returnSelection?
    ) => {
        const finish = (rs) => {
            if (this.props.onFluffyItemChanged) {
                this.props.onFluffyItemChanged(newItem);
            }
            callback(rs);
        };
        if (newItem && fluffy !== null) {
            this.setState({ selectedItem: newItem, selectNextItem }, () => {
                finish(returnSelection);
            });
        } else {
            finish(returnSelection);
        }
    };

    resizeComponents = (resizedComponentIndex, event) => {
        const MIN_SIZE = 150;
        const providerWidth = this.getProvider() ? PROVIDER_WIDTH : 0;
        const baseWidth =
            this.props.size.width -
            LIST_PADDING * NUM_PROVIDERS -
            SEPARATOR_WIDTH -
            PANE_RIGHT_PADDING -
            providerWidth;
        let objectWeight = this.state.weightObjectList;
        let typeWeight = this.state.weightTypeList;
        const coords = _.getClientPos(event);
        const x = coords.x - this._selectionsOffset.left;
        const BASE_WEIGHT = 10000;
        const newWeight = Math.floor((BASE_WEIGHT * x) / baseWidth) / BASE_WEIGHT;

        if (resizedComponentIndex === 0) {
            objectWeight = newWeight;
        } else {
            typeWeight = newWeight - objectWeight;
        }

        if (objectWeight + typeWeight > 1) {
            typeWeight = 1 - objectWeight;
        }

        if (objectWeight && !typeWeight) {
            typeWeight = 1 - objectWeight;
        }

        // Make it a bit easier to resize the lists to use 50% each of the available space
        const SNAP_SIZE = 0.5;
        const SNAP_THRESHOLD = 0.025;
        if (
            Math.abs(objectWeight - SNAP_SIZE) < SNAP_THRESHOLD &&
            Math.abs(typeWeight - SNAP_SIZE) < SNAP_THRESHOLD
        ) {
            objectWeight = SNAP_SIZE;
            typeWeight = SNAP_SIZE;
        }

        if (objectWeight * baseWidth < MIN_SIZE || typeWeight * baseWidth < MIN_SIZE) {
            return;
        }

        this.context.update(
            this.context.user,
            this.context.user.setBelowbarWeights([objectWeight, typeWeight])
        );
        this.setState({
            weightObjectList: objectWeight,
            weightTypeList: typeWeight,
        });
    };

    startComponentsResize = (resizedComponentIndex, event) => {
        this._selectionsOffset = _.nodeOffset(ReactDOM.findDOMNode(this.refs.objectSelection));

        event.stopPropagation();
        event.preventDefault();
        const resizeFunc = this.resizeComponents.bind(this, resizedComponentIndex);

        const moveFunc = _.getMoveEvent(event);
        const endFunc = _.getEndEvent(event);

        document.addEventListener(moveFunc, resizeFunc);
        document.addEventListener(endFunc, function removeListeners() {
            document.removeEventListener(moveFunc, resizeFunc);
            document.removeEventListener(endFunc, removeListeners);
        });
    };

    addAllObjectsServer = (fluffy, objects, callback) => {
        if (objects.length === 0) {
            callback(fluffy);
            return;
        }
        const typesWithObjects = {};
        objects.forEach((object) => {
            if (object.fields && !object.id) {
                // Is a filter
                return;
            }
            if (!typesWithObjects[object.typeId]) {
                typesWithObjects[object.typeId] = [];
            }
            // SCHED-940: Filter out duplicates
            const alreadyAdded = typesWithObjects[object.typeId].some(
                (added) => added.id === object.id
            );
            if (!alreadyAdded) {
                typesWithObjects[object.typeId].push(object);
            }
        });
        API.addToMcFluffyServer(fluffy.clearFields().toJson(), typesWithObjects, (result) => {
            if (result[0].parameters[0].class === "status" && result[0].parameters[0].result < 0) {
                Log.warning(result[0].parameters[0].details, result[0].parameters[0].result);
                return callback(fluffy, result[0].parameters[0]);
            }

            const resultFluffy = McFluffy.create(result[0], fluffy.labels);
            return callback(resultFluffy, result[0].parameters[0]);
        });
    };

    hasSnapshots = (hasPrevious) => this.props.data.hasSnapshots(hasPrevious);

    setReservation = (reservationIds, callback?) => {
        this.props.data.setReservation(
            _.asArray(reservationIds),
            (newSelection) => {
                const finalSelection = newSelection
                    .setGroups(this.props.data.groups)
                    .setDuration(false);
                if (callback) {
                    callback(finalSelection);
                } else {
                    this.context.update(this.props.data, finalSelection);
                }
            },
            false,
            false,
            length,
            false,
            this.props.data.clusterKind
        );
    };

    onGroupReservationSelected = (groupEntry) => {
        if (!this.props.activeCalendar) {
            return;
        }
        const reservationIds = groupEntry.reservationids
            ? groupEntry.reservationids
            : [groupEntry.id];
        const calendarGroupEntries = (this.props.activeCalendar.entries || []).filter(
            (etr) => etr.groups && _.some(etr.groups, (gr) => gr > 0)
        );

        const groups = this.context.useNewReservationGroups
            ? [groupEntry.group]
            : groupEntry.groups;
        const matchingEntries = _.filter(
            calendarGroupEntries,
            (entry) =>
                _.isEqual(entry.groups, groups) && _.isEqual(entry.reservationids, reservationIds)
        );
        let bestEntry;
        if (matchingEntries.length > 0) {
            bestEntry = _.find(
                matchingEntries,
                (entry) =>
                    entry.kind === EntryKind.COMPLETE || entry.kind === EntryKind.GROUP_COMPLETE
            );
            if (!bestEntry) {
                bestEntry = matchingEntries[0];
            }
        }

        const headerObjects = _.isNullish(bestEntry) ? [] : bestEntry.headerObjects;

        this.setReservation(reservationIds);
        Reservation.get(reservationIds, (res) => {
            if (this.props.activeCalendar && this.props.activeCalendar.gotoReservation) {
                this.context.update(
                    this.props.activeCalendar,
                    this.props.activeCalendar
                        .immutableSet({ currentEntryHeaderObjects: headerObjects })
                        .gotoReservation(res[0])
                );
            }
        });
    };

    // TODO entries are only entries for old reservation groups, for new groups, they're reservations
    onChangeTime = (reservationIds, entries, skipConfirmation = false) => {
        this.context.fireEvent(
            `groupPane${this.props.id}`,
            Macros.Event.CHANGE_RESERVATION_TIME,
            reservationIds,
            skipConfirmation,
            false
        );
        this.setReservation(reservationIds, (selection) => {
            if (this.props.activeCalendar) {
                this.context.update(
                    this.props.activeCalendar,
                    this.props.activeCalendar.immutableSet({
                        spotlightDate:
                            entries && entries.length > 0
                                ? new MillenniumDateTime(
                                      entries[0].begin ? entries[0].begin : entries[0].begins[0]
                                  ).getMillenniumDate()
                                : null,
                    })
                );
            }
            this.context.update(
                this.props.data,
                selection.enableAddMode(selection.groups, true).disableEditMode()
            );
        });
    };

    onEditGroupReservation = (reservations) => {
        this.context.fireEvent(
            `groupPane${this.props.id}`,
            Macros.Event.CHANGE_RESERVATION_OBJECTS,
            reservations
        );
    };

    isEmptyGroup(groups) {
        return groups.length === 0 || groups[0] === 0;
    }

    toEntry(entryCluster) {
        if (!entryCluster) {
            return null;
        }
        const result = _.clone(entryCluster);
        result.reservationids = [];
        result.objects = [];
        result.types = [];
        result.startTimes = entryCluster.begins.map((begin) => new MillenniumDateTime(begin));
        result.endTimes = entryCluster.ends.map((end) => new MillenniumDateTime(end));
        return result;
    }

    toggleAddMode = (entries) => {
        const selection = this.props.data;
        const selectionGroup = this.props.activeCalendar
            ? this.props.activeCalendar.selectionGroup
            : [];
        const isEmptyGroup = this.isEmptyGroup(selection.groups);
        if (selection.isGroupMode && selectionGroup.length > 0 && !isEmptyGroup) {
            const reservationIds = _.flatten(selectionGroup.map((entry) => entry.reservationids));
            if (this.context.useNewReservationGroups) {
                API.addReservationsToReservationGroup(
                    selection.groups[0],
                    reservationIds,
                    (groupResult) => {
                        if (groupResult === false) {
                            Log.warning(Language.get("nc_could_not_add_to_reservation_group"));
                            return;
                        }
                        const newSelection = selection.immutableSet({
                            reservations: selection.reservations.concat(reservationIds),
                        });
                        this.context.update(
                            selection,
                            newSelection.toggleAddMode(newSelection.groups).disableEditMode()
                        );
                    }
                );
            } else {
                API.addReservationsToGroups(
                    reservationIds,
                    selection.groups,
                    selection.clusterKind,
                    (groupResult) => {
                        if (groupResult === false) {
                            Log.warning(Language.get("nc_could_not_add_to_reservation_group"));
                            return;
                        }
                        const newSelection = selection.immutableSet({
                            reservations: selection.reservations.concat(reservationIds),
                        });
                        this.context.update(
                            selection,
                            newSelection.toggleAddMode(newSelection.groups).disableEditMode()
                        );
                    }
                );
            }
        } else {
            if (!selection.isGroupMode) {
                const currentEntry = _.find(entries, (entry) =>
                    _.isEqual(
                        entry.reservationids ? entry.reservationids : entry.id,
                        selection.reservations
                    )
                );
                this.context.update(
                    selection,
                    selection
                        .toggleAddMode(isEmptyGroup ? [] : selection.groups)
                        .enableEditMode(this.toEntry(currentEntry), true)
                );
            } else {
                this.context.update(
                    selection,
                    selection.toggleAddMode(isEmptyGroup ? [] : selection.groups).disableEditMode()
                );
            }
            if (this.props.activeCalendar) {
                this.context.update(
                    this.props.activeCalendar,
                    this.props.activeCalendar.immutableSet({
                        spotlightDate:
                            selection.isGroupMode ||
                            !entries ||
                            entries.length === 0 ||
                            this.context.useNewReservationGroups
                                ? null
                                : new MillenniumDateTime(
                                      entries[0].begins ? entries[0].begins[0] : entries[0].begin
                                  ).getMillenniumDate(),
                    })
                );
            }
        }
    };

    setSelectionGroup = (entries) => {
        if (!this.props.activeCalendar) {
            return;
        }
        this.context.update(
            this.props.activeCalendar,
            this.props.activeCalendar.immutableSet({ selectionGroup: entries })
        );
    };

    finishMove = () => {
        const selection = this.props.data;
        this.context.update(selection, selection.toggleAddMode(selection.groups).disableEditMode());
        if (this.props.activeCalendar) {
            this.context.update(
                this.props.activeCalendar,
                this.props.activeCalendar.immutableSet({
                    spotlightDate: null,
                })
            );
        }
        this.props.endLockMode();
    };

    finishSelectObjectOperation = (objectIds) => {
        this.state.selectObjectCallback(objectIds);
        this.setState({
            isSelectObjectOperation: false,
            selectObjectCallback: null,
            selectObjectTypeId: null,
        });
    };

    onSelectObjectOperation = () => {
        const items = this.props.data.fluffy.objectItems.filter(
            (item) => item.object && item.type.id === this.state.selectObjectTypeId
        );
        this.finishSelectObjectOperation(items.map((item) => item.object.id));
    };

    onCancelSelectObjectOperation = () => {
        this.finishSelectObjectOperation(null);
    };

    onSendToObjectHeader = (objects, doSet) => {
        if (!this.props.activeCalendar) {
            return;
        }
        const headers = this.props.activeCalendar
            .getHeaders()
            .filter((header) => header instanceof ObjectHeader);

        headers.forEach((header) => {
            if (doSet) {
                header.setObjects(
                    objects[0].typeId,
                    objects.map((obj) => obj.id),
                    (updatedHeader) => this.context.update(header, updatedHeader)
                );
            } else {
                header.addObjects(
                    objects[0].typeId,
                    objects.map((obj) => obj.id),
                    (updatedHeader) => this.context.update(header, updatedHeader)
                );
            }
        });
    };

    onSendToObjectManager = (objects) => {
        if (this.props.activeCalendar) {
            this.context.update(
                this.props.activeCalendar,
                this.props.activeCalendar.immutableSet({ objects })
            );
        }
    };

    clearFieldValues = () => {
        const newFluffy = this.props.data.fluffy.clearFields();
        const newSelection = this.props.data.setFluffy(newFluffy);
        this.context.update(this.props.data, newSelection);
    };

    toggleMultiSelect = () => {
        this.setState({ multiSelectActive: !this.state.multiSelectActive });
    };

    getSizeDebugInfo = () => {
        if (process.env.NODE_ENV !== "development" && this.props.data.fluffy.size !== null) {
            return (
                <li>
                    <ul>
                        <li className="fluffySize" style={{ paddingTop: "5px" }}>
                            Size: {this.props.data.fluffy.size}
                        </li>
                    </ul>
                </li>
            );
        } else if (process.env.NODE_ENV === "development") {
            return (
                <li>
                    <ul>
                        <li className="fluffySize" style={{ paddingTop: "5px" }}>
                            Size: {this.props.data.fluffy.size}
                        </li>
                        <li className="fluffyCapacity" style={{ paddingTop: "5px" }}>
                            Capacity ID: {this.props.data.fluffy.capacityReservationId}
                        </li>
                    </ul>
                </li>
            );
        }
        return null;
    };

    render() {
        const isNarrow = window.innerWidth < NARROW_WIDTH;

        const width = this.getAvailableWidth();
        const HALF = 0.5;
        const THIRD = 0.3;
        let objectListWidth = Math.floor((this.state.weightObjectList || THIRD) * width);
        let selectedListWidth = Math.floor((this.state.weightTypeList || THIRD) * width);

        if (isNarrow) {
            objectListWidth = Math.floor(HALF * width);
            selectedListWidth = objectListWidth;
        }

        const classes = ["selections"];
        if (!this.props.isVisible) {
            classes.push("hidden");
        }

        if (!this.props.data.fluffy) {
            return <div className={classes.join(" ")} style={{ height: this.props.size.height }} />;
        }

        const resizeObjectSelect = this.startComponentsResize.bind(this, 0);
        const resizeSelectionList = this.startComponentsResize.bind(this, 1);

        let objectInfo: React.JSX.Element | null = null;
        if (this.state.displayObjectInfo) {
            objectInfo = this.getLayerContent();
        }

        const NUM_LISTS = 2;
        const provider = this.getProvider();
        if (provider) {
            const availableWidth = width - (objectListWidth + selectedListWidth);
            if (availableWidth < PROVIDER_WIDTH) {
                const needed = (PROVIDER_WIDTH - availableWidth) / NUM_LISTS;
                objectListWidth -= needed;
                selectedListWidth -= needed;
            }
        }

        let templateHelp: React.JSX.Element | null = null;
        if (this.state.showTemplateHelp) {
            const sections = this.state.templateGroups
                .filter((group) => group.id > 0 && group.description)
                .map((group) => ({
                    headline: group.name,
                    text: group.description,
                }));
            if (sections.length > 0) {
                templateHelp = (
                    <li className="templateHelp" style={{ width: HELP_WIDTH }}>
                        <div className="closeButton" onClick={this.handleToggleTemplateHelp} />
                        <TextDisplay
                            headline={Language.get("nc_client_reservation_modes_help_headline")}
                            sections={sections}
                        />
                    </li>
                );
                let availableWidth = width - (objectListWidth + selectedListWidth);
                if (provider) {
                    availableWidth -= PROVIDER_WIDTH;
                }
                if (availableWidth < HELP_WIDTH) {
                    const needed = (HELP_WIDTH - availableWidth) / NUM_LISTS;
                    objectListWidth -= needed;
                    selectedListWidth -= needed;
                }
            }
        }

        const hoverButton = (
            <button
                className="hoverButton objectInfo"
                onClick={this.handleToggleObjectInfo}
                title={Language.get("nc_tooltip_show_detailed_object_information")}
            />
        );

        let fieldList: React.JSX.Element | null = null;
        if (
            this.context.user.fieldsInSelection === true &&
            this.context.user.fieldsNextToSelection === true
        ) {
            const FIELD_LIST_EXTRA_HEIGHT = 28;
            fieldList = (
                <li className="fieldInformation">
                    <SelectionListFieldEditor
                        disableClear={this.props.data.isEditMode()}
                        height={this.props.size.height + FIELD_LIST_EXTRA_HEIGHT}
                        fields={this.props.data.fluffy.fieldItems.map((fI) => fI.field)}
                        fluffy={this.props.data.fluffy}
                        clearFieldValues={this.clearFieldValues}
                    />
                </li>
            );
        }

        let groupPane: React.JSX.Element | null = null;
        if (this.props.data.belongsToGroup() || this.props.data.isGroupMode) {
            groupPane = (
                <li className="groupInformation">
                    <GroupInformationPane
                        listId={4096}
                        toggleAddMode={this.toggleAddMode}
                        clusterKind={this.props.data.clusterKind}
                        disableEntryEditing={
                            this.props.activeCalendar instanceof Calendar
                                ? this.props.activeCalendar.getMaxClusterDepth() > 1
                                : true
                        }
                        groupIds={this.props.data.getGroupIds()}
                        reservations={this.props.data.reservations}
                        onGroupReservationSelected={this.onGroupReservationSelected}
                        onEditGroupReservation={this.onEditGroupReservation}
                        onChangeTime={this.onChangeTime}
                        onInfoOpen={this.props.onEntryInfoOpen}
                        isGroupMode={this.props.data.isGroupMode}
                        unlockedReservations={this.props.unlockedReservations}
                        temporaryUnlock={this.props.temporaryUnlock}
                        skipConfirmation={this.props.skipConfirmation}
                        onFinishMoveButtonClicked={this.finishMove}
                    />
                </li>
            );
        }

        if (groupPane) {
            const availableWidth =
                width -
                objectListWidth -
                selectedListWidth -
                (provider ? PROVIDER_WIDTH : 0) -
                (templateHelp ? HELP_WIDTH : 0);
            if (availableWidth < MIN_GROUP_PANE_WIDTH) {
                // eslint-disable-next-line no-magic-numbers
                objectListWidth -= MIN_GROUP_PANE_WIDTH / 2;
                // eslint-disable-next-line no-magic-numbers
                selectedListWidth -= MIN_GROUP_PANE_WIDTH / 2;
            }
        }

        const foundItem = _.find(
            this.props.data.fluffy.objectItems,
            (item) => item.object && item.type.id === this.state.selectObjectTypeId
        );
        const selectObjectButton = this.state.isSelectObjectOperation ? (
            <div style={{ display: "flex" }}>
                <button
                    className="prefsOperationButton cancel"
                    onClick={this.onCancelSelectObjectOperation}
                >
                    {Language.get("dialog_cancel")}
                </button>
                <button
                    disabled={!foundItem || !foundItem.object || foundItem.object.id === 0}
                    className="prefsOperationButton"
                    onClick={this.onSelectObjectOperation}
                >
                    {Language.get("nc_prefs_select_object")}
                </button>
            </div>
        ) : null;

        const hasObjectHeader =
            this.props.activeCalendar && this.props.activeCalendar.hasHeaderType
                ? this.props.activeCalendar.hasHeaderType(ObjectHeader)
                : false;

        const hasObjectManager = this.props.activeCalendar instanceof MemberData;

        let multiButton: React.JSX.Element | null = null;
        if (this.context.user.enableMultiSelect) {
            const multiClasses = ["multiSelectButton"];
            if (this.state.multiSelectActive) {
                multiClasses.push("active");
            }
            multiButton = (
                <button
                    ref="multiSelectButton"
                    className={multiClasses.join(" ")}
                    title={Language.get("nc_multi_selection")}
                    value={this.state.multiSelectActive}
                    onClick={this.toggleMultiSelect}
                />
            );
        }

        let trackPane: React.JSX.Element | null = null;
        if (
            Object.keys(this.props.schedulingTracks).length > 0 &&
            this.props.activeCalendar instanceof Calendar
        ) {
            const headers = this.props.activeCalendar.getHeaderTypeMap();
            const prvd = headers.weekday || headers.weekdayperiod;
            if (prvd) {
                const weeks = prvd
                    .getWeeks(this.context.customWeekNames)
                    .filter((wk) => wk.selected);
                trackPane = (
                    <li className="trackPane">
                        <TrackPane
                            outerHeight={this.props.size.height}
                            tracks={this.props.schedulingTracks}
                            mappedResult={this.props.mappedResult}
                            currentTrack={this.props.currentTrack}
                            onCancel={this.props.onCancelActivityScheduling}
                            onUpdate={this.props.onCurrentTrackChanged}
                            numWeeks={weeks.length > 0 ? weeks.length : 1}
                        />
                    </li>
                );
            }
        }

        const sizeDebugInfo = this.getSizeDebugInfo();

        return (
            <div className={classes.join(" ")} style={{ height: this.props.size.height }}>
                <ul>
                    <li className="objectSelectList">
                        <ObjectSelect
                            data={this.props.data}
                            onObjectSearcherChange={this.props.onObjectSearcherChange}
                            width={objectListWidth}
                            selectNextItem={this.state.selectNextItem}
                            selectedType={this.state.selectedItem?.type}
                            subtypes={this.state.selectedItem?.subtypes}
                            selectedFluffyItem={this.state.selectedItem}
                            onClick={this.handleAddObject}
                            onMultipleSelected={
                                this.state.multiSelectActive
                                    ? this.handleMultipleSelected
                                    : undefined
                            }
                            onObjectInfo={this.props.onObjectInfo}
                            onSendToObjectHeader={
                                hasObjectHeader ? this.onSendToObjectHeader : null
                            }
                            onSendToObjectManager={
                                hasObjectManager ? this.onSendToObjectManager : null
                            }
                            allowMultiSelection={this.state.multiSelectActive}
                            addReloadFunction={this.props.addReloadFunction}
                            ref="objectSelection"
                            onRowHover={this.handleFocusObject}
                            hoverButton={hoverButton}
                            topLeftButton={multiButton}
                            user={this.props.user}
                        />
                    </li>
                    {isNarrow ? null : (
                        <li
                            className="separator"
                            onTouchStart={resizeObjectSelect}
                            onMouseDown={resizeObjectSelect}
                        >
                            <span />
                            <span />
                        </li>
                    )}
                    <li>
                        <SelectionList
                            flags={this.props.flags}
                            ref="selectedList"
                            data={this.props.data}
                            currentSelectionGroup={
                                this.props.activeCalendar
                                    ? this.props.activeCalendar.selectionGroup
                                    : []
                            }
                            setSize={this.setFluffySize}
                            clusterKind={this.props.data.clusterKind}
                            setSelectionGroup={this.setSelectionGroup}
                            lockedObjects={this.state.lockedItems}
                            onNewObject={this.props.onNewObject}
                            onObjectInfo={this.props.onObjectInfo}
                            onSelectionClear={this.handleClearSelection}
                            templateKind={
                                this.props.activeCalendar
                                    ? this.props.activeCalendar.templateKind
                                    : TemplateKind.RESERVATION
                            }
                            onHelpToggle={this.handleToggleTemplateHelp}
                            isHelpVisible={this.state.showTemplateHelp}
                            onTemplateSelect={this.handleSelectTemplateGroup}
                            onTemplatesLoaded={this.setTemplateGroups}
                            width={selectedListWidth}
                            height={this.props.size.height}
                            getObjectSearch={this.props.getObjectSearch}
                            selectedFluffyItem={this.state.selectedItem}
                            onChange={this.handleFluffyItem}
                            onRemove={this.handleRemoveObject}
                            onLock={this.handleLockItem}
                            onUnlock={this.handleUnlockItem}
                            onPreviousClick={this.handlePreviousFluffy}
                            onNextClick={this.handleNextFluffy}
                            hasSnapshots={this.hasSnapshots}
                            onObjectAdd={this.handleAddObject}
                            user={this.props.user}
                            setReservation={this.setReservation}
                            bottomButton={selectObjectButton}
                            fieldsInSelection={this.context.user.fieldsInSelection} // TODO Why user from context here and user from props above?
                        />
                    </li>
                    {isNarrow ? null : (
                        <li
                            className="separator"
                            onTouchStart={resizeSelectionList}
                            onMouseDown={resizeSelectionList}
                        >
                            <span />
                            <span />
                        </li>
                    )}
                    {provider}
                    {objectInfo}
                    {fieldList}
                    {templateHelp}
                    {groupPane}
                    {trackPane}
                    {sizeDebugInfo}
                </ul>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        // Do not update when focusObjectId changes
        return (
            this.props.data !== nextProps.data ||
            this.state.showTemplateHelp !== nextState.showTemplateHelp ||
            this.state.templateGroups !== nextState.templateGroups ||
            this.state.focusObjectId !== nextState.focusObjectId ||
            this.props.activeCalendar !== nextProps.activeCalendar ||
            this.props.isVisible !== nextProps.isVisible ||
            !_.isEqual(this.props.size, nextProps.size) ||
            this.state.displayObjectInfo !== nextState.displayObjectInfo ||
            this.state.selectNextItem !== nextState.selectNextItem ||
            this.props.data.selectNextItem !== nextProps.data.selectNextItem ||
            this.state.weightObjectList !== nextState.weightObjectList ||
            this.state.weightTypeList !== nextState.weightTypeList ||
            !_.isEqual(this.state.lockedItems, nextState.lockedItems) ||
            !_.isEqual(this.state.selectedItem, nextState.selectedItem) ||
            !_.isEqual(this.props.unlockedReservations, nextProps.unlockedReservations) ||
            !_.isEqual(this.props.data.getGroupIds(), nextProps.data.getGroupIds()) ||
            !_.isEqual(this.props.user, nextProps.user) ||
            this.state.isSelectObjectOperation !== nextState.isSelectObjectOperation ||
            this.state.multiSelectActive !== nextState.multiSelectActive ||
            !_.isEqual(this.props.schedulingTracks, nextProps.schedulingTracks)
        );
    }
}

export default SelectionPane;
