import Language from "../lib/Language";

const Status = function (id, name, description) {
    this.id = id;
    this.name = name;
    this.description = description;
};

const TEReservationStatus = {};

const STATUS = {
    UNKNOWN: 0,
    RESERVATION: 10,
    AVAILABILITY: 11,
    INFO: 12,
    COMPLETE: 20,
    INCOMPLETE: 21,
    CONFIRMED: 30,
    PRELIMINARY: 31,
    PLANNED: 32,
    REQUESTED: 33,
    REJECTED: 34,
    TIME: 40,
    WAITING_LIST: 41,
};

TEReservationStatus.UNKNOWN = new Status(
    STATUS.UNKNOWN,
    "",
    "Status denoting an invalid, incorrect or unknown status"
);

TEReservationStatus.A_RESERVATION = new Status(
    STATUS.RESERVATION,
    "reservation",
    "Status for standard type reservations"
);
TEReservationStatus.A_AVAILABILITY = new Status(
    STATUS.AVAILABILITY,
    "availability",
    "Status for availability type reservations"
);
TEReservationStatus.A_INFO = new Status(STATUS.INFO, "info", "Status for info type reservations");

TEReservationStatus.B_COMPLETE = new Status(
    STATUS.COMPLETE,
    "complete",
    "Status for complete reservations"
);
TEReservationStatus.B_INCOMPLETE = new Status(
    STATUS.INCOMPLETE,
    "incomplete",
    "Status for incomplete reservations"
);

TEReservationStatus.C_CONFIRMED = new Status(
    STATUS.CONFIRMED,
    "confirmed",
    "Status for accepted reservations that are not preliminary nor planned"
);
TEReservationStatus.C_PRELIMINARY = new Status(
    STATUS.PRELIMINARY,
    "preliminary",
    "Status for preliminary reservations"
);
TEReservationStatus.C_PLANNED = new Status(
    STATUS.PLANNED,
    "planned",
    "Status for planned reservations"
);
TEReservationStatus.C_REQUESTED = new Status(
    STATUS.REQUESTED,
    "requested",
    "Status for reservation requests"
);
TEReservationStatus.C_REJECTED = new Status(
    STATUS.REJECTED,
    "rejected",
    "Status for rejected reservation requests"
);

TEReservationStatus.D_TIME = new Status(
    STATUS.TIME,
    "time",
    "Status for reservations with begin and end time"
);
TEReservationStatus.E_WAITING_LIST = new Status(
    STATUS.WAITING_LIST,
    "waiting_list",
    "Status for reservations that do not contain begin or end time"
);

Status.prototype.getStatusName = function (shortName) {
    if (this === TEReservationStatus.UNKNOWN) {
        return "-";
    }
    let result = Language.get(`dynamic_reserv_list_${this.name}${shortName ? "_short" : ""}`);
    if (!result) {
        result = this.name;
    }
    return result;
};

TEReservationStatus.statusForId = function (id) {
    let status;
    // eslint-disable-next-line no-restricted-syntax
    for (status in TEReservationStatus) {
        if (
            TEReservationStatus.hasOwnProperty(status) &&
            TEReservationStatus[status] instanceof Status
        ) {
            if (TEReservationStatus[status].id === id) {
                return TEReservationStatus[status];
            }
        }
    }
    return TEReservationStatus.UNKNOWN;
};

TEReservationStatus.statusForName = function (name) {
    let status;
    // eslint-disable-next-line no-restricted-syntax
    for (status in TEReservationStatus) {
        if (
            TEReservationStatus.hasOwnProperty(status) &&
            TEReservationStatus[status] instanceof Status
        ) {
            if (TEReservationStatus[status].name === name) {
                return TEReservationStatus[status];
            }
        }
    }
    return TEReservationStatus.UNKNOWN;
};

export default TEReservationStatus;
