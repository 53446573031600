import { MillenniumDate, MillenniumDateTime } from "@timeedit/millennium-time";
import DataStore from "../../lib/DataStore";
import { TimeConstants } from "../../lib/TimeConstants";
import API from "../../lib/TimeEditAPI";

type Tree = Partial<{ subtypes: any; depth: number }>;
export const treeToTypeList = (tree: Tree | undefined, depth: number) => {
    if (!tree) {
        return [];
    }
    const subNodes: Tree[] = [];
    // eslint-disable-next-line no-param-reassign
    tree.depth = depth;
    subNodes.push(tree);
    if (tree.subtypes) {
        // eslint-disable-next-line no-param-reassign
        depth++;
        for (let i = 0; i < tree.subtypes.length; i++) {
            subNodes.push(_.flatten(treeToTypeList(tree.subtypes[i], depth)) as any); // This does not seem right. Remove 'any' type cast to discover.
        }
    }
    return _.flatten(subNodes);
};

export const isExact = (isExactProp) => {
    if (isExactProp === undefined) {
        return true;
    }
    return isExactProp === true;
};

const isValidFormat = (prefsTimeString) => {
    if (prefsTimeString.indexOf("Z") === prefsTimeString.length - 1) {
        return true;
    }
    if (prefsTimeString.indexOf("+00:00") === prefsTimeString.length - 6) {
        return true;
    }
    return false;
};

const isValidTimezone = (timeZone) => {
    try {
        Intl.DateTimeFormat(undefined, { timeZone });
        return true;
    } catch {
        return false;
    }
};

export const parseDate = (prefsDateString) => {
    const [year, month, rest] = prefsDateString.split("-");
    const day = rest.split("T")[0];
    return MillenniumDate.create(year, month, day);
};
/**
 *  Get timezone difference for given timezone.
 * @param {string} timeZone IANA Time Zone
 * @returns E.g "+02:00" for timezone "Europe/Stockholm"
 */
const getGmtOffsetFromTimezone = (timeZone) => {
    // Inspired by https://stackoverflow.com/a/77693985
    try {
        const longOffsetFormatter = new Intl.DateTimeFormat("en-US", {
            timeZone,
            timeZoneName: "longOffset",
        });

        const gmtOffset = longOffsetFormatter
            .format(new Date("2001-01-18T18:00:00.000")) // Just some correctly formatted date.
            .split("GMT")[1]; // split('GMT')[1] will give us e.g. '-05:00', which we are after.

        let modifier = gmtOffset.substring(0, 1);
        let timeDiff = gmtOffset.substring(1);
        if (timeDiff === "") {
            timeDiff = "00:00";
        }
        if (modifier === "") {
            modifier = "+";
        }
        return [modifier, timeDiff]; // E.g ["+", "05:00"]
    } catch (e) {
        throw new Error(`Could not get GMT offset from timezone '${timeZone}'`);
    }
};

/**
 * Apply timezone to UTC timestring
 * @param {string} utcTimeString UTC Timestring
 * @param {*} timeZone IANA Time Zone
 * @param {boolean} invertTimeDifference If true, then given timezone difference will be inverted.
 * @returns UTC timestring with timezone difference applied to it.
 */
const applyTimeZoneToUTCTimeString = (utcTimeString, timeZone, invertTimeDifference = false) => {
    let [modifier, timeDiff] = getGmtOffsetFromTimezone(timeZone);
    if (invertTimeDifference) {
        modifier = modifier === "+" ? "-" : "+";
    }
    // GMT offset string, example value: '-05:00'
    let gmtOffset = `${modifier}${timeDiff}`;

    // Strip timezone.
    const dateWihoutTimeZone = utcTimeString.split("Z")[0];

    // Return new date with timezone offset in ISO UTC format.
    return new Date(dateWihoutTimeZone + gmtOffset).toISOString();
};

// Parses UTC times from AM. Format: 2020-10-13T06:25:00.000Z
export const parseTime = <B extends boolean>(
    prefsTimeString,
    returnDateTime: B = false as any,
    timezoneOverride: string | undefined = undefined
): B extends true ? MillenniumDateTime : number => {
    if (!isValidFormat(prefsTimeString)) {
        throw new Error(`AM sent non-UTC timestamp ${prefsTimeString}`);
    }
    if (timezoneOverride && !isValidTimezone(timezoneOverride)) {
        throw new Error(`AM sent invalid timezone ${timezoneOverride}`);
    }
    const timeString = timezoneOverride
        ? applyTimeZoneToUTCTimeString(prefsTimeString, timezoneOverride, true)
        : prefsTimeString;

    const [year, month, rest] = timeString.split("-");
    const [day, time] = rest.split("T");
    const [hour, minute, seconds] = time.split(":");
    const [second] = seconds.split(".");
    let offset = new Date(timeString).getTimezoneOffset() * TimeConstants.SECONDS_PER_MINUTE;

    const result = MillenniumDateTime.fromYyyyMMddHHmmss(
        year,
        month,
        day,
        hour,
        minute,
        second,
        false
    ).addSeconds(-offset);
    return returnDateTime ? result : (result.getMts() as any);
};

export const mapResults = (objects, types, fields) => {
    const result = { objects: {}, types: {}, fields: {} };
    objects.forEach((obj) => {
        result.objects[obj.extid] = {
            label: obj.fields
                .map((field) => (field.values ? field.values.join(", ") : ""))
                .join(", "),
            fieldExtIds: obj.fields.map((field) => field.extid),
        };
    });
    types.forEach((type) => {
        result.types[type.extid] = { label: type.name };
    });
    fields.forEach((field) => {
        result.fields[field.extid] = { label: field.name };
    });
    return result;
};
/**
 * Try to convert boolean-like value too a "0" or "1"
 * @param {string, boolean, any} The boolean-like value that should be converted to a "0" or "1".
 * @returns "1", "0" or unmodified value.
 */
export const boolToNumberString = (value) => {
    if (value === true || value.toString().toLowerCase() === "true") {
        return "1";
    }
    if (value === false || value.toString().toLowerCase() === "false") {
        return "0";
    }
    return value;
};

const nullStringToNull = (value) => (value === "null" ? null : value);

type TSelectTypeCallBackArg = typeof _.noop | ((res: any) => void);
export const selectType = (extId, callback: TSelectTypeCallBackArg = _.noop) => {
    API.getTypesByExtid(_.asArray(extId), false, (result) => {
        callback(result[0]);
    });
};

export const mapReservationData = (
    {
        objects,
        fields,
        formType,
        reservationMode,
        startTime,
        endTime,
        duration,
        dateRanges,
        timezoneInfo,
    },
    useSpotlight = true,
    callback
) => {
    const typeExtIds = objects.map((obj) => obj.type);
    const objectExtIds = objects.map((obj) => obj.id).filter((id) => id !== undefined);
    const soughtFields = _.flatten(
        objects
            .map((obj) => (obj.fields || []).map((fld) => fld.fieldExtId))
            .filter((flds) => flds.length !== 0)
    ).concat(fields.map((fld) => fld.fieldExtId));

    API.getTypesByExtid(typeExtIds, false, (types) => {
        API.getObjectsByExtid(objectExtIds, (objs) => {
            API.getFieldsByExtid(soughtFields, (foundFields) => {
                const mappedObjects = objects
                    .map((obj) => {
                        const result = { ...obj };
                        const foundType = types[typeExtIds.indexOf(result.type)]; //_.find(types, (tp) => tp.extid === result.type);
                        if (foundType) {
                            result.type = foundType;
                            result.typeId = foundType.id;
                        }
                        if (result.id) {
                            result.extid = obj.id;
                            const foundObject = _.find(objs, (ob) => ob.extid === result.extid);
                            if (foundObject) {
                                result.id = foundObject.id;
                                result.fields = foundObject.fields;
                            }
                        } else if (result.fields) {
                            result.fields = result.fields.map((fld) => {
                                const rf = { ...fld };
                                rf.extid = fld.fieldExtId;
                                const foundField = _.find(
                                    foundFields,
                                    (ff) => ff.extid === rf.extid
                                );
                                if (foundField) {
                                    rf.id = foundField.id;
                                }
                                rf.values = fld.values
                                    .map(boolToNumberString) // Server wants boolean field values as strings '0' or '1'
                                    .map(nullStringToNull)
                                    .filter((value) => value !== null);

                                return rf;
                            });
                        }
                        return result;
                    })
                    .filter(
                        (object) =>
                            (object.id !== null && object.id > 0) ||
                            (object.fields && object.fields.length > 0)
                    );
                const mappedFields = fields.map((fld) => {
                    const result = { ...fld };
                    result.extid = fld.fieldExtId;
                    const foundField = _.find(
                        foundFields.filter((f) => Boolean(f)),
                        (ff) => ff.extid === result.extid
                    );
                    if (foundField) {
                        result.id = foundField.id;
                    }
                    result.values = result.values
                        .map(boolToNumberString) // Server wants boolean field values as strings '0' or '1'
                        .map(nullStringToNull)
                        .filter((value) => value !== null);
                    return result;
                });
                let begin: MillenniumDate | undefined | null = undefined;
                let end: MillenniumDate | undefined | null = undefined;
                try {
                    begin = startTime
                        ? new MillenniumDateTime(parseTime(startTime)).getMillenniumDate()
                        : null;
                    end = endTime
                        ? new MillenniumDateTime(parseTime(endTime)).getMillenniumDate()
                        : null;
                } catch (ignore) {
                    begin = undefined;
                    end = undefined;
                }
                let focusDate: MillenniumDate | undefined = undefined;
                if (dateRanges && dateRanges.startTime) {
                    try {
                        focusDate = new MillenniumDateTime(
                            parseTime(dateRanges.startTime, false, timezoneInfo?.identifier)
                        ).getMillenniumDate();
                    } catch (ignore) {
                        focusDate = undefined;
                    }
                }
                callback(
                    {
                        objects: mappedObjects,
                        fields: mappedFields,
                        formType,
                        reservationMode,
                        begin,
                        end,
                        focusDate,
                        duration,
                    },
                    useSpotlight
                );
            });
        });
    });
};

export const doPopulateSelection = (reservationData, props, useSpotlight = true) => {
    return new Promise((resolve) => {
        mapReservationData(
            DataStore.deepFreeze(reservationData),
            useSpotlight,
            props.populateSelection
        );
        resolve(undefined);
    });
};

export const getFilterValue = (activityValue) => activityValue.value;

export const validateTimezone = (timezoneInfo, userTimezone) => {
    if (!timezoneInfo || Object.keys(timezoneInfo).length === 0) {
        return true;
    }
    if (timezoneInfo.extId.toLowerCase() !== userTimezone.shortName.toLowerCase()) {
        // eslint-disable-next-line no-alert
        return confirm(`${timezoneInfo.identifier} is not your currently set timezone. Continue?`);
    }
    return true;
};
