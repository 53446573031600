import { Model } from "./Model";
import { View } from "./View";

export class ViewList extends Model {
    views: any;

    constructor(views?) {
        super("ViewList");
        this.views = views || [];
    }

    updateViews(callback) {
        const self = this;
        View.find(null, (newViews) => {
            callback(
                self.immutableSet({
                    views: newViews,
                })
            );
        });
    }
}
