const Mode = function (number, name) {
    this.number = number;
    this.name = name;
};

Mode.prototype.equals = function (otherMode) {
    if (!(otherMode instanceof Mode)) {
        return false;
    }
    return this.number === otherMode.number;
};

const FunctionMode = {};
const modes = [
    "RESERVATION",
    "NOT_USED_ACC",
    "META_RELATION",
    "HPERIOD",
    "SELECT",
    "INACTIVE",
    "NOT_USED_WEEK",
    "STATISTICS",
    "CLUSTER",
];
FunctionMode.get = function (number) {
    const intVal = parseInt(number, 10) || 0;
    return FunctionMode[modes[intVal]];
};

export default (function () {
    modes.forEach((mode, index) => {
        FunctionMode[mode] = new Mode(index, `FUNC_MODE_${mode}`);
    });
    return FunctionMode;
})();
