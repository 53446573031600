import _ from "underscore";

const ContextMenu = (function () {
    const listeners = [];
    let nextId = 1;
    let ignoreEvents = false;
    let currentMenuItems = [];
    let currentListeners = [];

    const closeMenu = function (event) {
        const menu = document.querySelector("#contextMenuClickCatcher");

        if (menu) {
            currentMenuItems = [];
            currentListeners = [];
            if (menu.closeCallback) {
                menu.closeCallback();
            }
            if (event) {
                event.preventDefault();
                event.stopPropagation();
            }
            menu.parentNode.removeChild(menu);
        }
    };

    const escapeHandler = function (event) {
        // eslint-disable-next-line no-magic-numbers
        if (event.keyCode === 27) {
            closeMenu(event);
        }
    };

    const renderList = function (node, event, parent, closeCallback, isSubMenu = false) {
        let useEvent = !parent;
        if (!parent) {
            // eslint-disable-next-line no-param-reassign
            parent = document.body;
        }

        let coords = null;
        try {
            coords = _.getClientPos(event);
        } catch (ignore) {
            useEvent = false;
        }

        const parentRect = parent.getBoundingClientRect();
        if (useEvent) {
            // eslint-disable-next-line no-param-reassign
            node.style.top = `${coords.y}px`;
            // eslint-disable-next-line no-param-reassign
            node.style.left = `${coords.x}px`;
        } else {
            // eslint-disable-next-line no-param-reassign
            node.style.top = `${parentRect.top}px`;
            // eslint-disable-next-line no-param-reassign, no-magic-numbers
            node.style.left = `${parentRect.left + parentRect.width - 2}px`;
        }

        let ref, menuRef;

        if (!isSubMenu) {
            const clickCatcher = document.createElement("div");
            clickCatcher.id = "contextMenuClickCatcher";
            clickCatcher.classList.add("contextMenuClickCatcher");
            if (closeCallback) {
                clickCatcher.closeCallback = closeCallback;
            }
            menuRef = clickCatcher.appendChild(node);
            ref = parent.appendChild(clickCatcher);
        } else {
            ref = parent.appendChild(node);
            menuRef = ref;
        }

        // Adjust position if menu is partially outside the screen
        const rect = menuRef.getBoundingClientRect();
        if (rect.bottom > document.body.clientHeight) {
            const baseY = useEvent ? coords.y : parentRect.top + parentRect.height;
            if (baseY - rect.height > 0) {
                // eslint-disable-next-line no-param-reassign
                node.style.top = `${baseY - rect.height}px`;
            } else {
                if (rect.height > document.body.clientHeight) {
                    // Special case, if the contextMenu height is larger than the space available
                } else {
                    // eslint-disable-next-line no-param-reassign, no-magic-numbers
                    node.style.top = `${5}px`;
                }
            }
        }
        if (rect.left + rect.width > document.body.clientWidth) {
            if (useEvent) {
                // eslint-disable-next-line no-param-reassign
                node.style.left = `${coords.x - rect.width}px`;
            } else {
                // eslint-disable-next-line no-param-reassign, no-magic-numbers
                node.style.left = `${parentRect.left - rect.width + 2}px`;
            }
        }
    };

    const appendItems = function (parent, items) {
        items.forEach((item) => {
            if (!item.action && !item.submenu && !item.isSeparator) {
                return;
            }
            let isDisabled = false;
            if (item.isDisabled === true || (item.isDisabled && item.isDisabled() === true)) {
                isDisabled = true;
            }

            const li = document.createElement("li");
            if (isDisabled) {
                li.classList.add("disabled");
            }

            if (item.emphasize) {
                li.classList.add("emphasize");
            }

            if (item.isSeparator) {
                li.classList.add("disabled");
                li.classList.add("separator");
            }

            if (item.tooltip) {
                li.title = item.tooltip;
            }

            if (item.hasOwnProperty("checked")) {
                li.classList.add("checkbox");
                if (item.checked()) {
                    li.classList.add("checked");
                }
            }

            if (item.classNames) {
                _.asArray(item.classNames).forEach((className) => {
                    li.classList.add(className);
                });
            }

            li.innerHTML = item.isSeparator ? "<hr></hr>" : item.label;
            if (item.shortcut) {
                li.innerHTML = `<span>${li.innerHTML}</span><span style="float: right; padding-left: 10px">${item.shortcut}</span>`;
            }
            if (item.action && !isDisabled) {
                li.addEventListener("click", (event) => {
                    item.action(event);
                    closeMenu(event);
                });
                li.addEventListener("contextmenu", (event) => {
                    event.preventDefault();
                    item.action(event);
                    closeMenu(event);
                });
            }
            if (item.submenu) {
                if (!item.action) {
                    li.addEventListener("click", (event) => {
                        if (li.querySelector(".contextMenu")) {
                            event.preventDefault();
                            event.stopPropagation();
                            return;
                        }
                        const ul = document.createElement("ul");
                        ul.classList.add("contextMenu");

                        appendItems(ul, item.submenu);
                        renderList(ul, event, li, undefined, true);
                        event.preventDefault();
                        event.stopPropagation();
                    });
                }
                li.addEventListener("mouseover", (event) => {
                    if (li.querySelector(".contextMenu")) {
                        return;
                    }
                    const ul = document.createElement("ul");
                    ul.classList.add("contextMenu");

                    appendItems(ul, item.submenu);
                    renderList(ul, event, li, undefined, true);
                });
                li.classList.add("submenu");
            }
            parent.appendChild(li);
        });
    };

    const displayMenu = function (menuItems, event, closeCallback) {
        if (!menuItems || menuItems.length === 0) {
            return 0;
        }

        event.preventDefault();
        event.stopPropagation();

        ignoreEvents = true;
        const ul = document.createElement("ul");
        ul.id = "contextMenu";
        ul.classList.add("contextMenu");

        const items = _.flatten(
            menuItems.map((item) => {
                if (_.isFunction(item)) {
                    return item();
                }
                return item;
            })
        );
        appendItems(ul, items);

        renderList(ul, event, undefined, closeCallback);
        const TIMEOUT = 100;
        setTimeout(() => {
            ignoreEvents = false;
        }, TIMEOUT);

        const id = nextId;
        currentListeners = currentListeners.concat(id);
        return id;
    };

    const update = function (listenerId) {
        if (!_.contains(currentListeners, listenerId)) {
            return;
        }

        const ul = document.querySelector("#contextMenu");
        if (!ul) {
            return;
        }

        while (ul.firstChild) {
            ul.removeChild(ul.firstChild);
        }

        const items = _.flatten(
            currentMenuItems.map((item) => {
                if (_.isFunction(item)) {
                    return item();
                }
                return item;
            })
        );
        appendItems(ul, items);
    };

    const windowHandler = function (event) {
        // eslint-disable-next-line no-magic-numbers
        if (event.type === "click" && event.button === 2) {
            return false;
        }

        if (ignoreEvents) {
            event.preventDefault();
            event.stopPropagation();
            return false;
        }

        closeMenu(event);

        if (currentMenuItems.length === 0) {
            return false;
        }

        displayMenu(currentMenuItems, event);
        return false;
    };

    /**
     * Add a click listener for the specified element. A click will render a
     * context menu containing the provided actions, which is an array of objects
     * each having a label and an action function.
     *
     * The function returns an ID which can be used to remove the listener later.
     *
     * Example:
     * ContextMenu.addListener(element, [{
     *      key: 'item.one',
     *      label: 'Item 1',
     *      action: function () {
     *          alert('You clicked item 1.');
     *      }
     *  }]);
     **/
    const addListener = function (element, menuItems, clickType = ContextMenu.LEFT_CLICK) {
        const id = nextId;
        nextId++;

        const listenerFunction = function (event) {
            event.preventDefault();
            currentListeners = currentListeners.concat(id);

            if (currentMenuItems.length === 0) {
                currentMenuItems = [].concat(menuItems);
                return;
            }

            const existingKeys = _.pluck(currentMenuItems, "key");
            _.asArray(menuItems).forEach((item) => {
                const index = existingKeys.indexOf(item.key);
                if (index > -1) {
                    currentMenuItems[index] = item;
                } else {
                    currentMenuItems.push(item);
                }
            });
        };

        // Add element listeners to show menu
        if (clickType === ContextMenu.LEFT_CLICK || clickType === ContextMenu.ANY_CLICK) {
            element.removeEventListener("click", listenerFunction, true);
            element.addEventListener("click", listenerFunction, true);
        }
        if (clickType === ContextMenu.RIGHT_CLICK || clickType === ContextMenu.ANY_CLICK) {
            element.removeEventListener("contextmenu", listenerFunction, true);
            element.addEventListener("contextmenu", listenerFunction, true);
        }

        // If this is the first registered menu, add window close listeners
        if (listeners.length === 0) {
            window.addEventListener("click", windowHandler, false);
            window.addEventListener("contextmenu", windowHandler, false);
            window.addEventListener("keyup", escapeHandler, false);
        }

        const info = {
            id,
            element,
            listener: listenerFunction,
            clickType,
        };

        listeners.push(info);
        return info.id;
    };

    const getIndexForId = function (id) {
        let i;
        for (i = 0; i < listeners.length; i++) {
            if (listeners[i].id === id) {
                return i;
            }
        }

        return -1;
    };

    /**
     * Remove a click listener based on the ID returned when adding it.
     **/
    const removeListener = function (id) {
        const index = getIndexForId(id);
        if (index === -1) {
            return;
        }

        let data = listeners.splice(index, 1);
        data = data[0];
        data.element.removeEventListener("click", data.listener, true);
        data.element.removeEventListener("contextmenu", data.listener, true);

        // If the last menu has been deregistered, remove window close listeners
        if (listeners.length === 0) {
            window.removeEventListener("click", windowHandler, false);
            window.removeEventListener("contextmenu", windowHandler, false);
            window.removeEventListener("keyup", escapeHandler, false);
        }
    };

    return {
        addListener,
        removeListener,
        displayMenu,
        update,
        isActive() {
            return Boolean(document.querySelector("#contextMenu"));
        },
        LEFT_CLICK: 0,
        RIGHT_CLICK: 1,
        ANY_CLICK: 2,
    };
})();

export default ContextMenu;
