const EXAM_MODE = {
    CREATE: "create",
    EDIT: "edit",
    MOVE: "move",
};

const EXAM_NOT_AUTHORIZED = 401;
const EXAM_NOT_FOUND = 404;

export default { EXAM_MODE, EXAM_NOT_AUTHORIZED, EXAM_NOT_FOUND };
