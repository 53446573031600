import {
    MillenniumDate,
    MillenniumWeek,
    MillenniumWeekday,
    SimpleDateFormat,
} from "@timeedit/millennium-time";
import { PeriodHeader } from "./PeriodHeader";

import Language from "../lib/Language";
import _ from "underscore";

const DAYS_IN_WEEK = 7;

const getWeekLimits = (limits) => ({
    start: new MillenniumWeek(
        limits.getStartDate(),
        Language.firstDayOfWeek,
        Language.daysInFirstWeek
    ),
    end: new MillenniumWeek(limits.getEndDate(), Language.firstDayOfWeek, Language.daysInFirstWeek),
});

export class WeekdayPeriodHeader extends PeriodHeader {
    weeks: any[];
    futureWeeksOnly: boolean;
    id: any;
    name: any;
    names: any;
    size: any;

    limits: any;

    constructor(visibleValues, firstVisibleValue, subheader?) {
        super(visibleValues || 1, firstVisibleValue, subheader, "WeekdayPeriodHeader");
        this.weeks = [];
        this.futureWeeksOnly = false;
    }

    getKind() {
        return PeriodHeader.KIND.WEEKDAY;
    }

    getSettings(providers) {
        const settings = super.getSettings();
        const self = this;

        const labels = Language.getWeekdayLabels();
        settings.items.push({
            id: "period",
            label: Language.get("cal_list_weekdays"),
            type: "groups",
            availableValues() {
                return MillenniumWeekday.getLocalizedWeekdayList(Language.firstDayOfWeek).map(
                    (weekday) => ({ value: weekday.getDay(), label: labels[weekday.getDay()] })
                );
            },
            get() {
                return {
                    names: self.names,
                    values: self.values?.map((period) => period.map((item) => item.getDay())),
                };
            },
            set(groups) {
                return self.immutableSet({
                    names: groups.names,
                    values: groups.values.map((period) =>
                        period.map((item) => MillenniumWeekday.getByWeekdayNumber(item))
                    ),
                });
            },
        });

        if (providers.week === true) {
            return settings;
        }

        settings.items.push({
            id: "weeks",
            label: Language.get("cal_list_weeks"),
            type: "array",
            limit: 0,
            get: self.getWeeks.bind(self),
            set(val) {
                return self.immutableSet({ weeks: val });
            },
        });

        return settings;
    }

    getSimplePeriodIndex(entry, onlyVisible = false) {
        if (!this.isSimplePeriod()) {
            throw new Error("Cannot use getSimplePeriodIndex if period is not a simple period.");
        }

        const isMatch = (value) =>
            value.getAbsoluteWeekdayNumber() === entry.startTimes[0].getDay();
        const index = _.flatten(this.getValues()).findIndex(isMatch);
        if (!onlyVisible) {
            return index;
        }
        return index - this.firstVisibleValue;
    }

    getIndexOfDate(date, onlyVisible = false) {
        const weekday = date.getDay(); // 0 (Sunday) to 6 (Saturday)
        // Not found if weekday not displayed
        const values = onlyVisible ? this.getVisibleValues() : this.values;
        return values.findIndex((weekdays) => {
            const days = weekdays.map((wd) => wd.getAbsoluteWeekdayNumber());
            return days.indexOf(weekday) !== -1;
        });
    }

    getWeeks(customWeekNames = []) {
        const values: any[] = [];

        const startDay = this.limits.getStartDate().getDayNumber();
        const weekLimits = getWeekLimits(this.limits);
        for (let day = startDay; day < startDay + this.limits.dayCount; day = day + DAYS_IN_WEEK) {
            const week = new MillenniumWeek(
                new MillenniumDate(day),
                Language.firstDayOfWeek,
                Language.daysInFirstWeek
            );
            const label =
                getCustomWeekName(week, customWeekNames) ||
                SimpleDateFormat.format(
                    week.getStartOfWeek(),
                    Language.getDateFormat("date_f_yyyy_ww_l")
                );
            if (week.week() >= weekLimits.start.week() && week.week() <= weekLimits.end.week()) {
                values.push({
                    value: week,
                    label,
                });
            }
        }

        return values.map((item) =>
            _.extend(item, {
                selected: this.weeks.some((week) => week.week() === item.value.week()),
            })
        );
    }

    periodsToJSON() {
        const weekdayList = MillenniumWeekday.getLocalizedWeekdayList(Language.firstDayOfWeek);
        return _.flatten(
            this.values?.map((weekdays) =>
                weekdays.map((weekday) =>
                    weekdayList.findIndex(
                        (element) => element.absoluteWeekdayNumber === weekday.absoluteWeekdayNumber
                    )
                )
            ) // Return relative weekday numbers, first day of week being 0
        );
    }

    toJSON() {
        const json = PeriodHeader.prototype.toJSON.call(this);
        return _.extend(json, {
            dayProvider: true,
            kind: "periodweekday",
            periods: this.values?.map((weekdays) =>
                weekdays.map((weekday) => weekday.getRepresentation())
            ),
            weeks: this.weeks.map((week) => `20${week.week()}`),
            futureWeeksOnly: this.futureWeeksOnly,
        });
    }
}

// eslint-disable-next-line no-unused-vars

const getCustomWeekName = (week, customWeekNames) => {
    const name = _.find(
        customWeekNames,
        (customWeek) => customWeek.dayNumber === week.date.dayNumber
    );
    if (name) {
        return name.getLongestName();
    }
    return null;
};
