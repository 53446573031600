import Language from "../lib/Language";
import IntegerInput from "./IntegerInput";

const MassMoveTimeSelect = (props) => (
    <div className="massMoveTimeSelect">
        <p className="headline">{Language.get("nc_mass_move_adjust_time_headline")}</p>
        <div>
            <div>
                <input
                    type="radio"
                    value={false}
                    checked={!props.hoursAndMinutesAreForward}
                    onChange={props.onDirectionChanged}
                />
                <span>{Language.get("nc_mass_move_backward")}</span>
                <br />
                <input
                    type="radio"
                    value={true}
                    checked={props.hoursAndMinutesAreForward}
                    onChange={props.onDirectionChanged}
                />
                <span>{Language.get("nc_mass_move_forward")}</span>{" "}
            </div>
            <div>
                {Language.get("nc_summary_hours")}{" "}
                <IntegerInput onUpdate={props.onHoursChanged} defaultValue={props.hours} /> <br />
                {Language.get("nc_summary_minutes")}{" "}
                <IntegerInput onUpdate={props.onMinutesChanged} defaultValue={props.minutes} />{" "}
            </div>
        </div>
    </div>
);

export default MassMoveTimeSelect;
