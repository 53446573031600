import React from "react";

const TemplateGroupList = (props) => {
    const templateGroupChanged = (event) => {
        const object = parseInt(event.target.value, 10);
        props.onObjectSelect(object);
    };

    return (
        <select
            disabled={props.disabled || false}
            value={props.value}
            onChange={templateGroupChanged}
        >
            {props.templateGroups.map((object) => (
                <option key={object.id} value={object.id}>
                    {object.name}
                </option>
            ))}
        </select>
    );
};

export default TemplateGroupList;
