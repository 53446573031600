import _ from "underscore";
import { Model } from "./Model";
import API from "../lib/TimeEditAPI";

export class Menu extends Model {
    activeMenu: string;
    items: any;
    isOpen: boolean;
    static POSITION = {
        LEFT: "left",
        RIGHT: "right",
    };

    constructor(items) {
        super("Menu");
        this.activeMenu = "views";
        this.items = items || [];
        this.isOpen = true;
    }

    getCurrentItem() {
        return _.find(this.items, (element) => element.id === this.activeMenu);
    }

    setMenuItems(items) {
        return this.immutableSet({
            items,
        });
    }

    updateFromPreferences(callback) {
        API.getPreferences("activeMenu", (activeMenu) => {
            if (_.isNullish(activeMenu)) {
                // eslint-disable-next-line no-param-reassign
                activeMenu = "views";
            }
            API.getPreferences("menuOpen", (isOpen) => {
                if (_.isNullish(isOpen)) {
                    // eslint-disable-next-line no-param-reassign
                    isOpen = true;
                }
                callback(
                    this.immutableSet({
                        activeMenu: activeMenu || this.items[0].id,
                        isOpen,
                    })
                );
            });
        });
    }

    close() {
        API.setPreferences("menuOpen", [false], _.noop);
        return this.immutableSet({
            isOpen: false,
        });
    }

    open(id) {
        API.setPreferences("activeMenu", [id], _.noop);
        API.setPreferences("menuOpen", [true], _.noop);
        return this.immutableSet({
            activeMenu: id,
            isOpen: true,
        });
    }
}
