import React from "react";
import { Macros } from "../models/Macros";

import Language from "../lib/Language";
import CancellationList from "./CancellationList";
import ListCalendar from "./ListCalendar";

import { Tutorial } from "../lib/Tutorial";

class ReservationListCalendar extends React.Component {
    private _settingsButton: any;
    private _wrapper: any;

    componentDidMount() {
        this.showTutorial();
    }

    showTutorial = () => {
        Tutorial.show("cancellationList", [
            {
                intro: Language.get("nc_tutorial_cancellation_list_intro"),
                element: this._wrapper,
            },
            {
                intro: Language.get("nc_tutorial_cancellation_list_search_settings"),
                element: this._settingsButton,
            },
            {
                intro: Language.get("nc_tutorial_cancellation_list_restore_cancellations"),
                element: this._wrapper,
            },
        ]);
    };

    getRefreshEvents = () => [
        Macros.Event.SELECT_RESERVATION,
        Macros.Event.RESERVATION_MADE_OR_MODIFIED,
    ];

    onSelection = (selection) => {
        this.props.onInfoOpen([selection.id], false);
    };

    render() {
        return (
            <ListCalendar
                {...this.props}
                type={"cancellationList"}
                refreshEvents={this.getRefreshEvents()}
                settingsRef={(node) => {
                    this._settingsButton = node;
                }}
                listRef={(node) => {
                    this._wrapper = node;
                }}
            >
                <CancellationList onSelection={this.onSelection} />
            </ListCalendar>
        );
    }
}

export default ReservationListCalendar;
