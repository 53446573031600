import React from "react";
import Accordion from "./Accordion";
import Language from "../lib/Language";
import API from "../lib/TimeEditAPI";
import Log from "../lib/Log";
import _ from "underscore";

class EmailDialog extends React.Component {
    state = {
        addresses: {},
        ccMe: true,
        ccCreator: false,
        to: "",
        subject: "",
        message: "",
        fields: [],
        uncheckedEmailFields: [],
    };

    componentDidMount() {
        let results = {};
        const calls = [
            (done) => {
                API.getPreferencesMulti(["uncheckedEmailFields", "ccMe", "ccCreator"], (result) => {
                    results = {
                        uncheckedEmailFields: result.uncheckedEmailFields
                            ? JSON.parse(result.uncheckedEmailFields) || []
                            : [],
                        ccMe: result.ccMe,
                        ccCreator: result.ccCreator,
                    };
                    done();
                });
            },
            (done) => {
                API.getEmailReservationPrefs(this.props.reservations, (emailSettings) => {
                    results.ok = emailSettings.ok;
                    results.addresses = {
                        user: emailSettings.ccMe,
                        reserver: emailSettings.ccReserver,
                        from: emailSettings.from,
                        replyTo: emailSettings.replyTo,
                    };
                    results.to = []
                        .concat(emailSettings.toObjects)
                        .concat(emailSettings.toMembers)
                        .join(", ");
                    results.subject = emailSettings.subject;
                    results.message = emailSettings.message;
                    results.fields = emailSettings.fields;
                    done();
                });
            },
        ];
        _.runSync(calls, () => {
            if (!results.ok) {
                Log.warning(Language.get("nc_no_reservation_with_id.", this.props.reservations[0]));
                this.props.onCancel();
                return;
            }
            this.setState(results);
        });
    }

    getCheckedFieldIds = () =>
        this.state.fields
            .map((field) => field.id)
            .filter((id) => !_.contains(this.state.uncheckedEmailFields, id));

    isFieldChecked = (field) => {
        const id = _.isNumber(field) ? field : field.id;
        return !_.contains(this.state.uncheckedEmailFields, id);
    };

    // Return a data object ready for sendEmailReservation
    getData = () => {
        let to = this.state.to.split(/[,;\n\r]/);
        to = to.map((address) => address.trim()).filter((address) => address !== "");

        let cc = [];
        if (this.state.ccMe) {
            cc = cc.concat(this.state.addresses.user);
        }
        if (this.state.ccCreator) {
            cc = cc.concat(this.state.addresses.reserver);
        }

        return {
            from: this.state.addresses.from,
            to,
            cc,
            replyTo: this.state.addresses.replyTo,
            subject: this.state.subject,
            message: this.state.message,
            reservationIds: this.props.reservations,
            fieldIds: this.getCheckedFieldIds(),
        };
    };

    onFinished = (e) => {
        API.sendEmailReservation(this.getData(), (error) => {
            if (error) {
                let message = error.message || Language.get("cal_res_side_email_send_fail_message");
                if (error.details) {
                    message = `${message} (${error.details})`;
                }

                Log.warning(message);
                return;
            }

            this.props.onFinished();
        });

        e.preventDefault();
    };

    onCancel = (e) => {
        this.props.onCancel(e);
        e.preventDefault();
    };

    onSubjectChange = (event) => {
        this.setState({ subject: event.target.value });
    };

    onMessageChange = (event) => {
        this.setState({ message: event.target.value });
    };

    onToChange = (event) => {
        this.setState({ to: event.target.value });
    };

    onCCMeChange = (event) => {
        this.setState({ ccMe: event.target.checked });
        API.setPreferences("ccMe", [event.target.checked], _.noop);
    };

    onCCCreatorChange = (event) => {
        this.setState({ ccCreator: event.target.checked });
        API.setPreferences("ccCreator", [event.target.checked], _.noop);
    };

    onFieldChanged = (fieldId, event) => {
        let uncheckedEmailFields = [].concat(this.state.uncheckedEmailFields);

        if (event.target.checked) {
            uncheckedEmailFields = uncheckedEmailFields.filter((id) => id !== fieldId);
        } else {
            uncheckedEmailFields = uncheckedEmailFields.concat(fieldId);
        }

        API.setPreferences("uncheckedEmailFields", [JSON.stringify(uncheckedEmailFields)], _.noop);
        this.setState({ uncheckedEmailFields });
    };

    _renderTable = () => {
        let ccMeCheckbox = null;
        if (this.state.addresses.user) {
            ccMeCheckbox = (
                <tr key={"ccMe"}>
                    <td>
                        <input
                            type="checkbox"
                            checked={this.state.ccMe}
                            onChange={this.onCCMeChange}
                        />
                        {Language.get("cal_res_side_email_cc_mysqlf")}
                    </td>
                </tr>
            );
        }

        let ccCreatorCheckbox = null;
        if (this.state.addresses.reserver && this.state.addresses.reserver.length > 0) {
            ccCreatorCheckbox = (
                <tr key={"ccCreator"}>
                    <td>
                        <input
                            type="checkbox"
                            checked={this.state.ccCreator}
                            onChange={this.onCCCreatorChange}
                        />
                        {Language.get("cal_res_side_email_cc_creator")}
                    </td>
                </tr>
            );
        }

        let includeFieldsHeadline = null;
        if (this.state.fields.length > 0) {
            includeFieldsHeadline = (
                <tr>
                    <th className="fieldLabel">
                        {Language.get("cal_res_side_email_fields_to_include")}
                    </th>
                </tr>
            );
        }
        return (
            <table>
                <tbody>
                    <tr>
                        <th className="fieldLabel">{Language.get("cal_res_side_email_to")}</th>
                    </tr>
                    <tr>
                        <td>
                            <textarea
                                className="field"
                                rows="5"
                                value={this.state.to}
                                onChange={this.onToChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th className="fieldLabel">{Language.get("cal_res_side_email_subject")}</th>
                    </tr>
                    <tr>
                        <td>
                            <input
                                className="field"
                                type="text"
                                value={this.state.subject}
                                onChange={this.onSubjectChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th className="fieldLabel">{Language.get("cal_res_side_email_message")}</th>
                    </tr>
                    <tr>
                        <td>
                            <textarea
                                className="field"
                                rows="5"
                                value={this.state.message}
                                onChange={this.onMessageChange}
                            />
                        </td>
                    </tr>
                    {ccMeCheckbox}
                    {ccCreatorCheckbox}
                    {includeFieldsHeadline}
                    {this.state.fields.map((field) => (
                        <tr key={field.id}>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={this.isFieldChecked(field.id)}
                                    onChange={this.onFieldChanged.bind(this, field.id)}
                                />
                                {field.name}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    render() {
        const sections = [
            {
                title: Language.get("cal_res_side_email_header", this.props.reservations.length),
                content: this._renderTable(),
            },
        ];

        return (
            <form className="emailDialog" onSubmit={this.onFinished}>
                <Accordion sections={sections} />
                <button type="submit" className="save">
                    {Language.get("cal_res_side_email_send")}
                </button>
                <button className="cancel" onClick={this.onCancel}>
                    {Language.get("menu_close")}
                </button>
            </form>
        );
    }
}

export default EmailDialog;
