/* eslint-disable react/prefer-stateless-function */
import React from "react";
import Language from "../lib/Language";
import ScratchpadList from "./ScratchpadList";

class PinboardPane extends React.Component {
    render() {
        const onObjectSelected = (object) => {
            console.log(object);
            if (this._isRemoveClick) {
                this.props.scratchpad.removeObjects([object.id]);
                this.setState({
                    scratchpadObjects: this.props.scratchpad.getObjects(),
                });
            }
            this._isRemoveClick = false;
        };
        const unpinButton = (
            <button
                className="unpinButton"
                onClick={() => {
                    this._isRemoveClick = true;
                }}
                title={Language.get("nc_remove_from_pinboard")}
            />
        );
        return (
            <div className="pinboardPane">
                <h2>{Language.get("nc_pinboard")}</h2>
                <p>
                    {Language.get("nc_pinboard_info")}
                    <br />
                    <a
                        target="_blank"
                        href="https://academy.timeedit.com/guides-tutorials/pinboard"
                    >
                        {Language.get("nc_pinboard_on_te_academy")}
                    </a>
                </p>
                <ScratchpadList
                    flex={true}
                    showExtraInfo={this.props.showExtraInfo}
                    objects={this.props.scratchpad.getObjects()}
                    onObjectSelected={onObjectSelected}
                    allowMultiSelection={true}
                    hoverButton={unpinButton}
                />
            </div>
        );
    }
}

export default PinboardPane;
