export const TimeConstants = {
    SECONDS_PER_HOUR: 3600,
    MILLISECONDS_PER_HOUR: 3600000,
    MINUTES_PER_HOUR: 60,
    SECONDS_PER_MINUTE: 60,
    DAYS_PER_WEEK: 7,
    HOURS_PER_DAY: 24,
    SECONDS_PER_DAY: 86400,
    MIN_WEEKS_PER_YEAR: 52,
    UNIX_MILLENNIUM_OFFSET: 946684800,
    MS_PER_SECOND: 1000,
} as const;
