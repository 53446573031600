import PropTypes from "prop-types";
import React from "react";
import API from "../lib/TimeEditAPI";
import {
    MillenniumDate,
    MillenniumDateTime,
    MillenniumTime,
    SimpleDateFormat,
} from "@timeedit/millennium-time";
import OrderListSettings from "./OrderListSettings";
import Language from "../lib/Language";
import { Header } from "../models/Header";
const Size = Header.Label;
import _ from "underscore";
import GenericList from "./GenericList";
import { TimeConstants as TC } from "../lib/TimeConstants";
import RC from "../lib/ReservationConstants";
import { TimeEdit } from "../lib/TimeEdit";
import Log from "../lib/Log";
import OrderUtils from "../lib/OrderUtils";

const SETTINGS_TO_SAVE = [
    "allOrders",
    "beginTime",
    "columnWidths",
    "complete",
    "endTime",
    "factor",
    "objectSearch",
    "orderStatus",
    "permission",
    "selectedColumns",
    "selectedInfoColumns",
    "useFactor",
    "includeMembers",
    "objectCategories",
    "descriptionField",
    "commentField",
];

const LABEL_SIZE_CUTOFFS = {
    MINIMUM: 10,
    XS: 30,
    S: 40,
    M: 60,
    L: 110,
};

const COL_IDS = {
    ORDER_ID: 1,
    ID: 2,
    EXTID: 3,
    DATE: 4,
    START_DATE: 5,
    ORDERED_TIME: 16,
    DELIVERED_TIME: 19,
    REMAINDER_TIME: 22,
    ORDER_OBJECTS: 30,
};

const HUNDRED_PERCENT = 100;

const getAvailableColumns = () => [
    {
        name: Language.get("dynamic_reserv_list_order_id"),
        primary: true,
        sortable: false,
        id: COL_IDS.ORDER_ID,
        defaultWidth: 100,
        textAlign: "right",
    },
    {
        name: Language.get("cal_reservation_list_column_id"),
        primary: true,
        sortable: true,
        id: COL_IDS.ID,
        defaultWidth: 100,
        textAlign: "right",
    },
    {
        name: Language.get("dynamic_reserv_list_ext_id"),
        primary: true,
        sortable: false,
        id: COL_IDS.EXTID,
        defaultWidth: 100,
    },
    {
        name: Language.get("cal_reservation_list_column_date"),
        primary: true,
        sortable: true,
        id: COL_IDS.DATE,
    },
    {
        name: Language.get("cal_reservation_list_column_start_date"),
        primary: false,
        sortable: true,
        id: COL_IDS.START_DATE,
    },
    {
        name: Language.get("cal_reservation_list_column_end_date"),
        primary: false,
        sortable: false,
        id: 6,
    },
    {
        name: Language.get("cal_reservation_list_column_week_day"),
        primary: false,
        sortable: false,
        id: 7,
    },
    {
        name: Language.get("cal_reservation_list_column_start_week_day"),
        primary: false,
        sortable: false,
        id: 8,
    },
    {
        name: Language.get("cal_reservation_list_column_end_week_day"),
        primary: false,
        sortable: false,
        id: 9,
    },
    {
        name: Language.get("cal_reservation_list_column_week"),
        primary: false,
        sortable: false,
        id: 10,
    },
    {
        name: Language.get("cal_reservation_list_column_start_week"),
        primary: false,
        sortable: false,
        id: 11,
    },
    {
        name: Language.get("cal_reservation_list_column_end_week"),
        primary: false,
        sortable: false,
        id: 12,
    },
    {
        name: Language.get("cal_reservation_list_column_status"),
        primary: true,
        sortable: false,
        id: 13,
    },
    {
        name: Language.get("dynamic_reserv_list_ordered_count"),
        primary: true,
        sortable: false,
        id: 15,
        textAlign: "right",
    },
    {
        name: Language.get("dynamic_reserv_list_ordered_time"),
        primary: true,
        sortable: false,
        id: COL_IDS.ORDERED_TIME,
        textAlign: "right",
        defaultWidth: 100,
    },
    {
        name: Language.get("dynamic_reserv_list_delivered_count"),
        primary: true,
        sortable: false,
        id: 18,
        textAlign: "right",
    },
    {
        name: Language.get("dynamic_reserv_list_delivered_time"),
        primary: true,
        sortable: false,
        id: COL_IDS.DELIVERED_TIME,
        textAlign: "right",
        defaultWidth: 100,
    },
    {
        name: Language.get("dynamic_reserv_list_remainder_count"),
        primary: true,
        sortable: false,
        id: 21,
        textAlign: "right",
    },
    {
        name: Language.get("dynamic_reserv_list_remainder_time"),
        primary: true,
        sortable: false,
        id: COL_IDS.REMAINDER_TIME,
        textAlign: "right",
        defaultWidth: 100,
    },
    {
        name: Language.get("dynamic_reserv_list_percent"),
        primary: false,
        sortable: false,
        id: 23,
        textAlign: "right",
    },
    {
        name: Language.get("dynamic_reserv_list_percent_count"),
        primary: false,
        sortable: false,
        id: 24,
        textAlign: "right",
    },
    {
        name: Language.get("dynamic_reserv_list_percent_time"),
        primary: false,
        sortable: false,
        id: 25,
        textAlign: "right",
    },
    { name: Language.get("dynamic_reserv_list_title"), primary: true, sortable: false, id: 26 },
    {
        name: Language.get("dynamic_reserv_list_mandatory_object"),
        primary: true,
        sortable: false,
        id: 27,
    },
    {
        name: Language.get("cal_reservation_list_column_type_order_objects_all"),
        primary: false,
        sortable: false,
        id: 28,
    },
    {
        name: Language.get("dynamic_reserv_list_order_objects"),
        primary: true,
        sortable: false,
        id: COL_IDS.ORDER_OBJECTS,
    },
    { name: Language.get("nc_other_info"), primary: false, sortable: false, id: 31 },
    {
        name: Language.get("nc_order_list_order_description"),
        primary: true,
        sortable: false,
        id: 32,
    },
    { name: Language.get("nc_other_comment"), primary: false, sortable: false, id: 33 },
];

class OrderList extends React.Component {
    static defaultProps = {
        totalWidth: 0,
    };

    static contextTypes = {
        user: PropTypes.object,
        update: PropTypes.func,
        customWeekNames: PropTypes.array,
    };

    getDefaultSearchOptions(timeLimit) {
        return {
            objectSearch: [0],
            searchString: "",
            beginTime: timeLimit.begin,
            endTime: timeLimit.end,
            allOrders: this.ALL,
            orderStatus: OrderListSettings.getDefaultOrderStatus(),
            complete: true,
            permission: RC.PERMISSION.MODIFY,
            factor: 1, // The number of weeks to divide times by
            includeMembers: 0,
            objectCategories: [],
            descriptionField: null,
            commentField: null,
        };
    }

    constructor(props, context) {
        super(props, context);
        const timeLimit = this.getTimeLimits();

        this.state = {
            searchOptions: this.getDefaultSearchOptions(timeLimit),
            availableObjectCategories: [],
            availableObjectCheckboxes: [],
            reservationFields: [],
        };
    }

    componentDidMount() {
        API.getObjectCategories((categories) => {
            API.findReservationFields((reservationFields) => {
                this.setState(_.extend({}, categories, { reservationFields }));
            });
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data.limits !== this.props.data.limits) {
            const timeLimits = this.getTimeLimits();
            if (
                timeLimits.begin !== this.state.searchOptions.beginTime ||
                timeLimits.endTime !== this.state.searchOptions.endTime
            ) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({
                    searchOptions: _.extend({}, this.state.searchOptions, {
                        beginTime: timeLimits.begin,
                        endTime: timeLimits.end,
                    }),
                });
            }
        }
    }

    onSearchSettingsChange = (settings, cb) => {
        this.setState(
            {
                searchOptions: _.extend(
                    {},
                    this.state.searchOptions,
                    _.omit(settings, (value) => value === undefined)
                ),
            },
            cb
        );
    };

    getTimeLimits = () => {
        try {
            return {
                begin: MillenniumDateTime.fromDate(this.props.data.limits.getStartDate()).getMts(),
                end: MillenniumDateTime.fromDate(this.props.data.limits.getEndDate()).getMts(),
            };
        } catch (error) {
            return {
                begin: MillenniumDateTime.fromDate(MillenniumDate.today()).getMts(),
                end: 0,
            };
        }
    };

    getSelection = () => {
        if (this.props.data.privateSelected) {
            return this.props.data.selection;
        }
        return this.props.selection;
    };

    getActiveFluffy = () => {
        const selection = this.getSelection();
        if (selection.fluffy) {
            return selection.fluffy;
        }
        return null;
    };

    getFluffyObjects = () => {
        const fluffy = this.getActiveFluffy();
        if (fluffy === null) {
            return [];
        }
        return fluffy.objectItems
            .filter((object) => object.object && object.object.id !== 0)
            .map((object) => ({
                id: object.object.id,
                type: object.type.id,
                title: object.objectText,
            }));
    };

    getSortOrder = (sortOrder, sortColumn) => {
        // typedef enum { ORDER_ROW_SORT_ID_ASC, ORDER_ROW_SORT_ID_DESC, ORDER_ROW_SORT_TIME_ASC, ORDER_ROW_SORT_TIME_DESC } t_order_row_sort_order;
        if (sortColumn === COL_IDS.ID) {
            // ID
            return sortOrder;
        }
        if (
            sortColumn === COL_IDS.DATE || // Date
            sortColumn === COL_IDS.START_DATE
        ) {
            // Start date
            if (sortOrder === 0) {
                return OrderUtils.SORT_ORDERS.ORDER_ROW_SORT_TIME_ASC;
            }
            return OrderUtils.SORT_ORDERS.ORDER_ROW_SORT_TIME_DESC;
        }
        return sortOrder;
    };

    getStatus = () => {
        const statuses = [].concat(this.state.searchOptions.orderStatus);
        const replaceIfBoth = function (array, first, second, replacement) {
            const firstIndex = array.indexOf(first);
            const secondIndex = array.indexOf(second);
            if (firstIndex !== -1 && secondIndex !== -1) {
                array.splice(firstIndex, 1);
                array.splice(array.indexOf(second), 1);
                array.push(replacement);
            }
        };
        // eslint-disable-next-line no-magic-numbers
        replaceIfBoth(statuses, 101, 102, 100);
        // eslint-disable-next-line no-magic-numbers
        replaceIfBoth(statuses, 111, 112, 110);
        return statuses;
    };

    getDataMenuItems = (selectedOrderIds, getDataAtIndex, callback, onMassReplace) => {
        const items = [];

        if (selectedOrderIds.length === 0) {
            items.push({
                label: Language.get("nc_no_order_selected"),
                isDisabled: true,
                emphasize: true,
                action: _.noop,
            });
        }

        items.push([
            {
                label: Language.get("menu_view_info"),
                isDisabled: selectedOrderIds.length === 0,
                action: () => {
                    this.props.onInfoOpen(selectedOrderIds, true);
                },
                classNames: ["icon", "showInfo"],
            },
        ]);

        if (this.props.getDataMenuItems) {
            items.push(
                this.props.getDataMenuItems(
                    selectedOrderIds,
                    getDataAtIndex,
                    callback,
                    onMassReplace
                )
            );
        }
        return items;
    };

    getObjectSearchSettings = (searchObjects) => {
        const objectSearch = this.state.searchOptions.objectSearch;
        const categories = this.state.searchOptions.objectCategories;

        let so = [].concat(searchObjects);
        if (categories && _.some(Object.keys(categories), (key) => categories[key].length > 0)) {
            so = so.concat(
                Object.keys(categories).map((key) => ({
                    fieldId: parseInt(key, 10),
                    type: TimeEdit.rootType,
                    categories: categories[key],
                }))
            );
        }

        return {
            modes: objectSearch,
            mandatoryObjects: objectSearch.indexOf(1) !== -1,
            // eslint-disable-next-line no-magic-numbers
            customerObjects: objectSearch.indexOf(2) !== -1,
            // eslint-disable-next-line no-magic-numbers
            ownerObjects: objectSearch.indexOf(3) !== -1,
            orderObjects: objectSearch.indexOf(0) !== -1,
            // eslint-disable-next-line no-magic-numbers
            otherObjects: objectSearch.indexOf(4) !== -1,
            objects: so,
            permission: this.state.searchOptions.permission,
            includeMembers: this.state.searchOptions.includeMembers,
        };
    };

    find = (firstIndex, searchObjects, columns, sortOrder, sortColumn, cb) => {
        const orderSortOrder = this.getSortOrder(sortOrder, sortColumn);
        const os = this.getObjectSearchSettings(searchObjects);

        const mapObjects = (enabled, objectList) => {
            if (!enabled) {
                return [];
            }
            return objectList.map((obj) => (obj.id ? obj.id : obj));
        };

        const MAX_SEARCH_OBJECTS = 6;
        if (
            os.objects.length > MAX_SEARCH_OBJECTS ||
            // eslint-disable-next-line no-magic-numbers
            (os.orderObjects && os.otherObjects && os.objects.length > 3)
        ) {
            Log.error(Language.get("nc_object_search_too_many_search_objects"));
            return;
        }

        const query = {
            searchString: this.state.searchOptions.searchString,
            objectSearchModes: os.modes,
            beginTime: new MillenniumDateTime(this.state.searchOptions.beginTime).getMts(),
            endTime: new MillenniumDateTime(this.state.searchOptions.endTime)
                .getEndOfDay()
                .getMts(),
            modifiedBeginTime: null,
            modifiedEndTime: null,
            mandatoryObjects: mapObjects(os.mandatoryObjects, os.objects),
            customerObjects: mapObjects(os.customerObjects, os.objects),
            ownerObjects: mapObjects(os.ownerObjects, os.objects),
            orderObjects: os.orderObjects ? os.objects : [],
            otherObjects: os.otherObjects ? os.objects : [],
            orderIds: [],
            status: this.getStatus(),

            userId: null,

            sortOrder: orderSortOrder,
            includeObjectNames: true,

            // True if orders allowed to modify are sought, false if allowed to read
            permission: os.permission === 1,

            includeMembers: os.includeMembers,

            startRow: firstIndex,
            numberOfRows: 100,
        };

        API.findOrderRowsList(query, (result) => {
            if (result.parameters[4] === false) {
                Log.warning(result.parameters[5]);
            }

            const numFound = result.parameters[1];
            const newData = result.parameters[0];
            const beginTime = result.parameters ? result.parameters[2].datetime : null;
            const endTime = result.parameters ? result.parameters[3].datetime : null;
            const searchStart =
                beginTime < TC.SECONDS_PER_DAY
                    ? MillenniumDateTime.fromDate(new MillenniumDate(beginTime))
                    : new MillenniumDateTime(beginTime);
            const searchEnd =
                endTime < TC.SECONDS_PER_DAY
                    ? MillenniumDateTime.fromDate(new MillenniumDate(endTime))
                    : new MillenniumDateTime(endTime);

            cb(newData, numFound, searchStart, searchEnd);
        });
    };

    onSelection = (order) => {
        API.getOrderRows({ orderRowIds: [order.id], createObjectsWithNames: true }, (result) => {
            this.props.onSelection(
                result.parameters[0][0],
                this.state.searchOptions.descriptionField,
                this.state.searchOptions.commentField
            );
        });
    };

    getCellLabels = (order, columns, columnWidths) => {
        const self = this;

        let color = false;
        if (order) {
            const isStarted =
                (order.delivered_length !== undefined && order.delivered_length !== 0) ||
                (order.delivered_count !== undefined && order.delivered_count !== 0);
            const status = this.getStatusObject(order.status.status, isStarted);
            color = status.color;
        }

        const getJoinedFieldValues = (fields = []) =>
            _.flatten(_.compact(_.pluck(fields, "values"))).join(", ");

        const labels = columns.map((column, idx) => {
            const width = columnWidths[idx];
            if (!order) {
                return "";
            }
            if (column.name === Language.get("cal_reservation_list_column_id")) {
                return order.id;
            }
            if (column.name === Language.get("dynamic_reserv_list_order_id")) {
                return order.id - (order.id % OrderUtils.ORDER_ID_OFFSET);
            }
            if (column.name === Language.get("dynamic_reserv_list_ext_id")) {
                return order.extid;
            }
            if (column.name === Language.get("dynamic_reserv_list_title")) {
                return order.order_title;
            }

            if (column.name === Language.get("dynamic_reserv_list_mandatory_object")) {
                if (!order.order_required_object) {
                    return "";
                }
                return getJoinedFieldValues(order.order_required_object.fields);
            }
            if (column.name === Language.get("dynamic_reserv_list_order_objects")) {
                return order.objects
                    .map((object) => getJoinedFieldValues(object.fields))
                    .join(", ");
            }
            if (
                column.name === Language.get("cal_reservation_list_column_type_order_objects_all")
            ) {
                const requiredId = order.order_required_object ? order.order_required_object.id : 0;
                return order.objects
                    .filter((obj) => obj.id !== requiredId)
                    .map((object) => getJoinedFieldValues(object.fields))
                    .join(", ");
            }
            if (column.name === Language.get("cal_reservation_list_column_status")) {
                return self.getStatusLabel(order.status.status, width);
            }

            const startTime = new MillenniumDateTime(order.order_begin);
            const endTime = new MillenniumDateTime(order.order_end);

            if (column.name === Language.get("cal_reservation_list_column_date")) {
                if (order.period) {
                    return order.period.name;
                }
                return `${SimpleDateFormat.format(
                    startTime,
                    Language.getDateFormat("date_f_yy_mm_dd")
                )} - ${SimpleDateFormat.format(
                    endTime,
                    Language.getDateFormat("date_f_yy_mm_dd_end")
                )}`;
            }
            if (column.name === Language.get("cal_reservation_list_column_start_date")) {
                return SimpleDateFormat.format(
                    startTime,
                    Language.getDateFormat("date_f_yy_mm_dd")
                );
            }
            if (column.name === Language.get("cal_reservation_list_column_end_date")) {
                return SimpleDateFormat.format(
                    endTime,
                    Language.getDateFormat("date_f_yy_mm_dd_end")
                );
            }

            if (column.name === Language.get("cal_reservation_list_column_week")) {
                return Language.formatWeekTableCell(
                    startTime,
                    endTime,
                    this.context.customWeekNames
                );
            }
            if (column.name === Language.get("cal_reservation_list_column_start_week")) {
                return Language.formatSingleWeekTableCell(
                    startTime,
                    false,
                    this.context.customWeekNames
                );
            }
            if (column.name === Language.get("cal_reservation_list_column_end_week")) {
                return Language.formatSingleWeekTableCell(
                    endTime,
                    true,
                    this.context.customWeekNames
                );
            }

            if (column.name === Language.get("cal_reservation_list_column_week_day")) {
                return `${SimpleDateFormat.format(startTime, "EE")} - ${SimpleDateFormat.format(
                    endTime,
                    "LL"
                )}`;
            }
            if (column.name === Language.get("cal_reservation_list_column_start_week_day")) {
                return SimpleDateFormat.format(startTime, "EEE");
            }
            if (column.name === Language.get("cal_reservation_list_column_end_week_day")) {
                return SimpleDateFormat.format(endTime, "LLL");
            }

            if (column.name === Language.get("dynamic_reserv_list_delivered_count")) {
                if (order.delivered_count === undefined) {
                    return "";
                }
                return order.delivered_count;
            }
            if (column.name === Language.get("dynamic_reserv_list_delivered_time")) {
                if (order.delivered_length === undefined) {
                    return "";
                }
                return self.getTime(order.delivered_length, this.state.searchOptions.factor, width);
            }

            if (column.name === Language.get("dynamic_reserv_list_ordered_count")) {
                return order.order_count;
            }
            if (column.name === Language.get("dynamic_reserv_list_ordered_time")) {
                if (order.order_length === undefined) {
                    return "";
                }
                return this.getTime(order.order_length, this.state.searchOptions.factor, width);
            }

            if (column.name === Language.get("dynamic_reserv_list_remainder_count")) {
                if (order.order_count === undefined || order.order_count === 0) {
                    return "";
                }
                return order.order_count - order.delivered_count;
            }
            if (column.name === Language.get("dynamic_reserv_list_remainder_time")) {
                if (
                    order.delivered_length === undefined ||
                    order.delivered_count === 0 ||
                    _.isNullish(order.order_length)
                ) {
                    return "";
                }
                let time = order.order_length - order.delivered_length;
                let prefix = "";
                if (time < 0) {
                    prefix = "-";
                    time = Math.abs(time);
                }
                return prefix + self.getTime(time, this.state.searchOptions.factor, width);
            }

            const DEFAULT_ROUNDING_DECIMALS = 2;
            const percent = (toDivide, divideBy, roundingDecimals = DEFAULT_ROUNDING_DECIMALS) => {
                if (toDivide === undefined || divideBy === undefined) {
                    return "";
                }
                const result = toDivide / divideBy;
                if (isNaN(result) || !isFinite(result)) {
                    return "";
                }

                // eslint-disable-next-line no-magic-numbers
                const roundingMultiplier = Math.pow(10, roundingDecimals);
                const roundedPercentage =
                    Math.round(result * roundingMultiplier * HUNDRED_PERCENT) / roundingMultiplier;
                return `${roundedPercentage}%`;
            };

            if (column.name === Language.get("dynamic_reserv_list_percent")) {
                if (order.length === 0 && order.order_count > 0) {
                    return percent(order.delivered_count, order.order_count);
                }
                return percent(order.delivered_length, order.order_length);
            }
            if (column.name === Language.get("dynamic_reserv_list_percent_count")) {
                return percent(order.delivered_count, order.order_count);
            }
            if (column.name === Language.get("dynamic_reserv_list_percent_time")) {
                return percent(order.delivered_length, order.order_length);
            }

            if (column.name === Language.get("nc_other_info")) {
                if (!order.description) {
                    return "";
                }
                return order.description;
            }
            if (column.name === Language.get("nc_other_comment")) {
                if (!order.comment) {
                    return "";
                }
                return order.comment;
            }
            if (column.name === Language.get("nc_order_list_order_description")) {
                if (!order.order_description) {
                    return "";
                }
                return order.order_description;
            }
            return "";
        });

        return { labels, color };
    };

    getTime = (time, factor, size) => {
        if (factor > 1) {
            // eslint-disable-next-line no-param-reassign
            time = Math.floor(time / factor);
            return Math.floor(time / TC.SECONDS_PER_MINUTE);
        }
        // eslint-disable-next-line no-param-reassign
        time = new MillenniumTime(time);
        switch (this.getLabelSize(size)) {
            case Size.M:
                return SimpleDateFormat.format(time, Language.getDateFormat("date_f_hh"));
            default:
                return SimpleDateFormat.format(time, Language.getDateFormat("date_f_hh_mm"));
        }
    };

    getLongDate = (date, size) => {
        switch (this.getLabelSize(size)) {
            case Size.XS:
                return SimpleDateFormat.format(date, Language.getDateFormat("date_f_d"));
            case Size.S:
                return SimpleDateFormat.format(date, Language.getDateFormat("date_f_d"));
            case Size.M:
                return SimpleDateFormat.format(date, Language.getDateFormat("date_f_m_d"));
            case Size.L:
                return SimpleDateFormat.format(date, Language.getDateFormat("date_f_ee_m_d"));
            default:
                return SimpleDateFormat.format(
                    date,
                    Language.getDateFormat("date_f_yyyy_mm_dd_hh_mm")
                );
        }
    };

    getLabelSize = (size) => {
        if (size <= LABEL_SIZE_CUTOFFS.MINIMUM) {
            return null;
        }
        if (size < LABEL_SIZE_CUTOFFS.XS) {
            return Size.XS;
        }
        if (size < LABEL_SIZE_CUTOFFS.S) {
            return Size.S;
        }
        if (size < LABEL_SIZE_CUTOFFS.M) {
            return Size.M;
        }
        if (size < LABEL_SIZE_CUTOFFS.L) {
            return Size.L;
        }
        return Size.XL;
    };

    getStatusObject = (statusValue, orderStarted = false) => {
        const statusLabels = {
            0: {
                short: Language.get("order_status_row_unknown_short"),
                long: Language.get("order_status_row_unknown"),
                symbol: "",
                color: "",
            },

            1: {
                short: Language.get("order_status_row_partial_short"),
                long: Language.get("order_status_row_partial"),
                symbol: "<",

                // (om levererat antal och levererad tid === 0)
                color: orderStarted ? "rgb(248,248,168)" : "white",
            },

            2: {
                short: Language.get("order_status_row_fulfilled_short"),
                long: Language.get("order_status_row_fulfilled"),
                symbol: "=",
                color: "rgb(168,232,168)",
            },

            3: {
                short: Language.get("order_status_row_exceeded_short"),
                long: Language.get("order_status_row_exceeded"),
                symbol: ">",
                color: "rgb(255,196,196)",
            },

            4: {
                short: Language.get("order_status_row_unlimited_short"),
                long: Language.get("order_status_row_unlimited"),
                symbol: "o",

                // (om inte påbörjad dvs både levererat antal och tid === 0)
                color: orderStarted ? "rgb(208,224,255)" : "white",
            },

            5: {
                short: Language.get("order_status_row_passive_short"),
                long: Language.get("order_status_row_passive"),
                symbol: "-",
                color: "rgb(208,208,208)",
            },
        };
        return statusLabels[statusValue];
    };

    getStatusLabel = (statusValue, size) => {
        const status = this.getStatusObject(statusValue);
        const labelSize = this.getLabelSize(size);
        if (labelSize > Size.L) {
            return status.long;
        }
        return status.short;
    };

    render() {
        const classes = {
            orderList: true,
        };

        return (
            <GenericList
                {...this.props}
                settingsToSave={SETTINGS_TO_SAVE}
                onSearchSettingsChange={this.onSearchSettingsChange}
                showSelectionSummary={false}
                allowMultiSelection={false}
                searchOptions={this.state.searchOptions}
                onSelection={this.onSelection}
                getCellLabels={this.getCellLabels}
                columns={getAvailableColumns()}
                defaultSelectedColumns={[
                    COL_IDS.ORDERED_TIME,
                    COL_IDS.DELIVERED_TIME,
                    COL_IDS.REMAINDER_TIME,
                    COL_IDS.ORDER_OBJECTS,
                ]}
                getDataMenuItems={this.getDataMenuItems}
                title={Language.get("nc_order_list_title")}
                classes={classes}
                find={this.find}
                getSettingsComponent={this.getSettingsComponent}
            />
        );
    }

    loadSettings = (callback, getSavedSettings) => {
        const defaultSettings = this.getDefaultSearchOptions(this.getTimeLimits());
        getSavedSettings((result) => {
            result.forEach((r) => {
                // eslint-disable-next-line no-param-reassign
                r.settings = _.extend({}, defaultSettings, r.settings);
            });
            callback(result);
        });
    };

    limitTo = (categories, allowedCategories) => {
        const result = _.omit(
            categories,
            (value, category) =>
                !_.some(
                    allowedCategories,
                    (allowedCategory) =>
                        String(allowedCategory.id) === category &&
                        _.every(value, (val) => _.contains(allowedCategory.categories, val))
                )
        );
        return result;
    };

    getSettingsComponent = (
        saveDefaultSettings,
        saveSettings,
        getSavedSettings,
        removeSettings
    ) => {
        const opts = this.state.searchOptions;

        return (
            <OrderListSettings
                onChange={this.onSearchSettingsChange}
                orderStatus={opts.orderStatus}
                factor={opts.factor}
                permission={opts.permission}
                allOrders={opts.allOrders}
                saveDefaultSettings={saveDefaultSettings}
                saveSettings={saveSettings}
                getSavedSettings={(cb) => this.loadSettings(cb, getSavedSettings)}
                removeSettings={removeSettings}
                objectSearch={opts.objectSearch}
                includeMembers={opts.includeMembers}
                objectCategories={this.limitTo(
                    opts.objectCategories,
                    this.state.availableObjectCategories.concat(
                        this.state.availableObjectCheckboxes
                    )
                )}
                availableObjectCategories={this.state.availableObjectCategories}
                availableObjectCheckboxes={this.state.availableObjectCheckboxes}
                descriptionField={opts.descriptionField}
                commentField={opts.commentField}
                reservationFields={this.state.reservationFields}
            />
        );
    };
}

export default OrderList;
