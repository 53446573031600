import { useEffect, useRef, useState } from "react";
import { TConflictingObjects } from "../types/models/TEntry";

import API from "../lib/TimeEditAPI";
import _ from "underscore";
import Language from "../lib/Language";

export const CONFLICT_TOOLTIP_LEFT_PADDING = 312;
const TOOLTIP_LEFT_OFFSET = CONFLICT_TOOLTIP_LEFT_PADDING + 40;
const TOOLTIP_RIGHT_OFFSET = -2;

export const CONFLICT_INFO_TOOLTIP_CLASSNAME = "reservationConflictInfoTooltip";

type TooltipRowProps = {
    id: number;
    name?: string;
    typeName?: string;
    buttonActions: {
        info: (id: number) => void;
    };
    children?: JSX.Element[];
    level?: number;
    isItalic?: boolean;
};
const TooltipRow = ({
    id,
    name,
    typeName,
    buttonActions,
    children,
    level = 1,
    isItalic = false,
}: TooltipRowProps) => {
    const classes = {
        wrapText: true,
        italicText: isItalic,
    };
    return (
        <>
            <tr className="tooltipRow">
                <td className={_.classSet(classes)} title={name} style={{ paddingLeft: level * 8 }}>
                    {name} {typeName ? `(${typeName})` : ""}
                </td>
                <td className="tooltipRowActionButtons">
                    <button className="showInfoButton" onClick={() => buttonActions.info(id)} />
                </td>
            </tr>
            {children}
        </>
    );
};

type ReservationConflictInfoTooltipProps = {
    conflictingObjects: TConflictingObjects;
    showObjectInfo: (objectId: number) => void;
    isVisible: boolean;
    onClose?: () => void;
    reservationIds: number[];
    mousePosition: {
        x: number;
        y: number;
        offset: { top: number; left: number };
    };
    tooltipLeft: boolean;
};

const ReservationConflictInfoTooltip = (props: ReservationConflictInfoTooltipProps) => {
    const [objectNamesMap, setObjectNamesMap] = useState<Map<number, string>>(new Map());
    const [objectTypesMap, setObjectTypesMap] = useState<
        Map<number, { id: number; extid: string; class: string }>
    >(new Map());
    const [typeNamesMap, setTypeNamesMap] = useState<Map<string, string>>(new Map());
    const tooltipRef = useRef<HTMLInputElement>(null);
    const [objects, setObjects] = useState<TConflictingObjects[0][]>();
    const [objectsWithMembers, setObjectsWithMembers] = useState<TConflictingObjects[0][]>();
    const [aggregatedMembersCount, setAggregatedMembersCount] = useState<number>(0);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        fetchData(props.conflictingObjects);
        processObjectAndMembers(props.conflictingObjects);
    }, []);

    const processObjectAndMembers = (conflictingObjects: TConflictingObjects) => {
        const objects: TConflictingObjects[0][] = [];
        const objectsWithMembers: TConflictingObjects[0][] = [];

        conflictingObjects.forEach((obj) => {
            if (obj.members) {
                objectsWithMembers.push(obj);
            } else {
                objects.push(obj);
            }
        });

        const membersCount =
            objectsWithMembers?.reduce((count, o) => {
                const membersLength = o?.members?.length ?? 0;
                return count + membersLength;
            }, 0) ?? 0;

        setObjects(objects);
        setObjectsWithMembers(objectsWithMembers);
        setAggregatedMembersCount(membersCount);
    };

    const showObjectInfo = (objectId: number) => {
        props.showObjectInfo(objectId);
    };

    const fetchAndSetTypeNames = (typeNamesMap: Map<string, string>): Promise<void> =>
        new Promise((res) => {
            API.findTypes((types) => {
                types.forEach((t) => {
                    typeNamesMap.set(t.extid, t.name);
                });
                res();
            });
        });

    type TFetchNamesResult = {
        class: string;
        extid: string;
        fields: {
            class: string;
            extid: string;
            id: number;
            values: string[];
        }[];
        id: number;
    };

    const fetchNames = (objectNames: { id: number; type: number }[] | number[]) =>
        new Promise<TFetchNamesResult[]>((res) => {
            API.getObjectNames(objectNames, true, (namesResult) => res(namesResult));
        });

    const fetchAndSetObjectNamesAndTypes = (
        objectNamesMap: Map<number, string>,
        objectTypesMap: Map<number, { id: number; extid: string; class: string }>,
        objectIdsFilter: number[]
    ): Promise<void> =>
        new Promise((res) => {
            // Fetch more reservation data, which includes 'type id' that we need.
            API.exportReservations(props.reservationIds, false, (reservations) => {
                if (!reservations?.length) return;
                const [reservation] = reservations;

                const objectNameWithTypeRequestData: { id: number; type: number }[] = [];
                const objectNameOnlyIdsRequestData: number[] = [];

                objectIdsFilter.forEach((id) => {
                    const objType = reservation?.objects?.find((o) => o.id === id)?.type;
                    // We need to include all objects that we can't lookup the types for in a separate request.
                    if (!objType) {
                        objectNameOnlyIdsRequestData.push(id);
                    } else {
                        // Set object types to map.
                        objectTypesMap.set(id, objType);
                        // Construct request data to use later to get object names.
                        objectNameWithTypeRequestData.push({ id, type: objType.id });
                    }
                });

                // Not pretty, but currently we need to fetch concurrent object names.
                Promise.all([
                    // Fetch names with only object ids.
                    fetchNames(objectNameWithTypeRequestData),
                    // Fetch names without typeids.
                    fetchNames(objectNameOnlyIdsRequestData),
                ]).then((namesResult) => {
                    namesResult?.flat().forEach((namedObject) => {
                        if (namedObject) {
                            const name = namedObject.fields
                                .map((field) => (field.values ? field.values.join(", ") : ""))
                                .join(", ");
                            // Set object names to map.
                            objectNamesMap.set(namedObject.id, name);
                        }
                    });
                    res();
                });
            });
        });

    const recursiveFlattenIds = (objArray?: TConflictingObjects): number[] => {
        const ids = objArray?.flatMap(({ objectId, members }) => {
            const nestedResult = recursiveFlattenIds(members);
            return [objectId, ...nestedResult];
        });

        return ids ?? [];
    };

    const fetchData = (conflictingObjects: TConflictingObjects) => {
        const conflictingObjectIds = recursiveFlattenIds(conflictingObjects);

        if (conflictingObjectIds?.length) {
            const objectNamesMap = new Map<number, string>();
            const objectTypesMap = new Map<number, { id: number; extid: string; class: string }>();
            const typeNamesMap = new Map<string, string>();

            // Get object and type names from concurrent API requests.
            Promise.all([
                fetchAndSetTypeNames(typeNamesMap),
                fetchAndSetObjectNamesAndTypes(
                    objectNamesMap,
                    objectTypesMap,
                    conflictingObjectIds
                ),
            ])
                .then(() => {
                    // Success, set data maps in state.
                    setTypeNamesMap(typeNamesMap);
                    setObjectNamesMap(objectNamesMap);
                    setObjectTypesMap(objectTypesMap);
                    setIsLoaded(true);
                })
                .catch((e) => {
                    console.error(
                        "Could not resolve object and type names in Reservation conflict info tooltip.",
                        e
                    );
                });
        }
    };

    if (!props.conflictingObjects || !isLoaded || !props.isVisible) return null;

    let tooltipStyle: React.CSSProperties | null = null;
    if (props.mousePosition) {
        const leftOffset = props.tooltipLeft ? TOOLTIP_LEFT_OFFSET : TOOLTIP_RIGHT_OFFSET;
        const top = props.mousePosition.y - props.mousePosition.offset.top;
        let left = props.mousePosition.x - leftOffset - props.mousePosition.offset.left;
        if (props.tooltipLeft) {
            if (left < 0) {
                left -= left;
            }
        }
        tooltipStyle = { top, left };
    }
    if (!tooltipStyle) {
        return null;
    }

    const classes = {
        entryTooltip: true,
        withButtons: true,
        [CONFLICT_INFO_TOOLTIP_CLASSNAME]: true,
    };

    return (
        <div
            ref={tooltipRef}
            className={_.classSet(classes)}
            style={tooltipStyle}
            onMouseLeave={props.onClose}
        >
            <span>
                <table>
                    <tbody>
                        <>
                            {objects?.length ? (
                                <tr>
                                    <th>
                                        {objects.length}{" "}
                                        {Language.get(
                                            "nc_calendar_reservation_conflict_tooltip_objects_header"
                                        )}
                                        :
                                    </th>
                                </tr>
                            ) : (
                                <></>
                            )}
                            {objects?.map(({ objectId }) => {
                                const extid = objectTypesMap?.get(objectId)?.extid;
                                return (
                                    <TooltipRow
                                        key={objectId}
                                        id={objectId}
                                        name={objectNamesMap.get(objectId)}
                                        typeName={typeNamesMap.get(extid!)}
                                        buttonActions={{ info: showObjectInfo }}
                                    />
                                );
                            })}
                        </>

                        {objectsWithMembers?.length ? (
                            <>
                                <tr>
                                    <th>
                                        {aggregatedMembersCount}{" "}
                                        {Language.get(
                                            "nc_calendar_reservation_conflict_tooltip_members_header"
                                        )}
                                        :
                                    </th>
                                </tr>
                                {objectsWithMembers.map(({ objectId, members }) => {
                                    if (objectId === undefined) return <></>;
                                    const extid = objectTypesMap.get(objectId)?.extid;
                                    return (
                                        <TooltipRow
                                            key={objectId}
                                            id={objectId}
                                            isItalic
                                            name={objectNamesMap.get(objectId)}
                                            typeName={typeNamesMap.get(extid!)}
                                            buttonActions={{ info: showObjectInfo }}
                                        >
                                            {members?.map((m) => {
                                                const extid = objectTypesMap.get(m.objectId)?.extid;
                                                return (
                                                    <TooltipRow
                                                        key={m.objectId}
                                                        id={m.objectId}
                                                        name={objectNamesMap!.get(m.objectId)}
                                                        typeName={typeNamesMap.get(extid!)}
                                                        buttonActions={{ info: showObjectInfo }}
                                                        level={2}
                                                    />
                                                );
                                            })}
                                        </TooltipRow>
                                    );
                                })}
                            </>
                        ) : (
                            <></>
                        )}
                    </tbody>
                </table>
            </span>
        </div>
    );
};

export default ReservationConflictInfoTooltip;
