import PropTypes from "prop-types";
import React from "react";
import ListCalendar from "./ListCalendar";
import StaticReservationList from "./StaticReservationList2";
import { Macros } from "../models/Macros";

class StaticReservationListCalendar extends React.Component {
    static contextTypes = {
        fireEvent: PropTypes.func,
    };

    getRefreshEvents = () => [
        Macros.Event.SELECT_RESERVATION,
        Macros.Event.RESERVATION_MADE_OR_MODIFIED,
        Macros.Event.RESERVATION_RESTORED,
    ];

    onSelection = (selection) => {
        if (!selection.cancelled) {
            this.context.fireEvent(
                `staticReservationList${this.props.id}`,
                Macros.Event.SELECT_RESERVATION,
                selection
            );
            this.props.onInfoOpen([selection.id], false);
        }
    };

    render() {
        return (
            <ListCalendar
                {...this.props}
                className={`${this.props.className} staticReservationList`}
                type={"staticReservationList"}
                refreshEvents={this.getRefreshEvents()}
                onSelection={this.onSelection}
                listRef={(node) => {
                    this._wrapper = node;
                }}
            >
                <StaticReservationList />
            </ListCalendar>
        );
    }
}

export default StaticReservationListCalendar;
