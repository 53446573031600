import { Limits } from "./Limits";
import { Model } from "./Model";
import TemplateKind from "./TemplateKind";
import Language from "../lib/Language";
import { Selection } from "./Selection";
import { MillenniumDate, MillenniumDateTime } from "@timeedit/millennium-time";
import { Settings } from "./Settings";

const DEFAULT_LIST_ID = 4;

export class CancellationSearch extends Model {
    templateKind: any;
    privateSelected: boolean;
    selection: any;
    limits: any;
    listId: number;
    isTimePeriod: boolean;
    periodId: number;

    constructor(limits = Limits.getDefaultList()) {
        super("CancellationSearch");
        this.templateKind = TemplateKind.RESERVATION;
        this.privateSelected = true;
        this.selection = new Selection();
        this.limits = limits;
        this.listId = DEFAULT_LIST_ID;
        this.isTimePeriod = false;
        this.periodId = 0;
    }

    getSettings() {
        const self = this;
        const settings = [
            {
                id: "isPrivate",
                label: Language.get("cal_header_topleft_ignore_selected"),
                type: "boolean",
                get() {
                    return self.privateSelected;
                },
                set(isPrivate) {
                    if (isPrivate === self.privateSelected) {
                        return self;
                    }

                    let selection: Selection | null = null;
                    if (isPrivate) {
                        selection = new Selection();
                    }

                    return self.immutableSet({
                        privateSelected: isPrivate,
                        selection,
                    });
                },
            },
            {
                id: "isStartDayAbsolute",
                label: Language.get("nc_cal_res_side_view_limit_type"),
                type: "array",
                limit: 1,
                get() {
                    return [
                        {
                            label: Language.get("nc_cal_res_side_view_relative_limits"),
                            value: false,
                            selected: self.limits.isStartDayAbsolute === false,
                        },
                        {
                            label: Language.get("nc_cal_res_side_view_absolute_limits"),
                            value: true,
                            selected: self.limits.isStartDayAbsolute === true && !self.isTimePeriod,
                        },
                        {
                            label: Language.get("nc_header_type_time_period"),
                            value: null,
                            selected:
                                self.isTimePeriod === true &&
                                self.limits.isStartDayAbsolute === true,
                        },
                    ];
                },
                set(val) {
                    let isTimePeriod = false;
                    if (val === null) {
                        isTimePeriod = true;
                        // eslint-disable-next-line no-param-reassign
                        val = true;
                    }

                    return self
                        .setLimits(self.limits.setIsStartDayAbsolute(val))
                        .setIsTimePeriod(isTimePeriod);
                },
            },
        ];
        if (self.limits.isStartDayAbsolute && !self.isTimePeriod) {
            settings.push({
                id: "startDate",
                label: Language.get("cal_res_side_view_start_day"),
                type: "date",
                get() {
                    return self.limits.getStartDate();
                },
                set(val) {
                    if (self.limits.getStartDate().equals(val)) {
                        return self;
                    }

                    return self.setLimits(self.limits.setStartDayFromDate(val));
                },
            });
            settings.push({
                id: "endDate",
                label: Language.get("cal_res_side_view_end_day"),
                type: "date",
                get() {
                    return self.limits.getEndDate();
                },
                set(val) {
                    if (self.limits.getEndDate().equals(val)) {
                        return self;
                    }

                    return self.setLimits(self.limits.setDayCountFromDate(val));
                },
            });
        } else if (self.limits.isStartDayAbsolute === true && self.isTimePeriod) {
            settings.push({
                id: "period",
                label: Language.get("nc_header_type_time_period"),
                type: "periodtype",
                limit: 1,
                get() {
                    return self.periodId; // Map periods, setting selected if start and end match what's set in the calendar
                },
                set(val) {
                    const newLimits = self.limits
                        .setIsStartDayAbsolute(true)
                        .setStartDay(val.start_date);
                    return self
                        .setLimits(
                            newLimits.setDayCountFromDate(new MillenniumDate(val.end_date), true)
                        )
                        .setPeriodId(val.id);
                },
            });
        } else {
            settings.push({
                id: "startDate",
                label: Language.get("cal_res_side_view_start_day"),
                type: "dateoffset",
                get() {
                    return self.limits.startDay;
                },
                set(val) {
                    if (self.limits.startDay === val) {
                        return self;
                    }

                    return self.setLimits(self.limits.setStartDay(val));
                },
            });
            settings.push({
                id: "endDate",
                label: Language.get("cal_res_side_view_end_day"),
                type: "dateoffset",
                get() {
                    return self.limits.dayCount + self.limits.startDay;
                },
                set(val) {
                    if (self.limits.dayCount === val) {
                        return self;
                    }

                    const newLimits = new Limits(self.limits);
                    newLimits.dayCount = val - self.limits.startDay;
                    return self.setLimits(newLimits);
                },
            });
        }
        return new Settings(settings);
    }

    setLimits(limits: any) {
        if (!(limits instanceof Limits)) {
            // eslint-disable-next-line no-param-reassign
            limits = new Limits(limits);
        }

        return this.immutableSet({
            limits,
        });
    }

    getMacroFunctions() {
        const macros = [];
        return macros;
    }

    setIsTimePeriod(isTimePeriod: any) {
        return this.immutableSet({ isTimePeriod }).immutableSet({ periodId: 0 });
    }

    setPeriodId(periodId: any) {
        return this.immutableSet({ periodId });
    }

    updateTimeRange(newBeginTime: any, newEndTime: any) {
        let limits = new Limits(this.limits);
        limits = limits.setStartDayFromDate(
            new MillenniumDateTime(newBeginTime).getMillenniumDate()
        );
        limits = limits.setDayCountFromDate(new MillenniumDateTime(newEndTime).getMillenniumDate());
        return this.immutableSet({
            limits,
        });
    }

    // What about the search settings object and such? How to even get hold of it from here?
    toJSON() {
        return {
            limits: this.limits,
            privateSelectedList: this.privateSelected,
            function: this.getMacroFunctions(),
            templateKind: this.templateKind.mode,
            isList: false,
            isCancellationList: true,
            listId: this.listId,
            periodId: this.periodId,
        };
    }
}
